import type { App } from 'vue'
import type { Config } from 'dompurify'

import { sanitizeUrl as sanitizeUrlLibrary } from '@braintree/sanitize-url'
import DOMPurify from 'dompurify'

const allowedTags = ['a', 'br', 'p']

DOMPurify.addHook('afterSanitizeAttributes', (node, event, config) => {
  if (config.forceTargetBlank && node instanceof HTMLAnchorElement) {
    node.setAttribute('target', '_blank')
  }
})

export const sanitizeHTML = (value: string, low: boolean = false, forceTargetBlank: boolean = false) => {
  const config: Config = {
    forceTargetBlank,
  }

  if (low) {
    config.USE_PROFILES = { html: true }
    config.ADD_ATTR = ['target']
  } else {
    config.ALLOWED_TAGS = allowedTags
  }

  return DOMPurify.sanitize(value, config)
}

export const sanitizeUrl = (url: string): string => {
  return sanitizeUrlLibrary(url)
}

declare module 'dompurify' {
  interface Config {
    /** All HTMLAnchorElement are render with blank target through DOMPurify "afterSanitizeAttributes" hook  */
    forceTargetBlank?: boolean
  }
}

export default (app: App) => {
  app.directive('safe-html', {
    created: (el: Element, { value, modifiers }) => {
      el.innerHTML = sanitizeHTML(value, modifiers?.low, modifiers?.blankLink).toString()
    },
    updated: (el: Element, { value, modifiers }) => {
      el.innerHTML = sanitizeHTML(value, modifiers?.low, modifiers?.blankLink).toString()
    },
  })

  app.directive('safe-link', {
    created: (el: HTMLAnchorElement, { value }) => {
      if (!(el instanceof HTMLAnchorElement)) return console.warn('v-safe-link should be used only with an A tag element <HTMLAnchorElement>')
      el.href = sanitizeUrl(value)
    },
  })

  app.config.globalProperties.$sanitizeUrl = sanitizeUrl
  app.config.globalProperties.$sanitizeHTML = sanitizeHTML
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $sanitizeUrl: typeof sanitizeUrl
    $sanitizeHTML: typeof sanitizeHTML
  }
}
