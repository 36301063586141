/**
 * @description Helper to get data attributes from div entry point
 */

const { pathname } = document.body.dataset // get data- attributes
const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name='_csrf']")?.content
const csrfHeader = document.head.querySelector<HTMLMetaElement>("meta[name='_csrf_header']")?.content
const protocol = window.location.protocol
const host = window.location.host
const root = `${protocol}//${host}${pathname}`

interface CSRFConfig {
  token: string // value
  header: string // name
}

interface HostConfig {
  pathname?: string
  protocol: string
  host: string
  root: string
  csrf: CSRFConfig
}

const initialConfig: HostConfig = {
  protocol,
  host,
  root,
  csrf: {
    token: csrfToken || '',
    header: csrfHeader || '',
  },
}

const hostConfig: HostConfig = Object.freeze(pathname ? { ...initialConfig, pathname } : initialConfig)

export const rootPath = '/assist/'

export const featureFlags = JSON.parse(document.body.dataset.features || '[]')

export default hostConfig
