import BaseModel from '@/engage/services/legacy/BaseModel'

export default class Config extends BaseModel {
  constructor() {
    super()

    this._model = ''
  }

  /**
   * @returns {Promise}
   * @memberof Config
   */
  getConfiguration() {
    return this.get(`${this.resourceUrl}giphy/configuration`)
  }

  /**
   * @param {string} channel
   * @returns {Promise}
   * @memberof Config
   */
  getAllowedFileTypes(channel) {
    return this.get(`${this.resourceUrl}allowedFileTypesToUpload?channelCode=${channel.toUpperCase()}`)
  }
}
