import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler, apiMessageSnackbar } from '@/engage/services/legacy/utils'
import { OPTION_FILTER, CHANNEL_TYPE } from '@/engage/constants'
import { useEngageCasesStore } from '@/engage/stores/engageCases'
import { usePolaritySentimentStore } from '@/engage/stores/polaritySentiment'

export const controllerAbortObject = createAbortControllerHandler(['getInteractions', 'getLastInteractions', 'getTicketChatHistory'])

export default class Interaction extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'interaction'
  }

  get currentActivityId() {
    const casesStore = useEngageCasesStore()
    return casesStore.currentActivityId
  }

  get polaritySentimentIds() {
    const polaritySentimentStore = usePolaritySentimentStore()
    return polaritySentimentStore.listByTicket.map((polarity) => polarity.polarityId)
  }

  get currentActivity() {
    const casesStore = useEngageCasesStore()
    return casesStore.currentActivity
  }

  /**
   * Set usernameTile and avatar interaction properties based on its channel
   *
   * @param {Object} interaction
   * @param {Object} activity
   * @returns {Object}
   * @memberof Interaction
   */
  _setInteractionNameAndAvatar(interaction, activity) {
    if (!interaction) {
      return
    }

    let name = ''
    let avatar = ''

    if (interaction.customer) {
      switch (activity.currentChannelId) {
        case CHANNEL_TYPE.FACEBOOK.id:
          name = interaction.customer.facebookName
          avatar = interaction.customer.facebookProfilePicUrl
          break
        case CHANNEL_TYPE.TWITTER.id:
          avatar = interaction.customer.twitterUserProfileImageURL
          name = interaction.customer.twitterUserScreenName
          break
        case CHANNEL_TYPE.TWITTERDM.id:
          name = interaction.customer.twitterUserScreenName
          break
        case CHANNEL_TYPE.WHATSAPP.id:
          name = interaction.customer.whatsAppDisplayName
          break
        case CHANNEL_TYPE.MESSENGER.id:
          name = interaction.customer.messengerName
          break
        case CHANNEL_TYPE.SMS.id:
          name = interaction.customer.mobilePhone
          break
        case CHANNEL_TYPE.INSTAGRAM.id:
        case CHANNEL_TYPE.INSTAGRAMDM.id:
          name = interaction.customer.instagramUserName
          break

        default:
          break
      }
    }

    if (!name && !interaction.incoming && activity?.senderName) {
      name = activity?.senderName
    }

    interaction.usernameTile = name
    interaction.avatar = avatar

    return interaction
  }

  /**
   * @returns {Promise}
   * @memberof Interaction
   */

  /**
   * @param {Array} interactions
   * @returns
   */
  _mapInteractionsData(interactions, activity) {
    if (!interactions || !activity) {
      return
    }

    return interactions.map((interaction) => this._setInteractionNameAndAvatar(interaction, activity))
  }

  /**
   * @returns {Promise}
   * @memberof Interaction
   */
  async getInteractions(activity) {
    const {
      data: { data: interactions = [] },
    } = await this.get(`${this.resourceUrl}/getByTicket/${activity.ticketId}`, {
      signal: controllerAbortObject['getInteractions'].requestAbortController().signal,
    })

    return this._mapInteractionsData(interactions, activity)
  }

  /**
   * @returns {Promise}
   * @memberof Interaction
   */
  addCall(ticketId, interactionTypeId, interactionContent, aht) {
    return this.post(
      `${this.resourceUrl}/addInteraction?ticketId=${ticketId}&interactionTypeId=${interactionTypeId}&interactionContent=${interactionContent}&aht=${aht}`,
    )
  }

  /**
   * @returns {Promise}
   * @memberof Interaction
   */
  async updateLikeOrUnlike(interactionId, like = true) {
    const response = await this.post(`${this.resourceUrl}/${like ? 'like' : 'unlike'}/${interactionId}`)

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @returns {Promise}
   * @memberof Interaction
   */
  async updateHideOrDisplay(interactionId, hide = true) {
    const response = await this.post(`${this.resourceUrl}/${hide ? 'hide' : 'display'}/${interactionId}`)

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {Timestamp} lastDate
   * @returns {Promise}
   * @memberof Interaction
   */
  async getLastInteractions(lastDate) {
    const {
      data: { data: interactions = [] },
    } = await this.get(`${this.resourceUrl}/getByTicket/${this.currentActivityId}/after/${lastDate}`, {
      signal: controllerAbortObject['getLastInteractions'].requestAbortController().signal,
    })

    return this._mapInteractionsData(interactions, this.currentActivity)
  }

  async saveQualifyInteraction(data, interactionId) {
    return this.post(`/social/qualify/interaction/${interactionId}`, data)
  }
  /**
   * get chat customer navigaiton history when chat closed
   * @returns {Promise}
   * @memberof Interaction
   */
  getTicketChatHistory(interactionId) {
    return this.get(`${this.resourceUrl}/getHistory/${interactionId}`, {
      signal: controllerAbortObject['getTicketChatHistory'].requestAbortController().signal,
    })
  }

  /**
   * @param {number} activityId
   * @returns {Promise}
   * @memberof Interaction
   */
  async getFirstInteractions(activity) {
    const {
      data: { data: interactions },
    } = await this.get(`${this.resourceUrl}/getByTicket/first/${activity.ticketId}`)

    return this._setInteractionNameAndAvatar(interactions, activity)
  }

  /**
   * @param {number} activityId
   * @param {string} filter
   * @param {number} currentPage
   * @returns {Promise}
   * @memberof Interaction
   */
  async getOnlyParentsInteractions(activity, filter = '', currentPage = 1) {
    const {
      data: { data: { items = [], pagination = {} } = {} },
    } = await this.get(`${this.resourceUrl}/getByTicket/onlyParents/${activity.ticketId}`, {
      params: {
        currentPage,
        commentType: this._getCommentTypeFromFilters(filter),
        polarityTypeIds: this._getPolarityTypeIdsFromFilters(filter),
      },
    })

    const interactions = this._mapInteractionsData(items, activity)

    return {
      interactions,
      pagination,
    }
  }

  /**
   * @param {number} interactionId
   * @param {string} filter
   * @param {number} currentPage
   * @returns {Promise}
   * @memberof Interaction
   */
  async getByInteractionParent(interactionId, activity, filter = '', currentPage = 1) {
    const {
      data: {
        data: { items = [], pagination = {} },
      },
    } = await this.get(`${this.resourceUrl}/getByInteractionParent/${interactionId}`, {
      params: {
        currentPage,
        commentType: this._getCommentTypeFromFilters(filter),
        polarityTypeIds: this._getPolarityTypeIdsFromFilters(filter),
      },
    })

    const interactions = this._mapInteractionsData(items, activity)

    return {
      interactions,
      pagination,
    }
  }

  /**
   *
   * @param {string} filter
   * @returns {string}
   */
  _getCommentTypeFromFilters(filter) {
    return filter.split(',')[0]
  }

  /**
   *
   * @param {string} filter
   * @returns {string}
   */
  _getPolarityTypeIdsFromFilters(filter) {
    const polarityType = this._getCommentTypeFromFilters(filter)
    const polarityTypeIds = filter.split(',').slice(1)

    if (polarityType === OPTION_FILTER.ALL_COMMENTS_POLARIZED && !polarityTypeIds.length) {
      // By default use polarity sentiment ids if no one is passed
      return this.polaritySentimentIds.join()
    } else {
      return polarityTypeIds.join()
    }
  }
}
