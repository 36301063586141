import activities from '@/engage/router/middlewares/activities'
import router from '@/router'
import _filter from 'lodash/filter'
import { useEngageCasesStore } from '@/engage/stores/engageCases'
import { useHelpRequestStore } from '@/engage/stores/helpRequests'
import { watch } from 'vue'

const isCommandCenterRoute = (route) => {
  return route.meta?.name === 'command-center'
}

const isEngageRoute = (route) => {
  return route.meta?.name === 'engage'
}

const isTicketPathNotValid = (to) => {
  let isNotCorrectPath = false

  if (isCommandCenterRoute(to)) {
    isNotCorrectPath = to.params.ticketId && !activities.isCorrectCommandCenterPath(to)
  }

  if (isEngageRoute(to)) {
    isNotCorrectPath = to.meta.channelRoute && !activities.isCorrectEngagePath(to)
  }

  return isNotCorrectPath
}

const redirectInvalidPath = () => {
  const notValidPath = isTicketPathNotValid(router.currentRoute.value)
  if (notValidPath) {
    router.push({ name: '404' })
  }
}

// Setup store watcher for api fetched status state on first load only
// Delay route path check by store resolution
const delayRoutePathValidation = ({ helpRequest = false } = {}) => {
  const fetchAwaitedList = [
    {
      id: 'activities',
      storeModule: useEngageCasesStore,
      storeState: 'activitiesFetched',
      fetched: false,
      expected: true,
    },
    {
      id: 'helpRequest',
      storeModule: useHelpRequestStore,
      storeState: 'helpRequestFetched',
      fetched: false,
      expected: helpRequest,
    },
  ]

  const updateFetchStatus = function (id) {
    const storeKeyAwaitedItem = fetchAwaitedList.find((item) => item.id === id)
    storeKeyAwaitedItem.fetched = true
  }

  const expectedFetchedList = _filter(fetchAwaitedList, 'expected')

  expectedFetchedList.forEach(function ({ id, storeModule, storeState }) {
    const store = storeModule()
    const unwatch = watch(
      () => store[storeState],
      () => {
        unwatch()
        updateFetchStatus(id)

        const endedFetchedLength = _filter(fetchAwaitedList, {
          expected: true,
          fetched: true,
        }).length

        if (endedFetchedLength === expectedFetchedList.length) {
          redirectInvalidPath()
        }
      },
    )
  })

  // allow initial navigation
  return true
}

export default {
  isCommandCenterRoute,
  isEngageRoute,
  isTicketPathNotValid,
  delayRoutePathValidation,
}
