import HttpService from '@/common/services/HttpService'
import i18n from '@/plugins/i18n'
import { VonageConfiguration, VonageProtectedData, VonageSmsNumber, VonageWhatsAppNumber } from '@/admin/types/vonage'
import { displayMessage } from '@/common/services/NotificationDisplayService'
import { useVonageStore } from '@/admin/stores/vonageStore'

const { t } = i18n.global

const vonageConfigurationPath = `/api/v1/vonage/configurations`

export const getAllVonageConfigurations = async () => {
  try {
    return await HttpService.getData<VonageConfiguration[]>(vonageConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getVonageConfiguration = async (id: UUID) => {
  try {
    return await HttpService.getData<VonageConfiguration>(`${vonageConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const saveNewVonageSMSNumber = async (vonageSmsNumber: VonageSmsNumber) => {
  const vonageConfiguration: VonageConfiguration | null = await getVonageConfiguration(vonageSmsNumber.vonageConfiguration?.id!)
  delete vonageSmsNumber.vonageConfiguration
  vonageConfiguration!.smsNumbers?.push(vonageSmsNumber)

  return updateVonageConfiguration(
    vonageConfiguration!,
    'assist.admin.sms.vonage.sms.number.created',
    'assist.admin.sms.vonage.sms.number.create.error',
  )
}

export const updateVonageSMSNumber = async (vonageSmsNumber: VonageSmsNumber) => {
  const vonageConfiguration: VonageConfiguration | null = await getVonageConfiguration(vonageSmsNumber.vonageConfiguration?.id!)
  delete vonageSmsNumber.vonageConfiguration
  const objectToReplace = vonageConfiguration?.smsNumbers?.find((arrayItem) => arrayItem.id === vonageSmsNumber.id)

  if (objectToReplace) {
    Object.assign(objectToReplace, vonageSmsNumber)
  } else {
    vonageConfiguration?.smsNumbers?.push(vonageSmsNumber)
  }

  return updateVonageConfiguration(
    vonageConfiguration!,
    'assist.admin.sms.vonage.sms.number.updated',
    'assist.admin.sms.vonage.sms.number.update.error',
  )
}

export const saveNewVonageWhatsAppNumber = async (vonageNumber: VonageWhatsAppNumber) => {
  const vonageConfiguration: VonageConfiguration | null = await getVonageConfiguration(vonageNumber.vonageConfiguration?.id!)
  delete vonageNumber.vonageConfiguration
  vonageConfiguration!.whatsappNumbers?.push(vonageNumber)

  return updateVonageConfiguration(vonageConfiguration!, 'assist.admin.whatsapp.vonage.created', 'assist.admin.whatsapp.vonage.create.error')
}

export const updateVonageWhatsAppNumber = async (vonageNumber: VonageWhatsAppNumber, updateList = false) => {
  const vonageConfiguration: VonageConfiguration | null = await getVonageConfiguration(vonageNumber.vonageConfiguration?.id!)
  delete vonageNumber.vonageConfiguration
  Object.values(VonageProtectedData).forEach((value) => {
    delete vonageNumber[value]
  })
  const objectToReplace = vonageConfiguration?.whatsappNumbers?.find((arrayItem) => arrayItem.id === vonageNumber.id)

  if (objectToReplace) {
    Object.assign(objectToReplace, vonageNumber)
  } else {
    vonageConfiguration?.whatsappNumbers?.push(vonageNumber)
  }

  return updateVonageConfiguration(vonageConfiguration!, 'assist.admin.whatsapp.vonage.updated', 'assist.admin.whatsapp.vonage.update.error').then(
    (res: any) => {
      if (updateList && res) {
        const vonageStore = useVonageStore()
        vonageStore.passWhatsAppNumbers()
      }
    },
  )
}

const updateVonageConfiguration = async (vonageConfiguration: VonageConfiguration, successMessage: string, errorMessage: string) => {
  try {
    const res = await HttpService.putData<VonageConfiguration>(vonageConfigurationPath, vonageConfiguration)
    displayMessage(t(successMessage), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t(errorMessage), 'danger')
    return
  }
}

export const saveVonageConfiguration = async (vonageConfiguration: VonageConfiguration) => {
  try {
    const res = await HttpService.putData<VonageConfiguration>(vonageConfigurationPath, vonageConfiguration)
    displayMessage(t('assist.admin.vonage.configuration.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.vonage.configuration.error'), 'danger')
    return
  }
}

export const saveNewVonageConfiguration = async (vonageConfiguration: VonageConfiguration) => {
  try {
    const res = await HttpService.postData<VonageConfiguration>(vonageConfigurationPath, vonageConfiguration)
    displayMessage(t('assist.admin.vonage.configuration.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.vonage.configuration.error'), 'danger')
    return
  }
}
