import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import configResolver, { rootPath } from '@/common/helpers/configResolver'
import { getLogoInstance } from '@/common/services/AppearancesService'
import { LegacyPathToken } from '@/common/types/application'

export const useApplicationStore = defineStore('application', () => {
  const ready: Ref<boolean> = ref(true)
  const legacyFrame = ref<HTMLIFrameElement>()
  const legacyPathToken: Ref<LegacyPathToken | null> = ref(null)
  const active: Ref<boolean> = ref(true)
  const disabled: Ref<boolean> = ref(false)
  const logo: Ref<string> = ref('')
  const engage: Ref<boolean> = ref(false)
  const modalOpened: Ref<boolean> = ref(false)

  function requestPathChange(path: string, reset: boolean) {
    if (ready.value) {
      legacyPathToken.value = {
        path: path.replace(rootPath, ''),
        reset,
      }
      ready.value = false
    } else {
      ready.value = true
    }
  }
  async function getLogo() {
    let resLogoInstance = `${configResolver.pathname}resources/img/logo-login-innso.png`
    try {
      resLogoInstance = await getLogoInstance()
    } catch (error) {
      console.log('Unable to retrieve instance logo')
    } finally {
      logo.value = resLogoInstance
    }
  }

  return {
    ready,
    legacyFrame,
    legacyPathToken,
    active,
    disabled,
    logo,
    requestPathChange,
    getLogo,
    engage,
    modalOpened,
  }
})
