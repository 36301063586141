import HttpService from '@/common/services/HttpService'
import Keycloak, { KeycloakConfig, KeycloakLoginOptions } from 'keycloak-js'
import router from '@/router'
import type { AuthenticationConfig } from '@/common/types/authenticationConfig'
import configResolver from '@/common/helpers/configResolver'
import { ADMIN_AUTH_SETTINGS_ROUTE, OIDC_LOGOUT_ROUTE_NAME } from '@/router/routes'
import { extractErrorMessage } from '@/common/helpers/errorUtils'
import { useSessionStore } from '@/common/stores/sessionStore'

const LOGOUT_PATH = '/assist/login?logout'
const AUTH_CONFIG_PATH = '/api/v1/auth/config'
const OIDC_SESSION_LINK_API = '/api/v1/oidc-session-links'
const KEYCLOAK_TOKEN = 'kc_token'
const KEYCLOAK_REFRESH_TOKEN = 'kc_refreshToken'

let keycloakInstanceRef: Keycloak | undefined
let manageOidcSession: boolean // call createOidcSession, updateOidcSession, removeOidcSession

export const OIDC_SECURED_APIS_BEFORE_SESSION_INIT = ['/api/v1/backend-event/token', OIDC_SESSION_LINK_API]
export const MENU_ITEMS_DISABLED_FOR_OIDC = [ADMIN_AUTH_SETTINGS_ROUTE]

// Function to apply authentication
export const keycloakAuthentication = async (withOidcSession = true): Promise<Keycloak | void> => {
  const authenticationConfig = await getAuthenticationConfig()
  if (!isValidOidcProviderUrl(authenticationConfig.oidcProviderUrl)) return
  const keycloak = await initKeycloak(authenticationConfig)
  if (!keycloak.authenticated) {
    throw new Error('Keycloak authentication failed.')
  }
  keycloakInstanceRef = keycloak
  manageOidcSession = withOidcSession
  try {
    if (manageOidcSession) await createOidcSession()
  } catch (error) {
    const errorMessage = extractErrorMessage(error)
    const errorPage = `${configResolver.root}assist/error?errorMessage=${errorMessage}`
    await keycloakInstanceRef.logout({ redirectUri: errorPage }) //remove keycloak session
  }
  return keycloak
}

// Function to handle logout
export const logout = async () => {
  if (keycloakInstanceRef) {
    await router.push({ name: OIDC_LOGOUT_ROUTE_NAME })
  } else {
    // else use legacy logout
    await HttpService.delete('/api/v1/users/me')
    await router.push(LOGOUT_PATH)
  }
}

// Function to handle oidc logout
export const oidcLogout = async () => {
  try {
    try {
      if (manageOidcSession || keycloakInstanceRef) await removeOidcSession()
      clearKeycloakSessionStorage()
    } catch (error) {
      console.error('Error removeOidcSession: ', error)
    } finally {
      await keycloakInstanceRef?.logout()
    }
  } catch (error) {
    console.error('Error keycloak logout : ', error)
  }
}

const initKeycloak = async (authenticationConfig: AuthenticationConfig): Promise<Keycloak> => {
  const keycloakConfig: KeycloakConfig = {
    url: authenticationConfig.oidcProviderUrl,
    realm: authenticationConfig.tenantId,
    clientId: 'web-end-user-client',
  }
  const keycloak = new Keycloak(keycloakConfig)

  keycloak.onAuthSuccess = async () => {
    updateKeycloakSessionStorage(keycloak)
  }

  keycloak.onTokenExpired = async () => await handleTokenExpired(keycloak)

  await keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
    token: getCurrentKeycloakToken()!,
    refreshToken: getCurrentKeycloakRefreshToken()!,
  })
  return keycloak
}

function updateKeycloakSessionStorage(keycloak: Keycloak) {
  sessionStorage.setItem(KEYCLOAK_TOKEN, <string>keycloak.token)
  sessionStorage.setItem(KEYCLOAK_REFRESH_TOKEN, <string>keycloak.refreshToken)
}

function clearKeycloakSessionStorage() {
  sessionStorage.removeItem(KEYCLOAK_TOKEN)
  sessionStorage.removeItem(KEYCLOAK_REFRESH_TOKEN)
}

export function getCurrentKeycloakToken(): string | null {
  return sessionStorage.getItem(KEYCLOAK_TOKEN)
}

export async function triggerOidcChangePassword(redirectUri = '') {
  const keycloakLoginOptions: KeycloakLoginOptions = { action: 'UPDATE_PASSWORD', redirectUri: redirectUri, prompt: 'login' }
  await keycloakInstanceRef?.login(keycloakLoginOptions)
}

function getCurrentKeycloakRefreshToken(): string | null {
  return sessionStorage.getItem(KEYCLOAK_REFRESH_TOKEN)
}

// create oidc-session link
const createOidcSession = async () => {
  await HttpService.post(OIDC_SESSION_LINK_API)
}
// update oidc-session link
const updateOidcSession = async () => {
  await HttpService.put(OIDC_SESSION_LINK_API)
}
// remove oidc-session link
const removeOidcSession = async () => {
  await HttpService.delete(OIDC_SESSION_LINK_API)
}
// Function to handle token refresh
const handleTokenExpired = async (keycloak: Keycloak) => {
  console.debug('token expired', keycloak.token)
  try {
    const refreshed = await keycloak.updateToken(5)
    if (refreshed) {
      updateKeycloakSessionStorage(keycloak)
      if (manageOidcSession) await updateOidcSession()
      console.debug('Token was successfully refreshed')
    } else {
      console.debug('Token is still valid')
    }
  } catch (error) {
    console.error('Failed to refresh the token, or the session has expired')
    await logout()
  }
}

// check if oidc provider url is valid
function isValidOidcProviderUrl(oidcUrl: string | undefined | null): boolean {
  return typeof oidcUrl === 'string' && oidcUrl.trim() !== ''
}

// Function to get authentication configuration from backend
function getAuthenticationConfig(): Promise<AuthenticationConfig> {
  return HttpService.getData<AuthenticationConfig>(AUTH_CONFIG_PATH)
}

const isTargetUrlEligibleToTokenPropagation = function (originUrl: string, targetUrl: string): boolean {
  // Not starting with http -> eligible
  if (!targetUrl.startsWith('http')) return true
  // With same host, eligible
  const origin = new URL(originUrl)
  if (targetUrl.includes(origin.host)) return new URL(targetUrl).host === origin.host
  // Otherwise, no token propagated
  return false
}

export const isAccessTokenPropagationToRequestEnabled = function (originUrl: string, targetUrl: string): boolean {
  if (OIDC_SECURED_APIS_BEFORE_SESSION_INIT.includes(targetUrl)) return true // Manage early stage url that need token propagation
  if (!useSessionStore().oidcEnabled) return false
  return isTargetUrlEligibleToTokenPropagation(originUrl, targetUrl)
}
