import BaseModel from '@/engage/services/legacy/BaseModel'

export default class Sms extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'sms'
  }

  /**
   * @param {Number} skillGroupId
   * @param {Number} skillId
   * @param {Boolean} notification
   * @returns {Promise}
   * @memberof Sms
   */
  getSenders(skillGroupId, skillId, notification = true) {
    return this.get(`${this.resourceUrl}/senders`, {
      params: {
        skillGroupId,
        skillId,
        notification,
      },
    })
  }

  /**
   * @param {Number} id
   * @param {Number} ticketId
   * @param {Number} aht
   * @param {String} toPhone
   * @param {String} message
   * @returns {Promise}
   * @memberof Sms
   */
  sendSms(id, ticketId, aht, toPhone, message) {
    return this.post(`${this.resourceUrl}/send?senderId=${id}&ticketId=${ticketId}&aht=${aht}&toPhone=${toPhone}&message=${message}`)
  }
}
