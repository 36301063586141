import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler } from '@/engage/services/legacy/utils'
import { useEngageCasesStore } from '@/engage/stores/engageCases'

const controllerAbortObject = createAbortControllerHandler(['skillGetTreeView', 'skillSearchActivities'])

export default class Skill extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'skills'
  }

  get currentActivityId() {
    const casesStore = useEngageCasesStore()
    return casesStore.currentActivityId
  }

  /**
   * @returns {Promise}
   * @memberof Skill
   */
  getTreeView() {
    return this.get(`${this.resourceUrl}/getTreeview`, {
      params: {
        ticketId: this.currentActivityId,
      },
      signal: controllerAbortObject['skillGetTreeView'].requestAbortController().signal,
    })
  }

  /**
   * @returns {Promise}
   * @memberof Skill
   */
  getSkillsTree() {
    return this.get(`${this.resourceUrl}/getSkillsTree`, {
      params: {
        restrictToLoggedInUser: true,
      },
      signal: controllerAbortObject['skillSearchActivities'].requestAbortController().signal,
    })
  }
}
