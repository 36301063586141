import '@/styles/design-system-css-develop/index.css'
import 'quasar/src/css/index.sass'

import { createApp } from 'vue'
import { Quasar, Notify, Loading, Dialog } from 'quasar'
import quasarIconSet from 'quasar/icon-set/svg-fontawesome-v6'

import i18n from '@/plugins/i18n'
import sentry from '@/plugins/sentry'
import sanitizer from '@/plugins/sanitizer'
import dayjs from '@/plugins/dayjs'
import survey from '@/plugins/survey'
import filters from '@/plugins/filters'
import pinia from '@/plugins/pinia'
import * as constants from '@/engage/constants'
import '@/common/plugins/quasar'

import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import fontAwesome from '@/plugins/font-awesome'
import vuetify from '@/plugins/vuetify'
import api from '@/engage/plugins/api'

import App from '@/App.vue'
import router from '@/router'

import eventBus from '@/plugins/eventBus'
import { getInlineIcon } from '@/common/helpers/icon'

quasarIconSet.arrow.dropdown = getInlineIcon(faAngleDown)

const app = createApp(App)

app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$legacyApi = api
app.config.globalProperties.$constants = constants

app.use(pinia, { router })
app.use(router)
app.use(i18n)
app.use(Quasar, {
  plugins: { Dialog, Notify, Loading },
  iconSet: quasarIconSet,
})
app.use(sanitizer)
app.use(filters)
app.use(fontAwesome)
app.use(survey)
app.use(vuetify)
app.use(eventBus)
app.use(sentry, { router })
app.mount('#assist-controller')

console.log('assist is loaded')
