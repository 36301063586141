import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { CHANNEL_TYPE, DISTRIBUTION_MODE } from '@/engage/constants'
import Api from '@/engage/plugins/api'
import type { ProfilesIds } from '@/agent/types/profile'
import type { ChannelsIds, ChannelsAvailability } from '@/agent/types/channels'
import type { LoggedUserDetails } from '@/agent/types/users'
import type { Rights } from '@/engage/types/rights'
import { usePauseStore } from '@/engage/stores/pause'

export const useUserStore = defineStore('user', () => {
  const isChannelMenuShown = ref(false)
  const profile = ref<ProfilesIds[] | never[]>([])
  const userAvailableChannelIds = ref<ChannelsAvailability>({})
  const id = ref<number | null>()
  const locale = ref<string | null>(null)
  const login = ref<string | null>(null)
  const identity = ref<string | null>(null)
  const rights = ref<Rights>({})
  const pushModeAutoDisabledByActivity = ref(false)

  const userChannels = computed(() => {
    return Object.keys(userAvailableChannelIds.value).map(Number)
  })
  const userIsSupervisor = computed(() => {
    return rights.value.SUPERVISION_COMMAND_CENTER
  })
  const isPushModeAuto = computed(() => {
    return rights.value.DISTRIBUTION_MODE === DISTRIBUTION_MODE.PUSH
  })
  const userIsChatAgent = computed(() => {
    return userChannels.value.includes(CHANNEL_TYPE.CHAT.id)
  })
  const hasRightToDownloadAttachments = computed((): boolean => {
    return !!rights.value.TICKET_DOWNLOAD_ATTACHMENTS
  })
  const hasUserRightToDeleteAttachment = computed(() => {
    return (userId: number): boolean => {
      return !!rights.value.INTERACTION_FORCE_DELETE_ATTACHMENTS || (!!rights.value.INTERACTION_DELETE_ATTACHMENTS && userId === id.value)
    }
  })

  const updatePushModeDisabledByActivity = (payload: boolean) => {
    pushModeAutoDisabledByActivity.value = payload
  }
  const updateChannelsMenuDisplay = (payload: boolean) => {
    isChannelMenuShown.value = payload
  }
  const setAllChannelsDisabled = () => {
    _updateAllChannelAvailability(false)
  }
  const setAllChannelsEnabled = () => {
    _updateAllChannelAvailability(true)
  }
  const getLoggedUserDetails = async () => {
    const { data } = await Api.users.getLoggedUserDetails()
    if (data?.data) {
      updateUserAndPause(data.data as LoggedUserDetails)
    }
  }
  const updateUserAvailableChannels = async ({ item, value }: { item: ChannelsIds; value: boolean }) => {
    await Api.users.changeUserChannelState(item, value)
    userAvailableChannelIds.value[item] = value
  }
  const getUserAccessRight = async () => {
    try {
      const { data }: { data: Rights } = await Api.users.getUserAccessRight()
      rights.value = data
    } catch (e) {
      console.error(e)
    }
  }

  const updateUserAndPause = (user: LoggedUserDetails) => {
    const pauseStore = usePauseStore()
    _setUser(user)

    if (user.prePause && user.pauseStateId) {
      pauseStore.setIsPrePause(user.prePause)
      pauseStore.setCurrentPrePauseId(user.pauseStateId)
    }
  }
  const _setUser = (user: LoggedUserDetails) => {
    profile.value = user.profileTypeIds
    userAvailableChannelIds.value = user.userAvailableChannelIds
    id.value = user.userId
    locale.value = user.localeCode ? user.localeCode.split('_')[0] : null
    login.value = user.userLogin
    identity.value = user.identity || user.userLogin
  }
  const _updateAllChannelAvailability = (payload: boolean) => {
    for (const channel in userAvailableChannelIds.value) {
      const channelId = Number(channel) as ChannelsIds
      userAvailableChannelIds.value[channelId] = payload
    }
  }

  return {
    profile,
    login,
    isChannelMenuShown,
    userAvailableChannelIds,
    id,
    locale,
    identity,
    rights,
    pushModeAutoDisabledByActivity,
    userChannels,
    userIsSupervisor,
    isPushModeAuto,
    userIsChatAgent,
    hasRightToDownloadAttachments,
    hasUserRightToDeleteAttachment,
    updatePushModeDisabledByActivity,
    updateChannelsMenuDisplay,
    setAllChannelsDisabled,
    setAllChannelsEnabled,
    getLoggedUserDetails,
    updateUserAvailableChannels,
    getUserAccessRight,
    updateUserAndPause,
  }
})
