import router from '@/router'
import useNavigation from '@/common/composables/useNavigation'
import { useVonageStore } from '@/admin/stores/vonageStore'
import { useApplicationStore } from '@/common/stores/applicationStore'
import { subscribe, unsubscribe } from '@/common/services/eventBusService'
import { onBeforeMount, onMounted } from 'vue'

export function useGlobalEvents() {
  const applicationStore = useApplicationStore()
  const navigation = useNavigation()

  const showTicketHandler = (caseId: string) => {
    // force parameter is needed to force the router to
    // refresh the same route
    navigation.openCase(caseId, { force: true })
  }

  const showCustomerHandler = (customerId: string) => {
    // force parameter is needed to force the router to
    // refresh the same route
    router.push({ path: `/assist/customer/edit/${customerId}`, force: true })
  }

  const disableNavigationHandler = () => {
    console.log('EMIT: innso:disable-navigation')
    applicationStore.disabled = true
  }

  const enableNavigationHandler = () => {
    console.log('EMIT: innso:enable-navigation')
    applicationStore.disabled = false
  }

  const redirectHandler = (url: string) => {
    router.push({ path: `/assist${url}`, force: true })
  }

  const loadWhatsappVonageNumbersHandler = () => {
    useVonageStore().passWhatsAppNumbers()
  }

  const toggleActivateWhatsappAccoundHandler = (id: number) => {
    useVonageStore().updateWhatsAppNumberActiveValue(id)
  }

  const bindGlobalEvents = () => {
    subscribe('innso:show-ticket', showTicketHandler)
    subscribe('innso:show-customer', showCustomerHandler)
    subscribe('innso:disable-navigation', disableNavigationHandler)
    subscribe('innso:enable-navigation', enableNavigationHandler)
    subscribe('innso:redirect', redirectHandler)
    subscribe('innso:load-whatsapp-vonage-numbers', loadWhatsappVonageNumbersHandler)
    subscribe('innso:toggle-activate-whatsapp-account', toggleActivateWhatsappAccoundHandler)
  }

  const unbindGlobalEvents = () => {
    unsubscribe('innso:show-ticket', showTicketHandler)
    unsubscribe('innso:show-customer', showCustomerHandler)
    unsubscribe('innso:disable-navigation', disableNavigationHandler)
    unsubscribe('innso:enable-navigation', enableNavigationHandler)
    unsubscribe('innso:redirect', redirectHandler)
    unsubscribe('innso:load-whatsapp-vonage-numbers', loadWhatsappVonageNumbersHandler)
    unsubscribe('innso:toggle-activate-whatsapp-account', toggleActivateWhatsappAccoundHandler)
  }

  onMounted(() => {
    bindGlobalEvents()
  })

  onBeforeMount(() => {
    unbindGlobalEvents()
  })
}
