import HttpService from '@/common/services/HttpService'
import { get as getCachedData } from '@/common/services/LocalStorageService'
import { type InteractionTypeData, type InteractionData, InteractionIncludeEnum, type Interaction } from '@/common/types/interaction'

export const getInteractionTypes = async (): Promise<InteractionTypeData[]> => {
  return (await getCachedData<Array<InteractionTypeData>>('interactionTypes', '/api/v1/interactions/types')) || []
}

export const getInteractions = async (
  caseId?: number,
  ids?: number[],
  page?: number,
  size?: number,
  include?: InteractionIncludeEnum[],
): Promise<InteractionData[]> => {
  const params: any = {}
  if (caseId) {
    params.caseId = caseId
  }
  if (ids) {
    params.ids = ids.join()
  }
  if (page) {
    params.page = page
  }
  if (size) {
    params.size = size
  }
  if (include && include.length > 0) {
    params.include = include.join()
  }
  return HttpService.getData<InteractionData[]>(`/api/v4/interactions`, {
    params,
  })
}

export function isInteraction(entity: any): entity is Interaction {
  return (entity as Interaction).interactionDate !== undefined
}
