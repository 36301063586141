import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import Api from '@/engage/plugins/api'
import ICONS from '@/engage/constants/icons'
import iconChangeVersion, { importIcon } from '@/engage/helper/icons'
import type { PolaritySentiment } from '@/engage/types/polaritySentiment'
import type { IconDefinitionWithFileName, IconStylesPrefix, IconStylesDefinition } from '@/engage/types/icons'

export const usePolaritySentimentStore = defineStore('polaritySentiment', () => {
  const listByTicket = ref<PolaritySentiment[]>([])
  const listByInteraction = ref<PolaritySentiment[]>([])
  const listIcon = ref<IconDefinitionWithFileName[]>([])

  const getIconByName = computed(() => {
    return (fileName: IconDefinitionWithFileName['fileName'], type: IconStylesPrefix) => {
      const icons = listIcon.value.filter((icon) => icon.prefix === type && icon.fileName === fileName)

      return icons.length ? icons[icons.length - 1] : null
    }
  })

  const setListPolaritySentimentTicket = (data: PolaritySentiment[]) => {
    if (!data) return
    listByTicket.value = data
  }

  const setListPolaritySentimentInteraction = (data: PolaritySentiment[]) => {
    if (!data) return
    listByInteraction.value = data
  }

  const getFindActive = async () => {
    return Promise.all([(_getFindActiveForInteraction(), _getFindActiveForTicket())])
  }

  const fetchIcon = async ({
    icon,
    type = ICONS.REGULAR as IconStylesDefinition,
  }: {
    icon: IconDefinitionWithFileName['fileName']
    type: IconStylesDefinition
  }) => {
    const iconFound = getIconByName.value(iconChangeVersion(icon), type.prefix)
    if (iconFound) {
      return iconFound
    }

    const definition = await importIcon(icon, type.name)

    if (definition) {
      _setIconPolaritySentiment(definition)
    }

    return definition
  }

  const _setIconPolaritySentiment = (data: IconDefinitionWithFileName) => {
    const icon = listIcon.value.find((icon) => icon.prefix === data.prefix && icon.fileName === data.fileName)
    if (!icon) {
      listIcon.value.push(data)
    }
  }

  const _getFindActiveForInteraction = async () => {
    const { data } = await Api.polaritySentiment.getFindActiveForInteraction()
    setListPolaritySentimentInteraction(data)
  }

  const _getFindActiveForTicket = async () => {
    const { data } = await Api.polaritySentiment.getFindActiveForTicket()
    setListPolaritySentimentTicket(data)
  }

  return {
    listByTicket,
    listByInteraction,
    getIconByName,
    getFindActive,
    fetchIcon,
  }
})
