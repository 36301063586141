import { onMounted, onUnmounted } from 'vue'
import { useApplicationStore } from '@/common/stores/applicationStore'

export default function useFocusOutIframe() {
  const applicationStore = useApplicationStore()

  /**
   * Close menu and dropdowns when the iframe get the focus
   */
  const onBlur = () => {
    if (document.activeElement) {
      if (document.activeElement.tagName === 'IFRAME' && applicationStore.active) applicationStore.active = false
    }
  }

  const onFocus = () => {
    applicationStore.active = true
  }

  onMounted(() => {
    window.addEventListener('blur', onBlur)
    window.addEventListener('focus', onFocus)
  })

  onUnmounted(() => {
    window.removeEventListener('blur', onBlur)
    window.removeEventListener('focus', onFocus)
  })
}
