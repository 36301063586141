import BaseModel from '@/engage/services/legacy/BaseModel'

export default class ResponsesType extends BaseModel {
  constructor() {
    super()
  }

  /**
   * @returns
   * @memberof BaseModel
   */
  async getTypeMessages({ currentChannelId, skillGroupId, skillId }, str = '') {
    return this.get(`/cannedResponses/getTree?channelId=${currentChannelId}&skillGroupId=${skillGroupId}&skillId=${skillId}&str=${str}`)
  }

  /**
   * @returns
   * @memberof BaseModel
   */
  async getListingQuickMessages({ currentChannelId, skillGroupId, skillId }, str = '') {
    return this.get(`/cannedResponses/getQuickMessages?channelId=${currentChannelId}&skillGroupId=${skillGroupId}&skillId=${skillId}&str=${str}`)
  }

  /**
   * @returns {Promise}
   * @memberof BaseModel
   */
  async getTemplateMessages({ currentChannelId, skillGroupId, skillId }, str = '') {
    return this.get(`/cannedResponses/getTemplateMessages?channelId=${currentChannelId}&skillGroupId=${skillGroupId}&skillId=${skillId}&str=${str}`)
  }

  /**
   * @returns
   * @memberof BaseModel
   */
  async getCannedResponseContent({ ticketId }, id) {
    return this.get(`/cannedResponses/getCannedResponseContent/mailDraft/${id}?ticketId=${ticketId}`)
  }
}
