import { ref } from 'vue'
import type { Break } from '@/agent/types/break'
import { getBreaks } from '@/common/services/breakService'
import { defineStore } from 'pinia'

export const useBreaksStore = defineStore('breaks', () => {
  const breaks = ref<Array<Break>>([])

  const getBreakById = (id?: number | null) => {
    return breaks.value.find((b: Break) => b.userStateId === id)
  }

  const load = async () => {
    try {
      breaks.value = await getBreaks()
    } catch {
      // DO SOMETHING
    }
  }

  return {
    breaks,
    load,
    getBreakById,
  }
})
