import { useSnackbarStore } from '@/engage/stores/snackbar'

export function createAbortControllerHandler(apiArray) {
  // initializing the cancel token handler object
  const abortControllers = {}

  // for each property in apiObject, i.e. for each request
  apiArray.forEach((propertyName) => {
    // initializing the cancel token of the request
    const requestControllerHandler = {
      controller: undefined,
    }

    // associating the cancel token handler to the request name
    abortControllers[propertyName] = {
      requestAbortController: () => {
        // if a previous cancel token exists,
        // cancel the request
        requestControllerHandler.controller?.abort(`${propertyName} canceled 2`)

        // creating a new cancel token
        requestControllerHandler.controller = new AbortController()

        // returning the new cancel token
        return requestControllerHandler.controller
      },
    }
  })

  return abortControllers
}

export function apiMessageSnackbar(response) {
  const snackbarStore = useSnackbarStore()

  const successMessages = response.apiSuccesses
  const errorMessages = response.apiErrors

  if (!errorMessages?.length && !successMessages?.length) return

  const isSuccess = successMessages?.length > 0
  const messages = isSuccess ? successMessages : errorMessages

  const config = {
    text: messages.join('<br />'),
    color: isSuccess ? 'success50' : 'error50',
  }

  snackbarStore.showSnackbar(config)
}
