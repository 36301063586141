import HttpService from '@/common/services/HttpService'
import type { Space } from '@/common/types/spaces'
import { addTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'

const serviceRoot = '/api/v1/spaces'

export const getSpaces = async (): Promise<Space[]> => {
  return HttpService.getData<Space[]>(serviceRoot)
}

export const fetchSpaces = async (): Promise<any> => {
  const sessionStore = useSessionStore()
  return await Promise.all([getSpaces(), addTranslations(sessionStore.locale, PrefixTranslationsEnum.SPACE)])
}
