import { RouteRecordRaw } from 'vue-router'

const EngageView = () => import('@/engage/views/EngageView.vue')
import routes from '@/engage/router/routes'

export default [
  {
    path: '/assist/engage/view',
    component: EngageView,
    children: [...routes],
    props: {
      menu: true,
      backButton: false,
    },
    meta: {
      engage: true,
    },
  },
] as Array<RouteRecordRaw>
