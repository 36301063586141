export const enum NOTIFICATION_SCOPE {
  Local,
  Remote,
}

export type NotificationState = {
  opened: boolean
  waiting: boolean
  setTimeoutRef: ReturnType<typeof setTimeout> | null
  timeoutDelay: number
  notifications: NotificationList
  interceptors: NotificationInterceptor[]
}

export type NotificationList = Array<Notification>

export interface Notification extends NotificationToken {
  source: NOTIFICATION_SCOPE
}

export type NotificationResponse = Array<NotificationToken>

export const MessageTypeValues = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
} as const

export type MessageType = (typeof MessageTypeValues)[keyof typeof MessageTypeValues]

export type NotificationAction = {
  type: NotificationActionTypeEnum
  arg: string
}

export enum NotificationActionTypeEnum {
  S3_DOWNLOAD = 'S3_DOWNLOAD',
}

/**
 * This type is the reflect of what we receive from the server. All come from class BrowserNotification on back side.
 * We must not add more attributes than the back. And it is not necessary to duplicate attributes from QNotifyCreateOptions.
 * @property type:
 *           - notification: Scrolling banner notification
 *           - notificationItem: Browser Tab notification
 *           - toast: Toast notification
 *           - custom: Custom notification
 */
export type NotificationToken = {
  id: number
  ref: string
  type: 'notification' | 'notificationItem' | 'toast' | 'custom'
  title: string
  messageType?: MessageType
  message: string
  // Format: 2022-01-12T14:46:00Z
  date: string
  deletable?: boolean
  // action to perform, added from the server
  action?: NotificationAction
}

export enum NotificationStrategyBitEnum {
  STACK = 1 << 0,
  POP = 1 << 1,
  STACK_AND_POP = STACK | POP,
}

export type NotificationInterceptor = { ref: string; handler: (notification: Notification) => void }
