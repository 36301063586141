import { PiniaPluginContext, Store } from 'pinia'

const AllPiniaStores = new Set<Store>()

export const allStore = ({ pinia, store }: PiniaPluginContext) => {
  AllPiniaStores.add(store)
  if (!pinia.$allStores) pinia.$allStores = AllPiniaStores
}

declare module 'pinia' {
  export interface Pinia {
    $allStores: Set<Store>
  }
}
