import { defineStore } from 'pinia'
import * as VoiceService from '@/admin/services/VoiceService'
import { InboundCallConfiguration, OutboundCallConfiguration, VoiceConfigurationTabEnum, VoiceProvider } from '@/admin/types/voice'
import { ref, Ref } from 'vue'
import { useMenuStore } from '@/admin/stores/menuStore'
import { StateNames } from '@/admin/types/menu'

export const useVoiceStore = defineStore('voice', () => {
  const activeProvider: Ref<VoiceProvider> = ref(VoiceProvider.NONE)
  const selectedTabs = ref<{ [key in VoiceProvider]?: VoiceConfigurationTabEnum }>({
    [VoiceProvider.AXIALYS]: VoiceConfigurationTabEnum.GENERAL,
    [VoiceProvider.ODIGO]: VoiceConfigurationTabEnum.GENERAL,
    [VoiceProvider.CTI_INHOUSE]: VoiceConfigurationTabEnum.GENERAL,
    [VoiceProvider.GENESYS]: VoiceConfigurationTabEnum.GENERAL,
    [VoiceProvider.VOICEBOT]: VoiceConfigurationTabEnum.GENERAL,
  })
  const axialysInboundCallConfigs: Ref<Array<InboundCallConfiguration>> = ref([])
  const axialysOutboundCallConfigs: Ref<Array<OutboundCallConfiguration>> = ref([])

  const voicebotInboundCallConfigs: Ref<Array<InboundCallConfiguration>> = ref([])

  const odigoInboundCallConfigs: Ref<Array<InboundCallConfiguration>> = ref([])
  const odigoOutboundCallConfigs: Ref<Array<OutboundCallConfiguration>> = ref([])

  async function initVoice() {
    activeProvider.value = await VoiceService.getVoiceProvider()
    const menuStore = useMenuStore()
    menuStore.entryStates.set(StateNames.CTI_PROVIDER, activeProvider.value.toString())
  }

  async function updateActiveProvider(voiceProvider: VoiceProvider) {
    try {
      const result = await VoiceService.putVoiceConfiguration(voiceProvider)
      activeProvider.value = <VoiceProvider>result?.activeProvider ?? VoiceProvider.NONE
      const menuStore = useMenuStore()
      if (result?.activeProvider) {
        menuStore.entryStates.set(StateNames.CTI_PROVIDER, result.activeProvider)
      } else {
        menuStore.entryStates.delete(StateNames.CTI_PROVIDER)
      }
    } catch (message) {
      console.error(message)
    }
  }

  async function getAxialysInboundCallConfig() {
    try {
      axialysInboundCallConfigs.value = await VoiceService.getAxialysInboundCallConfigurations()
    } catch (message) {
      console.log(message)
    }
  }

  async function deleteAxialysInboundCallConfig(callConfigId: UUID) {
    try {
      await VoiceService.deleteAxialysInboundCallConfiguration(callConfigId)
      getAxialysInboundCallConfig()
    } catch (message) {
      console.log(message)
    }
  }

  async function getAxialysOutboundCallConfig() {
    try {
      axialysOutboundCallConfigs.value = await VoiceService.getAxialysOutboundCallConfigurations()
    } catch (message) {
      console.log(message)
    }
  }

  async function deleteAxialysOutboundCallConfig(callConfigId: UUID) {
    try {
      await VoiceService.deleteAxialysOutboundCallConfiguration(callConfigId)
      getAxialysOutboundCallConfig()
    } catch (message) {
      console.log(message)
    }
  }

  async function getVoicebotInboundCallConfig() {
    try {
      voicebotInboundCallConfigs.value = await VoiceService.getVoicebotInboundCallConfigurations()
    } catch (message) {
      console.log(message)
    }
  }

  async function deleteVoicebotInboundCallConfig(callConfigId: UUID) {
    try {
      await VoiceService.deleteVoicebotInboundCallConfiguration(callConfigId)
      getVoicebotInboundCallConfig()
    } catch (message) {
      console.log(message)
    }
  }

  async function getOdigoInboundCallConfig() {
    try {
      odigoInboundCallConfigs.value = await VoiceService.getOdigoInboundCallConfigurations()
    } catch (message) {
      console.log(message)
    }
  }

  async function deleteOdigoInboundCallConfig(callConfigId: UUID) {
    try {
      await VoiceService.deleteOdigoInboundCallConfiguration(callConfigId)
      getOdigoInboundCallConfig()
    } catch (message) {
      console.log(message)
    }
  }

  async function getOdigoOutboundCallConfig() {
    try {
      odigoOutboundCallConfigs.value = await VoiceService.getOdigoOutboundCallConfigurations()
    } catch (message) {
      console.log(message)
    }
  }

  async function deleteOdigoOutboundCallConfig(callConfigId: UUID) {
    try {
      await VoiceService.deleteOdigoOutboundCallConfiguration(callConfigId)
      getOdigoOutboundCallConfig()
    } catch (message) {
      console.log(message)
    }
  }

  return {
    activeProvider,
    selectedTabs,
    axialysInboundCallConfigs,
    axialysOutboundCallConfigs,
    odigoInboundCallConfigs,
    odigoOutboundCallConfigs,
    voicebotInboundCallConfigs,
    initVoice,
    updateActiveProvider,
    getAxialysInboundCallConfig,
    deleteAxialysInboundCallConfig,
    getAxialysOutboundCallConfig,
    deleteAxialysOutboundCallConfig,
    getVoicebotInboundCallConfig,
    deleteVoicebotInboundCallConfig,
    getOdigoInboundCallConfig,
    deleteOdigoInboundCallConfig,
    getOdigoOutboundCallConfig,
    deleteOdigoOutboundCallConfig,
  }
})
