import { defineStore } from 'pinia'
import { ref, Ref, toRaw } from 'vue'
import { VonageConfiguration } from '@/admin/types/vonage'
import { getAllVonageConfigurations, updateVonageWhatsAppNumber } from '@/admin/services/VonageService'
import { publish } from '@/common/services/eventBusService'
import { useEntityStore } from '@/common/stores/entityStore'

export const useVonageStore = defineStore('vonage', () => {
  const vonageConfigurations: Ref<Array<VonageConfiguration>> = ref([])
  const entityStore = useEntityStore()
  initVonage()
  async function initVonage() {
    vonageConfigurations.value = await getAllVonageConfigurations()
  }

  async function findVonageSmsNumber(id: number) {
    await initVonage()
    for (const vonageConf of vonageConfigurations.value.values()) {
      for (const phoneNumber of vonageConf.smsNumbers ?? []) {
        if (id == phoneNumber.id) {
          phoneNumber.vonageConfiguration = vonageConf
          delete phoneNumber.vonageConfiguration.smsNumbers
          return phoneNumber
        }
      }
    }

    return null
  }

  async function findVonageWhatsAppNumber(id: number) {
    await initVonage()
    for (const vonageConf of vonageConfigurations.value.values()) {
      for (const phoneNumber of vonageConf.whatsappNumbers ?? []) {
        if (id == phoneNumber.id) {
          phoneNumber.vonageConfiguration = vonageConf
          delete phoneNumber.vonageConfiguration.whatsappNumbers
          return phoneNumber
        }
      }
    }

    return null
  }

  async function passWhatsAppNumbers() {
    await entityStore.getSkills()
    await initVonage()
    const whatsappNumbers = vonageConfigurations.value.map((configuration) => toRaw(configuration.whatsappNumbers) || []).flat()
    const whatsappNumbersWithSkillName = whatsappNumbers.map((number) => {
      const skillCode = number.defaultSkill ? entityStore.skills.find((skill) => skill.id === number.defaultSkill)?.code : null
      return {
        ...number,
        defaultSkillName: skillCode ? entityStore.getSkillLabel(skillCode) : '',
      }
    })
    publish('assist:load-vonage-whatsapp-numbers', whatsappNumbersWithSkillName)
  }

  async function updateWhatsAppNumberActiveValue(id: number) {
    const whatsAppNumber = await findVonageWhatsAppNumber(id)
    if (!whatsAppNumber) {
      throw new Error('WhatsApp number not found in vonageConfigurations')
    }
    const updatedWhatsAppNumber = { ...whatsAppNumber, active: !whatsAppNumber?.active }
    updateVonageWhatsAppNumber(updatedWhatsAppNumber, true)
  }

  return {
    initVonage,
    findVonageSmsNumber,
    findVonageWhatsAppNumber,
    passWhatsAppNumbers,
    updateWhatsAppNumberActiveValue,
    vonageConfigurations,
  }
})
