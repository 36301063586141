import { CHANNELS, CHANNEL_TYPE, GROUPS_COMMAND_CENTER } from '@/engage/constants'
import { useHelpRequestStore } from '@/engage/stores/helpRequests'

/**
 * Compare route excluding query parameters
 */
const isSameRoutePath = (to, from) => {
  return to.path === from.path && to.name === from.name
}

const getCommandCenterRouteByActivity = (activity) => {
  const helpRequestsStore = useHelpRequestStore()
  // Will be condition by help request status
  const hasHelpRequest = helpRequestsStore.helpRequestsAvailabilityByCaseId(activity.ticketId)
  const routeName = hasHelpRequest ? GROUPS_COMMAND_CENTER.HELP : GROUPS_COMMAND_CENTER.OTHER

  return {
    name: routeName,
    params: {
      ticketId: activity.ticketId,
    },
  }
}

const getEngageRouteByActivity = (activity) => {
  const channelName = CHANNELS[activity.currentChannelId].name
  let routeName = channelName

  if (channelName === CHANNEL_TYPE.CHAT.name) {
    routeName += activity.conversationOpen ? '-open' : '-close'
  }

  return {
    name: routeName,
    params: {
      ticketId: activity.ticketId,
    },
  }
}

export { isSameRoutePath, getEngageRouteByActivity, getCommandCenterRouteByActivity }
