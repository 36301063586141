import BaseModel from '@/engage/services/legacy/BaseModel'

export default class Countries extends BaseModel {
  constructor() {
    super()
    this._service = 'rest'
    this._model = 'refcountry'
  }

  /**
   * Return countries list automatically regarding user language
   *
   * @returns {Promise}
   */
  getCountries() {
    return this.get(`${this.resourceUrl}/`)
  }
}
