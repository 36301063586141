import { ref, Ref, computed, ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import { Locale } from '@/admin/types/locale'
import { fetchSkills, fetchLocales } from '@/common/services/EntityService'
import { fetchSpaces } from '@/common/services/SpaceService'
import { useSessionStore } from '@/common/stores/sessionStore'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import i18n from '@/plugins/i18n'
import { Skill } from '@/common/types/skills'
import { Space } from '@/common/types/spaces'
import { useTypologyStore } from '@/common/stores/typologyStore'

const { t } = i18n.global
export const useEntityStore = defineStore('entity', () => {
  const spaces: Ref<Space[]> = ref([])
  const skills: Ref<Skill[]> = ref([])
  const locales: Ref<Locale[]> = ref([])

  async function getSpaces() {
    try {
      ;[spaces.value] = await fetchSpaces()
    } catch (error) {
      console.log(error)
    }
  }
  async function getSkills() {
    try {
      ;[skills.value] = await fetchSkills()
    } catch (error) {
      console.log(error)
    }
  }
  async function getLocales() {
    try {
      const localesFetched = await fetchLocales()
      const sessionStore = useSessionStore()
      locales.value = localesFetched.map((locale) => ({
        ...locale,
        required: locale.default || locale.code === sessionStore.locale,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = () => {
    return Promise.all([getSpaces(), getSkills(), useTypologyStore().getTypologies(), getLocales()])
  }

  const getLocalesSorted: ComputedRef<Locale[]> = computed(() => {
    return locales.value.sort((a, b) => a.displayOrder - b.displayOrder)
  })

  const getLocaleByCode = computed(() => {
    return (code: string): Locale | undefined => {
      return locales.value.find((l) => l.code === code)
    }
  })

  const getSpaceLabel = computed(() => {
    return (spaceId: number): string => {
      const space = spaces.value.find((value) => value.id === spaceId)
      return t(space?.name ?? '')
    }
  })

  const getSkillLabel = computed(() => {
    return (skillCode: string): string => {
      const skill = skills.value.find((value) => value.code === skillCode)
      return t(PrefixTranslationsEnum.SKILL + skill?.code)
    }
  })

  return {
    spaces,
    skills,
    locales,
    fetchData,
    getSpaces,
    getSkills,
    getLocales,
    getLocalesSorted,
    getLocaleByCode,
    getSpaceLabel,
    getSkillLabel,
  }
})
