import { featureFlags } from '@/common/helpers/configResolver'
import BaseModel from '@/engage/services/legacy/BaseModel'

export default class FeatureFlag extends BaseModel {
  constructor() {
    super()

    this._service = 'api/v1'
    this._model = 'feature-flags'
  }

  async customerMergeEnabled() {
    const MERGE_CUSTOMER_FEATURE_FLAG = 'MERGE_CUSTOMER_RECORDS'
    return featureFlags.includes(MERGE_CUSTOMER_FEATURE_FLAG)
  }
}
