import { axiosInstance } from '@/common/services/HttpService'
import qs from 'qs'

export default class BaseModel {
  constructor() {
    this._$http = axiosInstance
    this._service = 'social'

    axiosInstance.interceptors.response.use(this.responseHandler, function (error) {
      return Promise.reject(error)
    })
  }

  get $http() {
    return this._$http
  }

  get service() {
    return this._service
  }

  get model() {
    return this._model
  }

  get resourceUrl() {
    return `${this.service}/${this.model}`
  }

  /**
   * @param {Object} config
   * @returns {Promise}
   * @memberof BaseModel
   */
  request(config) {
    return this.$http.request(config)
  }

  /**
   * @param {String} url
   * @param {Object} config
   * @returns {Promise}
   * @memberof BaseModel
   */
  get(url, config = {}) {
    return this.$http.get(url, config)
  }

  /**
   * @param {String} url
   * @param {Object} config
   * @returns {Promise}
   * @memberof BaseModel
   */
  post(url, config = {}, options = {}) {
    return this.$http.post(
      url,
      {
        ...config,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      },
      options,
    )
  }

  /**
   * @param {String} url
   * @param {Object} config
   * @returns {Promise}
   * @memberof BaseModel
   */
  postFormData(url, formData, config = {}) {
    return this.$http.post(url, formData, {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  }

  /**
   * @param {String} url
   * @param {Object} config
   * @returns {Promise}
   * @memberof BaseModel
   */
  patch(url, config) {
    return this.$http.patch(url, config)
  }

  /**
   * @param {String} url
   * @param {Object} config
   * @returns {Promise}
   * @memberof BaseModel
   */
  delete(url, config = null) {
    return this.$http.delete(url, config)
  }

  /**
   * @param {String} url
   * @param {Object} config
   * @returns {Promise}
   * @memberof BaseModel
   */
  put(url, config = null) {
    return this.$http.put(url, config)
  }

  formDataFile(files) {
    const formData = new FormData()

    if (Array.isArray(files)) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
      }
    } else {
      formData.append('file', files)
    }

    return formData
  }

  multiPartObject(obj) {
    const json = JSON.stringify(obj)
    return new Blob([json], {
      type: 'application/json',
    })
  }

  responseHandler(response) {
    response.apiErrors = []
    response.apiSuccesses = []

    const {
      data: { messages = [], status },
    } = response

    if (messages) {
      messages.forEach((item) => {
        if (item.message) {
          if (status !== 'success') {
            response.apiErrors.push(item.message)
          } else {
            response.apiSuccesses.push(item.message)
          }
        }
      })
    }

    return response
  }
}
