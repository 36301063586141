import BaseModel from '@/engage/services/legacy/BaseModel'
import { useEngageCasesStore } from '@/engage/stores/engageCases'

export default class ChatConversation extends BaseModel {
  constructor() {
    super()
    this._service = 'social'
    this._model = 'chat'
  }

  get currentActivityId() {
    const casesStore = useEngageCasesStore()
    return casesStore.currentActivityId
  }

  /**
   * @param {Number, Number, Number, String} { skillId, reasonCode, userId, comment }
   * @returns
   * @memberof Conversation
   */
  transfer({ skillId, reasonCode, userId, comment }) {
    return this.post(`${this.resourceUrl}/transfer/${this.currentActivityId}`, {
      skillId,
      reasonCode,
      userId,
      comment,
    })
  }

  /**
   * @param {Number, String} { userId, comment }
   * @returns {Promise}
   * @memberof Ticket
   */
  reassign({ userId, comment }) {
    return this.post(`${this.resourceUrl}/reassign/${this.currentActivityId}`, {
      userId,
      comment,
    })
  }

  /**
   * @param {String} comment
   * @returns {Promise}
   * @memberof Ticket
   */
  release({ comment }) {
    return this.post(`${this.resourceUrl}/release/${this.currentActivityId}`, {
      comment,
    })
  }
}
