import { defineStore } from 'pinia'
import { ref } from 'vue'
import router from '@/router'
import type { RouteParamsGeneric, LocationQuery } from 'vue-router'

export const useRouterStore = defineStore('router', () => {
  const routerParams = ref<RouteParamsGeneric | null>(null)
  const routerQuery = ref<LocationQuery | null>(null)

  const updateRouterStore = () => {
    routerParams.value = router.currentRoute.value.params
    routerQuery.value = router.currentRoute.value.query
  }

  return {
    routerParams,
    routerQuery,
    updateRouterStore,
  }
})
