import Action from '@/engage/services/legacy/Action'
import Activity from '@/engage/services/legacy/Activity'
import Chat from '@/engage/services/legacy/Chat'
import CommandCenter from '@/engage/services/legacy/CommandCenter'
import Config from '@/engage/services/legacy/Config'
import Conversation from '@/engage/services/legacy/Conversation'
import Customer from '@/engage/services/legacy/Customer'
import I18n from '@/engage/services/legacy/I18n'
import Interaction from '@/engage/services/legacy/Interaction'
import Parameters from '@/engage/services/legacy/Parameters'
import Pause from '@/engage/services/legacy/Pause'
import PolaritySentiment from '@/engage/services/legacy/PolaritySentiment'
import ResponsesType from '@/engage/services/legacy/ResponsesType'
import Screen from '@/engage/services/legacy/Screen'
import Skill from '@/engage/services/legacy/Skill'
import Sms from '@/engage/services/legacy/Sms'
import Ticket from '@/engage/services/legacy/Ticket'
import Typology from '@/engage/services/legacy/Typology'
import Users from '@/engage/services/legacy/Users'
import Mail from '@/engage/services/legacy/Mail'
import Countries from '@/engage/services/legacy/Countries'
import UserGroups from '@/engage/services/legacy/UserGroups'
import ChatConversation from '@/engage/services/legacy/ChatConversation'
import CryptoConverter from '@/engage/services/legacy/CryptoConverter'
import FeatureFlag from '@/engage/services/legacy/FeatureFlag.js'

const api = {
  action: new Action(),
  activity: new Activity(),
  chat: new Chat(),
  commandCenter: new CommandCenter(),
  config: new Config(),
  conversation: new Conversation(),
  customer: new Customer(),
  i18n: new I18n(),
  interaction: new Interaction(),
  parameters: new Parameters(),
  pause: new Pause(),
  polaritySentiment: new PolaritySentiment(),
  responsesType: new ResponsesType(),
  screen: new Screen(),
  skill: new Skill(),
  sms: new Sms(),
  ticket: new Ticket(),
  typology: new Typology(),
  users: new Users(),
  mail: new Mail(),
  countries: new Countries(),
  userGroups: new UserGroups(),
  chatConversation: new ChatConversation(),
  cryptoConverter: new CryptoConverter(),
  featureFlag: new FeatureFlag(),
}

export default api
