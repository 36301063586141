import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler } from '@/engage/services/legacy/utils'

const controllerAbortObject = createAbortControllerHandler(['activityGetListing', 'searchActivities'])
export default class Activity extends BaseModel {
  constructor() {
    super()
  }

  /**
   * @returns
   * @memberof BaseModel
   */
  async getListing() {
    return this.get(`${this.service}/listing`, {
      signal: controllerAbortObject['activityGetListing'].requestAbortController().signal,
    })
  }

  async saveQualifyTicket(data, activityId) {
    return this.post(`${this.service}/qualify/${activityId}`, data)
  }

  async getChannelGroups() {
    return this.get(`${this.service}/getChannelGroups`)
  }

  async searchActivities(searchTerms) {
    return this.get(`${this.service}/searchOnMyOpenTickets`, {
      params: {
        query: searchTerms,
      },
      signal: controllerAbortObject['searchActivities'].requestAbortController().signal,
    })
  }
}
