import HttpService from '@/common/services/HttpService'
import type {
  AxialysConfiguration,
  InboundCallConfiguration,
  OdigoConfiguration,
  OutboundCallConfiguration,
  VoicebotConfiguration,
} from '@/admin/types/voice'
import { Call, CallRequestBody, VoiceConfiguration, VoiceProvider } from '@/admin/types/voice'
import { displayMessage } from '@/common/services/NotificationDisplayService'
import i18n from '@/plugins/i18n'
import { CallParams, CtiOdigoAgentAndStatus } from '@/cti/types/cti'

const { t } = i18n.global

const configurationPath = `call/api/v1/configuration/`

const axialysOutboundCallConfigurationPath = `call/api/v1/axialys/outbound-call-configurations`
const axialysConfigurationsPath = `/call/api/v1/axialys/configuration`
const axialysInboundCallConfigurationPath = `call/api/v1/axialys/inbound-call-configurations`
const axialysConnectUrl = `call/api/v1/axialys/configuration/connect`

const odigoConnectUrl = `call/api/v1/odigo/configuration/connect`
const odigoOutboundCallConfigurationPath = `call/api/v1/odigo/outbound-call-configurations`
const odigoConfigurationsPath = `/call/api/v1/odigo/configuration`
const odigoInboundCallConfigurationPath = `call/api/v1/odigo/inbound-call-configurations`
const odigoAgentAndStatusPath = 'call/api/v1/odigo/agent-status'

const voicebotConfigurationsPath = `/call/api/v1/voicebot/configuration`
const voicebotInboundCallConfigurationPath = `call/api/v1/voicebot/inbound-call-configurations`

export const getVoiceProvider = async () => {
  try {
    return (await HttpService.getData<VoiceConfiguration>(configurationPath))?.activeProvider ?? VoiceProvider.NONE
  } catch (error) {
    console.error(error)
    return VoiceProvider.NONE
  }
}

export const putVoiceConfiguration = async (activeProvider: VoiceProvider) => {
  try {
    const res = await HttpService.putData<VoiceConfiguration>(configurationPath, { activeProvider })
    displayMessage(t('assist.admin.voice.provider.message.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.provider.message.update.error', { provider: activeProvider }), 'danger')
    return
  }
}

export const getAxialysOutboundCallConfigurations = async (): Promise<OutboundCallConfiguration[]> => {
  try {
    return await HttpService.getData<OutboundCallConfiguration[]>(axialysOutboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getAxialysOutboundCallConfigurationById = async (id: UUID) => {
  try {
    return await HttpService.getData<OutboundCallConfiguration>(`${axialysOutboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createAxialysOutboundCallConfiguration = async (axialysOutboundCallConfiguration: OutboundCallConfiguration) => {
  try {
    const res = await HttpService.postData(axialysOutboundCallConfigurationPath, axialysOutboundCallConfiguration)
    displayMessage(t('assist.admin.voice.axialys.outbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.outbound.create.error'), 'danger')
    return
  }
}

export const updateAxialysOutboundCallConfiguration = async (axialysOutboundCallConfiguration: OutboundCallConfiguration) => {
  try {
    const res = await HttpService.putData(
      `${axialysOutboundCallConfigurationPath}/${axialysOutboundCallConfiguration.id}`,
      axialysOutboundCallConfiguration,
    )
    displayMessage(t('assist.admin.voice.axialys.outbound.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.outbound.update.error'), 'danger')
    return
  }
}
export const deleteAxialysOutboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${axialysOutboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.axialys.outbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.outbound.delete.error'), 'danger')
    return
  }
}
export const getAxialysInboundCallConfigurations = async (): Promise<InboundCallConfiguration[]> => {
  try {
    return await HttpService.getData<InboundCallConfiguration[]>(axialysInboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getAxialysInboundCallConfigurationById = async (id: UUID): Promise<InboundCallConfiguration | undefined> => {
  try {
    return await HttpService.getData<InboundCallConfiguration>(`${axialysInboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createAxialysInboundCallConfiguration = async (axialysInboundCallConfiguration: InboundCallConfiguration) => {
  try {
    const res = await HttpService.postData(axialysInboundCallConfigurationPath, axialysInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.axialys.inbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.inbound.create.error'), 'danger')
    return
  }
}

export const updateAxialysInboundCallConfiguration = async (axialysInboundCallConfiguration: InboundCallConfiguration) => {
  try {
    await HttpService.putData(`${axialysInboundCallConfigurationPath}/${axialysInboundCallConfiguration.id}`, axialysInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.axialys.inbound.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.inbound.update.error'), 'danger')
  }
}
export const deleteAxialysInboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${axialysInboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.axialys.inbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.inbound.delete.error'), 'danger')
    return false
  }
}
export const updateAxialysConfiguration = async (axialysConfiguration: AxialysConfiguration) => {
  try {
    await HttpService.putData(`${axialysConfigurationsPath}`, axialysConfiguration)
    displayMessage(t('assist.admin.voice.axialys.configuration.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.configuration.update.error'), 'danger')
  }
}

export const getAxialysConfiguration = async (): Promise<AxialysConfiguration | undefined> => {
  try {
    return await HttpService.getData<AxialysConfiguration>(`${axialysConfigurationsPath}`)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const generateAxialysToken = async () => {
  try {
    return await HttpService.postData(`${axialysConnectUrl}`)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.axialys.token.error'), 'danger')
    throw error
  }
}

export const generateOdigoToken = async () => {
  try {
    return await HttpService.postData(`${odigoConnectUrl}`)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.odigo.token.error'), 'danger')
    throw error
  }
}

export const getOdigoOutboundCallConfigurations = async (): Promise<OutboundCallConfiguration[]> => {
  try {
    return await HttpService.getData<OutboundCallConfiguration[]>(odigoOutboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getOdigoOutboundCallConfigurationById = async (id: UUID) => {
  try {
    return await HttpService.getData<OutboundCallConfiguration>(`${odigoOutboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createOdigoOutboundCallConfiguration = async (odigoOutboundCallConfiguration: OutboundCallConfiguration) => {
  try {
    const res = await HttpService.postData(odigoOutboundCallConfigurationPath, odigoOutboundCallConfiguration)
    displayMessage(t('assist.admin.voice.odigo.outbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.outbound.create.error'), 'danger')
    return
  }
}

export const updateOdigoOutboundCallConfiguration = async (odigoOutboundCallConfiguration: OutboundCallConfiguration) => {
  try {
    const res = await HttpService.putData(
      `${odigoOutboundCallConfigurationPath}/${odigoOutboundCallConfiguration.id}`,
      odigoOutboundCallConfiguration,
    )
    displayMessage(t('assist.admin.voice.odigo.outbound.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.outbound.update.error'), 'danger')
    return
  }
}
export const deleteOdigoOutboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${odigoOutboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.odigo.outbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.outbound.delete.error'), 'danger')
    return
  }
}
export const getOdigoInboundCallConfigurations = async (): Promise<InboundCallConfiguration[]> => {
  try {
    return await HttpService.getData<InboundCallConfiguration[]>(odigoInboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getOdigoInboundCallConfigurationById = async (id: UUID): Promise<InboundCallConfiguration | undefined> => {
  try {
    return await HttpService.getData<InboundCallConfiguration>(`${odigoInboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createOdigoInboundCallConfiguration = async (odigoInboundCallConfiguration: InboundCallConfiguration) => {
  try {
    const res = await HttpService.postData(odigoInboundCallConfigurationPath, odigoInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.odigo.inbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.inbound.create.error'), 'danger')
    return
  }
}

export const updateOdigoInboundCallConfiguration = async (odigoInboundCallConfiguration: InboundCallConfiguration) => {
  try {
    await HttpService.putData(`${odigoInboundCallConfigurationPath}/${odigoInboundCallConfiguration.id}`, odigoInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.odigo.inbound.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.inbound.update.error'), 'danger')
  }
}
export const deleteOdigoInboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${odigoInboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.odigo.inbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.inbound.delete.error'), 'danger')
    return false
  }
}

export const updateOdigoConfiguration = async (odigoConfiguration: OdigoConfiguration) => {
  try {
    await HttpService.putData(`${odigoConfigurationsPath}`, odigoConfiguration)
    displayMessage(t('assist.admin.voice.odigo.configuration.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.configuration.update.error'), 'danger')
  }
}

export const getOdigoConfiguration = async (): Promise<OdigoConfiguration | undefined> => {
  try {
    return await HttpService.getData<OdigoConfiguration>(`${odigoConfigurationsPath}`)
  } catch (error) {
    console.log(error)
  }
}

export const getOdigoAgentAndStatus = async (): Promise<CtiOdigoAgentAndStatus | null> => {
  try {
    return await HttpService.getData<CtiOdigoAgentAndStatus>(`${odigoAgentAndStatusPath}`)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const httpNewCall = async (callRequestBody: CallRequestBody): Promise<Call | undefined> => {
  try {
    return await HttpService.postData<Call>(`/call/api/v1/calls/`, callRequestBody)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.newcall.error'), 'danger')
  }
}

export const sendScreenPopEvent = async (callParams: CallParams, parkPageToken: string): Promise<void> => {
  try {
    return await HttpService.postData<void>(`/cti/ctiScreenPopEvent?parkPageToken=${parkPageToken}`, callParams)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.cti.screepop.error'), 'danger')
  }
}

export const getVoicebotInboundCallConfigurations = async (): Promise<InboundCallConfiguration[]> => {
  try {
    return await HttpService.getData<InboundCallConfiguration[]>(voicebotInboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getVoicebotInboundCallConfigurationById = async (id: UUID): Promise<InboundCallConfiguration | undefined> => {
  try {
    return await HttpService.getData<InboundCallConfiguration>(`${voicebotInboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createVoicebotInboundCallConfiguration = async (voicebotInboundCallConfiguration: InboundCallConfiguration) => {
  try {
    const res = await HttpService.postData(voicebotInboundCallConfigurationPath, voicebotInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.voicebot.inbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.inbound.create.error'), 'danger')
    return
  }
}

export const updateVoicebotInboundCallConfiguration = async (voicebotInboundCallConfiguration: InboundCallConfiguration) => {
  try {
    await HttpService.putData(`${voicebotInboundCallConfigurationPath}/${voicebotInboundCallConfiguration.id}`, voicebotInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.voicebot.inbound.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.inbound.update.error'), 'danger')
  }
}
export const deleteVoicebotInboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${voicebotInboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.voicebot.inbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.inbound.delete.error'), 'danger')
    return false
  }
}
export const updateVoicebotConfiguration = async (voicebotConfiguration: VoicebotConfiguration) => {
  try {
    await HttpService.putData(`${voicebotConfigurationsPath}`, voicebotConfiguration)
    displayMessage(t('assist.admin.voice.voicebot.configuration.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.configuration.update.error'), 'danger')
  }
}

export const getVoicebotConfiguration = async (): Promise<VoicebotConfiguration | undefined> => {
  try {
    return await HttpService.getData<VoicebotConfiguration>(`${voicebotConfigurationsPath}`)
  } catch (error) {
    console.log(error)
    throw error
  }
}
