import axios, { AxiosRequestConfig, type AxiosResponse } from 'axios'
import configResolver from '@/common/helpers/configResolver'
import { displayApiResponseData } from '@/common/services/NotificationDisplayService'
import { isApiResponseData } from '@/common/helpers/http'
import { getCurrentKeycloakToken, isAccessTokenPropagationToRequestEnabled } from '@/common/services/AuthenticationService'

declare module 'axios' {
  export interface AxiosRequestConfig {
    raw?: boolean
  }
}

const axiosOptions = {
  baseURL: configResolver.pathname,
  debug: process.env.NODE_ENV === 'development',
  headers: {
    [configResolver.csrf.header]: configResolver.csrf.token,
  },
}

export const axiosInstance = axios.create(axiosOptions)

/**
 * Global error interceptor
 *
 * API errors are reported to the user. This behaviour can
 * be disabled by switching the config.raw parameter to true.
 */
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.config?.raw) return Promise.reject(error)

      const responseDataToken = error.response?.data
      if (isApiResponseData(responseDataToken)) {
        displayApiResponseData(responseDataToken, 'warning')
      }
    }

    return Promise.reject(error)
  },
)

export const redirectUserToLoginPageOnExpiredSession = async (response: AxiosResponse): Promise<boolean> => {
  if (response.headers['content-type'] !== 'text/html;charset=UTF-8') return false

  const currentURL = response.request.responseURL
  let logoutPath = null

  const oidcLogoutUrl = '/assist/oidc-logout'
  if (currentURL.includes(oidcLogoutUrl)) {
    logoutPath = oidcLogoutUrl
  } else {
    const matches = currentURL?.match(/login(\?forceLogout)?$/)
    if (!Array.isArray(matches)) return false

    const [standardLogout, forceLogout] = matches
    if (forceLogout) {
      logoutPath = '/assist/login?forceLogout'
    } else if (standardLogout) {
      logoutPath = '/assist/login?logout'
    }
  }

  if (logoutPath) {
    const { default: router } = await import('@/router')
    await router.push(logoutPath)
    return true
  }
  return false
}

// Follow auth redirection in case of logout
// Due to the impossibility to manage it through Spring config
axiosInstance.interceptors.response.use(
  async function (response) {
    const isRedirected = await redirectUserToLoginPageOnExpiredSession(response)
    if (isRedirected) return Promise.reject(new Error('Unauthorized'))

    return response
  },
  function (error) {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.request.use((config) => {
  if (config.url && isAccessTokenPropagationToRequestEnabled(configResolver.root, config.url)) {
    config.headers['Authorization'] = 'Bearer ' + getCurrentKeycloakToken()
  }
  return config
})

/**
 * @param {String} url
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const getData = async <T>(url: string, config: AxiosRequestConfig = {}): Promise<T> => {
  try {
    const { data } = await axiosInstance.get<T>(url, config)
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.message)
    } else {
      return Promise.reject(error)
    }
  }
}

const get = <T = any>(url: string, config: AxiosRequestConfig = {}) => {
  return axiosInstance.get<T>(url, config)
}

/**
 * @param {String} url
 * @param {Object} data
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const post = <T = any>(url: string, data = {}, config: AxiosRequestConfig = {}) => {
  return axiosInstance.post<T>(url, data, config)
}

/**
 * @param {String} url
 * @param {Object} indata
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const postData = async <T>(url: string, indata = {}, config: AxiosRequestConfig = {}): Promise<T> => {
  try {
    const { data } = await axiosInstance.post<T>(url, indata, config)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * @param {String} url
 * @param {Object} indata
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const putData = async <T>(url: string, indata = {}, config: AxiosRequestConfig = {}): Promise<T> => {
  try {
    const { data } = await axiosInstance.put<T>(url, indata, config)
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.message)
    } else {
      return Promise.reject(error)
    }
  }
}

/**
 * @param {String} url
 * @param {Object} data
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const put = <T = any>(url: string, data = {}, config: AxiosRequestConfig = {}) => {
  return axiosInstance.put<T>(url, data, config)
}

/**
 * @param {String} url
 * @param {Object} data
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const patch = <T = any>(url: string, data = {}, config: AxiosRequestConfig = {}) => {
  return axiosInstance.patch<T>(url, data, config)
}

/**
 * @param {String} url
 * @param {Object} indata
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const patchData = async <T>(url: string, indata = {}, config: AxiosRequestConfig = {}): Promise<T> => {
  try {
    const { data } = await axiosInstance.patch<T>(url, indata, config)
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.message)
    } else {
      return Promise.reject(error)
    }
  }
}

/**
 * @param {String} url
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const remove = <T = any>(url: string, config: AxiosRequestConfig = {}) => {
  return axiosInstance.delete<T>(url, config)
}

/**
 * @param {String} url
 * @param {AxiosRequestConfig} config
 * @returns {Promise}
 */
const deleteData = async <T>(url: string, config: AxiosRequestConfig = {}): Promise<T> => {
  try {
    const { data } = await axiosInstance.delete<T>(url, config)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult => input.status === 'rejected'
const isFulfilled = <T = any>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> => input.status === 'fulfilled'

export default {
  get,
  getData,
  post,
  postData,
  put,
  putData,
  patch,
  patchData,
  delete: remove,
  deleteData,
  isRejected,
  isFulfilled,
}
