import mitt from 'mitt'
import type { AssistEventBus, Events, Handler } from '@/common/types/event'
import { useApplicationStore } from '@/common/stores/applicationStore'

let _eventBus: AssistEventBus

// Called only once by eventBus plugin to save initialize eventBus
export const initialize = () => {
  _eventBus = mitt<Events>()
  window.assistEventBus = _eventBus
}

export function publish<Key extends keyof Events>(key: Key, event?: Events[Key]) {
  _eventBus.emit<Key>(key, event as Events[Key])
}

export function openLegacyModal<Key extends keyof Events>(key: Key, event: Events[Key]) {
  const applicationStore = useApplicationStore()
  applicationStore.disabled = true

  publish(key, event)
}

export function subscribe<Key extends keyof Events>(key: Key, handler: Handler<Events[Key]>) {
  _eventBus.on(key, handler)
}

export function unsubscribe<Key extends keyof Events>(key: Key, handler?: Handler<Events[Key]>) {
  if (handler) _eventBus.off(key, handler)
  else _eventBus.off(key)
}

export function reset(scope: string) {
  const eventsKeys = [..._eventBus.all.keys()]
  eventsKeys.forEach((key) => {
    if (key.startsWith(scope)) _eventBus.off(key as keyof Events)
  })
}
