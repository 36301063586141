import BaseModel from '@/engage/services/legacy/BaseModel'
import { useChatStore } from '@/engage/stores/chat'

export default class Chat extends BaseModel {
  constructor() {
    super()
    this._service = 'api'
    this._model = 'chat'
  }

  get currentChatUSer() {
    const chatStore = useChatStore()
    return chatStore.user ? chatStore.user.identity : null
  }

  /**
   * Retrieve a token
   * @returns {Promise<token|string>}
   * @memberof Chat
   */
  getChatToken() {
    return this.post(`${this.resourceUrl}/tokens`, {
      userIdentity: this.currentChatUSer,
    })
  }

  /**
   * Delete a chat
   * @returns {Promise}
   * @memberof Chat
   */
  deleteChat(sid) {
    return this.delete(`${this.resourceUrl}/rooms/${sid}`)
  }

  connectedAgents() {
    return this.get(`social/chat/connectedAgents`)
  }

  /**
   * Agent chat is connecting to Twilio and available on queuing when enable
   * @returns {Promise}
   * @memberof Chat
   */
  chatAgentConnected() {
    return this.post(`${this.resourceUrl}/userConnected`)
  }
}
