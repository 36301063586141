import HttpService from '@/common/services/HttpService'
import { InstanceParameter } from '@/common/types/instanceParameter'

const API_PARAMETERS = '/api/v1/parameters'
/**
 * Get instance parameter by code
 * @param code parameter code
 * @returns InstanceParameter
 */
export const getParameterByCode = async (code: string): Promise<InstanceParameter> => {
  return HttpService.getData<InstanceParameter>(API_PARAMETERS + '/' + code)
}

export const getParameterList = () => {
  return HttpService.getData<InstanceParameter[]>(API_PARAMETERS)
}

export const updateParameterValue = (paramCode: string, paramValue: string): Promise<InstanceParameter> => {
  return HttpService.putData<InstanceParameter>(API_PARAMETERS + '/' + paramCode, paramValue, { headers: { 'Content-Type': 'text/plain' } })
}
