import { FieldWrapper, FieldTypeEnum, EntityFieldCodeEnum } from '@/common/types/fields'
import { EntityEnum } from '@/common/types/general'
import { ColumnDefinition } from '@/search/types/resultViewConfig'
import { Case } from '@/common/types/case'
import { Customer } from '@/common/types/customer'
import { Interaction } from '@/common/types/interaction'
import { QTableProps } from 'quasar'

export const entitiesAvailable = [EntityEnum.TICKET, EntityEnum.CUSTOMER, EntityEnum.INTERACTION]

/**
 * All panels displayable in search
 */
export enum PanelEnum {
  SearchForm = 'SearchForm', // Criteria
  SearchResult = 'SearchResult', // Result
}

/**
 * All operations available for criteria
 */
export enum OperationEnum {
  eq = 'eq',
  in = 'in',
  gt = 'gt',
  ge = 'ge',
  lt = 'lt',
  le = 'le',
  custom = 'custom',
}

/**
 * Specific Wrapper for the search
 * defaultOperation is the default operation for each criteria
 * operation is to define a specific operation for each criteria
 * options include list of possible values of field, if applicable. Used when we apply a search filter.
 * customMethodOnApplyFilter is a custom method to set the values on the component when we apply a filter
 * replaceCustomTagsByValues is a custom method to replace custom tags by good values before to apply the search
 */
export interface SearchFieldWrapper extends FieldWrapper {
  readonly defaultOperation: OperationEnum
  operation: OperationEnum
  options?: string[]
  customMethodOnApplyFilter?: (values: string[]) => void
  replaceCustomTagsByValues?: () => string[]
}

/**
 * Map of SearchFieldWrapper with code as key
 */
export type Criteria = {
  [code: string]: SearchFieldWrapper
}

/**
 * Map of Criteria used By GroupedCriteria with code as key
 */
export type GroupedCriteria = {
  [code: string]: SearchFieldWrapper
}

/**
 * List of grouped criteria
 */
export enum GroupedCriteriaParentEnum {
  CUSTOMER_DELETED = 'CUSTOMER_DELETED',
  OTHER_TICKET_ACTION = 'OTHER_TICKET_ACTION',
  TICKET_ACTION_REASON = 'TICKET_ACTION_REASON',
  TICKET_IMPORT = 'TICKET_IMPORT',
}

/**
 * All placements available
 */
export const enum FieldsPlacementEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  GROUP = 'GROUP',
}

/**
 * Those two types are important to defined the next type : fieldsPlacementObject
 */
type KeysFieldsPlacement<K extends string> = Record<K, Array<EntityFieldCodeEnum | GroupedCriteriaParentEnum>>
type KeysEntity<K extends string> = Record<K, KeysFieldsPlacement<FieldsPlacementEnum>>

/**
 * Schema of entity fields disposition in search form panel
 * Keep the order, it is important
 */
export const fieldsPlacementObject: KeysEntity<EntityEnum> = {
  TICKET: {
    LEFT: [
      EntityFieldCodeEnum.TICKET_ID,
      EntityFieldCodeEnum.EXTERNAL_REF,
      EntityFieldCodeEnum.CREATION_SUMMARY,
      EntityFieldCodeEnum.CREATION_CHANNEL,
      EntityFieldCodeEnum.CURRENT_CHANNEL,
      EntityFieldCodeEnum.PRIORITY,
      EntityFieldCodeEnum.EMERGENCY,
      EntityFieldCodeEnum.CREATION_DATE,
      EntityFieldCodeEnum.WAKE_UP_DATE,
      EntityFieldCodeEnum.DUE_DATE,
      EntityFieldCodeEnum.DUE_DATE_ALERT,
    ],
    RIGHT: [
      EntityFieldCodeEnum.TICKET_STATUS,
      EntityFieldCodeEnum.VALIDATION_STATUS,
      EntityFieldCodeEnum.INITIAL_SKILL,
      EntityFieldCodeEnum.CURRENT_SKILL,
      EntityFieldCodeEnum.TYPOLOGY,
      EntityFieldCodeEnum.CREATION_USER,
      EntityFieldCodeEnum.ASSIGNEE,
      EntityFieldCodeEnum.CREATION_SCREEN,
      EntityFieldCodeEnum.ONCE_AND_DONE,
      EntityFieldCodeEnum.TICKET_MAILBOX,
      EntityFieldCodeEnum.HAS_ATTACHMENTS,
    ],
    GROUP: [GroupedCriteriaParentEnum.TICKET_IMPORT, GroupedCriteriaParentEnum.OTHER_TICKET_ACTION, GroupedCriteriaParentEnum.TICKET_ACTION_REASON],
  },
  CUSTOMER: {
    LEFT: [
      EntityFieldCodeEnum.CUSTOMER_ID,
      EntityFieldCodeEnum.CUSTOMER_EMAIL,
      EntityFieldCodeEnum.CUSTOMER_CREATIONDATE,
      EntityFieldCodeEnum.CUSTOMER_LAST_MODIFIED_DATE,
      EntityFieldCodeEnum.CUSTOMER_ADDRESS,
      EntityFieldCodeEnum.CUSTOMER_COMMENTS,
    ],
    RIGHT: [
      EntityFieldCodeEnum.CUSTOMER_REF,
      EntityFieldCodeEnum.CUSTOMER_PHONE,
      EntityFieldCodeEnum.CUSTOMER_LASTNAME,
      EntityFieldCodeEnum.CUSTOMER_FIRSTNAME,
      EntityFieldCodeEnum.CUSTOMER_COMPANYNAME,
      EntityFieldCodeEnum.CUSTOMER_VIP,
    ],
    GROUP: [GroupedCriteriaParentEnum.CUSTOMER_DELETED],
  },
  INTERACTION: {
    LEFT: [
      EntityFieldCodeEnum.INTERACTION_ID,
      EntityFieldCodeEnum.INTERACTION_TYPE,
      EntityFieldCodeEnum.INTERACTION_DATE,
      EntityFieldCodeEnum.INTERACTION_STATUS,
    ],
    RIGHT: [
      EntityFieldCodeEnum.INTERACTION_CONTACT,
      EntityFieldCodeEnum.INTERACTION_CONTENT,
      EntityFieldCodeEnum.INTERACTION_USER,
      EntityFieldCodeEnum.INTERACTION_SLA,
      EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS,
    ],
    GROUP: [],
  },
}

/**
 * Model of field or criteria used by SearchRequest
 */
export type FieldSearchRequest = {
  code: string
  op: OperationEnum
  values: Array<{}>
}

/**
 * Model to ask server what we want to search
 */
export type SearchRequest = {
  entity: EntityEnum
  max: number
  fields: FieldSearchRequest[]
  criteria: FieldSearchRequest[]
  sort: string[]
}

export const EntityFieldsDefaultOperationMap = new Map<string, OperationEnum>([
  // TICKET
  [EntityFieldCodeEnum.EXTERNAL_REF, OperationEnum.gt],
  [EntityFieldCodeEnum.CREATION_USER, OperationEnum.in],
  [EntityFieldCodeEnum.ASSIGNEE, OperationEnum.in],
  [EntityFieldCodeEnum.CREATION_SUMMARY, OperationEnum.in],
  [EntityFieldCodeEnum.CREATION_SCREEN, OperationEnum.in],
  // custom
  [EntityFieldCodeEnum.HAS_ATTACHMENTS, OperationEnum.custom],
  [EntityFieldCodeEnum.VALIDATION_STATUS, OperationEnum.custom],

  // CUSTOMER
  [EntityFieldCodeEnum.CUSTOMER_REF, OperationEnum.gt],
  [EntityFieldCodeEnum.CUSTOMER_LASTNAME, OperationEnum.gt],
  [EntityFieldCodeEnum.CUSTOMER_FIRSTNAME, OperationEnum.gt],
  [EntityFieldCodeEnum.CUSTOMER_COMPANYNAME, OperationEnum.gt],
  [EntityFieldCodeEnum.CUSTOMER_COMMENTS, OperationEnum.in],
  // custom
  [EntityFieldCodeEnum.CUSTOMER_EMAIL, OperationEnum.custom],
  [EntityFieldCodeEnum.CUSTOMER_PHONE, OperationEnum.custom],
  [EntityFieldCodeEnum.CUSTOMER_ADDRESS, OperationEnum.custom],

  // INTERACTION
  [EntityFieldCodeEnum.INTERACTION_SLA, OperationEnum.custom],
  [EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS, OperationEnum.custom],
  [EntityFieldCodeEnum.INTERACTION_CONTACT, OperationEnum.custom],
  [EntityFieldCodeEnum.INTERACTION_CONTENT, OperationEnum.custom],
])

export const FieldTypesDefaultOperationMap = new Map<FieldTypeEnum, OperationEnum>([
  [FieldTypeEnum.TEXT, OperationEnum.eq],
  [FieldTypeEnum.TEXTAREA, OperationEnum.eq],
  [FieldTypeEnum.EMAIL, OperationEnum.eq],
  [FieldTypeEnum.NUMBER, OperationEnum.eq],
  [FieldTypeEnum.LOCAL_DATE, OperationEnum.in],
  [FieldTypeEnum.TIMESTAMP, OperationEnum.in],
  [FieldTypeEnum.SELECT_LIST_SINGLE, OperationEnum.in],
  [FieldTypeEnum.SELECT_LIST_MULTIPLE, OperationEnum.eq],
  [FieldTypeEnum.CHECKBOX_SINGLE, OperationEnum.eq],
  [FieldTypeEnum.CHECKBOX_MULTIPLE, OperationEnum.eq],
  [FieldTypeEnum.RADIO, OperationEnum.eq],
  [FieldTypeEnum.TREE_SINGLE, OperationEnum.eq],
  [FieldTypeEnum.TREE_MULTIPLE, OperationEnum.eq],
  [FieldTypeEnum.TREE_SINGLE_ASCENDING, OperationEnum.eq],
])

/**
 * Available operations for an input for each type
 */
export const AvailableOperationsMap = new Map<FieldTypeEnum, OperationEnum[]>([
  [FieldTypeEnum.TEXT, [OperationEnum.eq, OperationEnum.in, OperationEnum.gt, OperationEnum.lt]],
  [FieldTypeEnum.TEXTAREA, [OperationEnum.eq, OperationEnum.in, OperationEnum.gt, OperationEnum.lt]],
  [FieldTypeEnum.EMAIL, [OperationEnum.eq, OperationEnum.in, OperationEnum.gt, OperationEnum.lt]],
  [FieldTypeEnum.NUMBER, [OperationEnum.eq, OperationEnum.ge, OperationEnum.le]],
  [FieldTypeEnum.LOCAL_DATE, [OperationEnum.in, OperationEnum.le, OperationEnum.ge]],
  [FieldTypeEnum.TIMESTAMP, [OperationEnum.in, OperationEnum.le, OperationEnum.ge]],
])

export const columnNameChannelIcon = 'channelIcon'
export const columnNameItemActions = 'itemActions'

/**
 * All fields used as specific criteria for request search v2
 */
export const SpecialFieldCriteria: string[] = [
  EntityFieldCodeEnum.CUSTOMER_EMAIL,
  EntityFieldCodeEnum.CUSTOMER_PHONE,
  EntityFieldCodeEnum.CUSTOMER_ADDRESS,
  EntityFieldCodeEnum.HAS_ATTACHMENTS,
  EntityFieldCodeEnum.VALIDATION_STATUS,
  EntityFieldCodeEnum.INTERACTION_SLA,
  EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS,
  EntityFieldCodeEnum.INTERACTION_CONTACT,
  EntityFieldCodeEnum.INTERACTION_CONTENT,
  GroupedCriteriaParentEnum.OTHER_TICKET_ACTION,
  GroupedCriteriaParentEnum.TICKET_ACTION_REASON,
  GroupedCriteriaParentEnum.TICKET_IMPORT,
  GroupedCriteriaParentEnum.CUSTOMER_DELETED,
]

/**
 * Map of fields with their grouped criteria (the order is important)
 */
export const FieldsUsedByGroupedCriteriaMap = new Map<string, GroupedCriteriaParentEnum>([
  [EntityFieldCodeEnum.IMPORT_ID, GroupedCriteriaParentEnum.TICKET_IMPORT],
  [EntityFieldCodeEnum.IMPORT_USER, GroupedCriteriaParentEnum.TICKET_IMPORT],
  [EntityFieldCodeEnum.IMPORT_ACTION_TYPE, GroupedCriteriaParentEnum.TICKET_IMPORT],
  [EntityFieldCodeEnum.IMPORT_DATE, GroupedCriteriaParentEnum.TICKET_IMPORT],
  [EntityFieldCodeEnum.OTHER_ACTION_TYPE, GroupedCriteriaParentEnum.OTHER_TICKET_ACTION],
  [EntityFieldCodeEnum.OTHER_ACTION_DATE, GroupedCriteriaParentEnum.OTHER_TICKET_ACTION],
  [EntityFieldCodeEnum.OTHER_ACTION_USER, GroupedCriteriaParentEnum.OTHER_TICKET_ACTION],
  [EntityFieldCodeEnum.ACTION_REASON, GroupedCriteriaParentEnum.TICKET_ACTION_REASON],
  [EntityFieldCodeEnum.REASON_LAST_ACTION_ONLY, GroupedCriteriaParentEnum.TICKET_ACTION_REASON],
  [EntityFieldCodeEnum.CUSTOMER_INCLUDE_DELETED, GroupedCriteriaParentEnum.CUSTOMER_DELETED],
  [EntityFieldCodeEnum.CUSTOMER_DELETEDATE, GroupedCriteriaParentEnum.CUSTOMER_DELETED],
])

/**
 * Map of FieldData for grouped criteria
 */
export const GroupedCriteriaParentEntityEnumMap = new Map<GroupedCriteriaParentEnum, EntityEnum>([
  [GroupedCriteriaParentEnum.TICKET_IMPORT, EntityEnum.TICKET],
  [GroupedCriteriaParentEnum.OTHER_TICKET_ACTION, EntityEnum.TICKET],
  [GroupedCriteriaParentEnum.TICKET_ACTION_REASON, EntityEnum.TICKET],
  [GroupedCriteriaParentEnum.CUSTOMER_DELETED, EntityEnum.CUSTOMER],
])

/**
 * All import action type options
 */
export enum ImportActionTypeEnum {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  IDENTIFIED = 'IDENTIFIED',
}

/**
 * Search export endpoints enum
 */

export enum ExportEndpointEnum {
  CASES = 'cases',
  CUSTOMERS = 'customers',
  INTERACTIONS = 'interactions',
}

type Unpacked<T> = T extends (infer U)[] ? U : T
export type SearchResultColumn = Unpacked<QTableProps['columns']> & { columnDefinition?: ColumnDefinition }

export type SearchFilter = {
  id: UUID
  name: string
  entity: EntityEnum
  shared: boolean
  creationUser: string
  fields: SearchFilterField[]
}

export type SearchFilterField = {
  code: string
  values: string[]
  op: OperationEnum
}

export type EntitySearchable = Case | Customer | Interaction

/**
 * All user token available for selection
 */
export enum UserSelectTokenEnum {
  EVERYONE = 'EVERYONE',
  ME = 'ME',
  MY_TEAM = 'MY_TEAM',
  MY_OTHER_TEAMS = 'MY_OTHER_TEAMS',
}
