import BaseModel from '@/engage/services/legacy/BaseModel'

export default class I18n extends BaseModel {
  constructor() {
    super()
    this._service = 'rest'
    this._model = 'i18n'
  }

  /**
   * @returns {Promise}
   * @memberof Config
   */
  getTranslate(i18nKeyStartWith = '') {
    return this.get(`${this.resourceUrl}/translation/list`, {
      params: { i18nKeyStartWith },
    })
  }
}
