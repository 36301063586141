import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { getPreBreakTimerSettings, stopPrebreak } from '@/common/services/breakService'
import type { PreBreakTimerSettings } from '@/agent/types/break'
import { useBreaksStore } from '@/agent/stores/breakStore'
import { getLoggedUserDetails } from '@/common/services/SessionService'

const END_TIME_KEY = 'endTime'

export const usePreBreakStore = defineStore('preBreaks', () => {
  const breakStore = useBreaksStore()

  const currentPrePauseId = ref<number | null>(null)
  const displayModal = ref<boolean>(false)
  const interval = ref<number | null>(null)
  const remainingTime = ref<number>(0)
  const timerSettings = ref<PreBreakTimerSettings>()
  const timerEndDate = useLocalStorage<string | null>(END_TIME_KEY, null)

  const isTimerSettingsEnable = computed((): boolean => {
    return timerSettings.value?.active ?? false
  })

  const isPreBreakActive = computed(() => {
    return !!currentPrePauseId.value
  })

  const currentPreBreak = computed(() => {
    return breakStore.getBreakById(currentPrePauseId.value)
  })

  const currentPreBreakName = computed(() => {
    return currentPreBreak.value?.userStateName ?? ''
  })

  const isTimerFinished = computed(() => {
    return remainingTime.value <= 0 && interval.value !== null
  })

  const cancelPreBreak = async () => {
    if (!currentPrePauseId?.value) return

    try {
      await stopPrebreak(currentPrePauseId.value)
      currentPrePauseId.value = null
    } catch (error) {
      console.error(error)
    }
  }

  const load = async () => {
    try {
      timerSettings.value = await getPreBreakTimerSettings()
      const {
        data: { prePause, pauseStateId = null },
      } = await getLoggedUserDetails()
      currentPrePauseId.value = prePause ? pauseStateId : null
    } catch (e) {
      // DO SOMETHING
      console.error(e)
    }
  }

  return {
    interval,
    remainingTime,
    displayModal,
    currentPrePauseId,
    timerEndDate,
    isTimerSettingsEnable,
    timerSettings,
    isPreBreakActive,
    currentPreBreakName,
    currentPreBreak,
    isTimerFinished,
    cancelPreBreak,
    load,
  }
})
