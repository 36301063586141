import { useRootStore } from '@/engage/stores/root'
import middlewares from '@/engage/router/middlewares'
import { isSameRoutePath } from '@/engage/helper/router'

export const beforeEach = async (to, from, next) => {
  const rootStore = useRootStore()
  const isFirstRoute = !from.meta?.engage && to.meta?.engage

  if (isSameRoutePath(to, from)) {
    return next()
  }

  // Reset store data and stop on going action
  middlewares.pollInteractions.clearPolling()
  await rootStore.resetRouteData()

  if (from.meta.worklog) {
    await middlewares.worklog.saveWorkLog()
  }

  // Await until require data has been fetch according to the route
  if (isFirstRoute) {
    await Promise.all([
      middlewares.initializeData.getLoggedUser(),
      middlewares.initializeData.getConfiguration(),
      middlewares.initializeData.getUserAccessRight(),
    ])
  }

  // Check right access
  if (to.meta.supervisorRoute && !middlewares.supervisorRoute.userHasAccess()) {
    return next({ name: '404' })
  } else if (middlewares.pathMatch.isEngageRoute(to) && middlewares.supervisorRoute.userHasAccess()) {
    // Block access to engage for supervisor
    return next({ name: 'command-center-default' })
  }

  // Check route availability and path
  if (isFirstRoute) {
    if (middlewares.pathMatch.isCommandCenterRoute(to)) {
      middlewares.pathMatch.delayRoutePathValidation({
        helpRequest: true,
      })
    } else {
      middlewares.pathMatch.delayRoutePathValidation()
    }
  } else if (middlewares.pathMatch.isTicketPathNotValid(to)) {
    return next({ name: '404' })
  }

  return next()
}

export const afterEach = async (to, from) => {
  if (isSameRoutePath(to, from) || !to.meta.channelRoute) return

  if (to.params.ticketId) {
    middlewares.activities.setCurrentActivity(to.params.ticketId)
  }

  if (to.meta.pollInteractions) {
    middlewares.pollInteractions.initPolling()
  }
}
