import Api from '@/engage/plugins/api'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { CHANNELS, STATUS_TYPE, SORT_TYPE, CHANNEL_TYPE, CHANNEL_GROUP } from '@/engage/constants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import router from '@/router'
import axios from 'axios'
import { useCustomerStore } from '@/engage/stores/customer'
import { useWorklogStore } from '@/engage/stores/worklog'
import { useInteractionsStore } from '@/engage/stores/interactions'
import { useHelpRequestStore } from '@/engage/stores/helpRequests'
import type { PolaritySentiment } from '@/engage/types/polaritySentiment'
import type { SortValues } from '@/engage/types/sort'
import type { StatusValues } from '@/engage/types/status'
import type { ChannelsIds } from '@/agent/types/channels'
import type { LegacyEngageCase, CaseContext, CaseContextList, CaseGroup, CaseFilter } from '@/engage/types/case'

dayjs.extend(utc)

type ActivitiesGroupedList = Record<CaseGroup['channelGroupId'], LegacyEngageCase[]>[]
export const useEngageCasesStore = defineStore('engageCases', () => {
  const activitiesFetched = ref(false)
  const currentActivityId = ref<number | null>(null)
  const activities = ref<LegacyEngageCase[]>([])
  const activitiesContext = ref<CaseContextList>({})
  const groupActivities = ref<CaseGroup[]>([])
  const activitiesMatchSearch = ref<number[]>([])
  const filters = ref<CaseFilter>({
    searchTerms: null,
    channels: {},
    loading: false,
    status: [],
    typology: [],
    skill: [],
  })
  const defaultSort = ref<SortValues | null>(null)
  const sort = ref<SortValues | null>(null)

  // getters
  const emptySearch = computed(() => {
    return !filters.value.loading && !activitiesMatchSearch.value.length && filters.value.searchTerms !== null
  })
  const currentActivityIdFetched = computed(() => {
    return activitiesFetched.value && currentActivityId.value
  })
  const currentActivity = computed(() => {
    return currentActivityId.value ? activities.value.find((o) => o.ticketId === currentActivityId.value) || null : null
  })
  const currentChannel = computed(() => {
    return currentActivity.value ? CHANNELS[currentActivity.value.currentChannelId] : {}
  })
  const isChat = computed(() => {
    return currentActivity.value?.currentChannelId === CHANNEL_TYPE.CHAT.id
  })
  const isPublic = computed(() => {
    if (!currentActivity.value) return undefined

    return CHANNELS[currentActivity.value?.creationChannelId]?.group === CHANNEL_GROUP.PUBLIC_CHANNELS
  })
  const isTwitter = computed(() => {
    return currentActivity.value?.currentChannelId === CHANNEL_TYPE.TWITTER.id
  })
  const displayCustomerHistoryBrowsing = computed(() => {
    return isChat.value && currentActivity.value?.displayCustomerHistoryBrowsing
  })
  const hasActivityChatOpen = computed(() => {
    return activities.value.length
      ? !!activities.value.filter((o) => CHANNELS[o.currentChannelId]?.name === CHANNEL_TYPE.CHAT.name && o.conversationOpen).length
      : false
  })
  const allActivityChatAreClosed = computed(() => {
    return activities.value.filter((o) => CHANNELS[o.currentChannelId]?.name === CHANNEL_TYPE.CHAT.name).length === 0
  })
  const chatContext = computed(() => {
    return activitiesContext.value[CHANNEL_TYPE.CHAT.id] || {}
  })
  /**
   * Return filtered and ordered activities group by channelGroupId
   */
  const activitiesByGroup = computed<ActivitiesGroupedList>(() => {
    const filteredActivities = _getActivitiesFiltered(activities.value)
    const orderedList = _getActivitiesOrdered(filteredActivities)
    // Group activities by group id
    return _groupBy(orderedList, 'channelGroupId') as unknown as ActivitiesGroupedList
  })
  const channelConfig = computed(() => {
    return currentActivity.value ? CHANNELS[currentActivity.value.currentChannelId] : {}
  })
  const chatChannelGroup = computed(() => {
    return groupActivities.value.find((group) => group.channelIds.includes(CHANNEL_TYPE.CHAT.id))
  })
  const activitiesWithHelpRequest = computed(() => {
    const helpRequestsStore = useHelpRequestStore()
    return activities.value.filter((activity) => {
      return helpRequestsStore.helpRequestsAvailabilityByCaseId(activity.ticketId)
    })
  })
  const activitiesWithoutHelpRequest = computed(() => {
    const helpRequestsStore = useHelpRequestStore()
    return activities.value.filter((activity) => {
      return !helpRequestsStore.helpRequestsAvailabilityByCaseId(activity.ticketId)
    })
  })

  // mutations
  const clearCurrentActivityId = () => {
    currentActivityId.value = null
  }
  const updateLastInteractionDateIn = ({ ticketId, timestamp }: { ticketId: number; timestamp: string }) => {
    const index = activities.value.findIndex((ticket) => ticket.ticketId === ticketId)
    if (~index) {
      activities.value[index].lastInteractionDateIn = timestamp
    }
  }
  const setActivitiesFilterStatus = (status: StatusValues[]) => {
    filters.value.status = [...status]
  }
  const clearActivitiesFilterStatus = () => {
    filters.value.status = []
  }
  const setActivitiesFilterTypology = (typology: string[]) => {
    filters.value.typology = [...typology]
  }
  const clearActivitiesFilterTypology = () => {
    filters.value.typology = []
  }
  const setActivitiesFilterSkill = (skill: string[]) => {
    filters.value.skill = [...skill]
  }
  const clearActivitiesFilterSkill = () => {
    filters.value.skill = []
  }
  const setActivitiesSort = (payload: SortValues) => {
    sort.value = payload
  }
  const setChannelsFilters = () => {
    const channelsFilters: CaseFilter['channels'] = {}

    Object.keys(CHANNELS).forEach((key) => (channelsFilters[Number(key) as ChannelsIds] = true))
    filters.value.channels = channelsFilters
  }
  const updateChannelsFilters = ({ key, value }: { key: ChannelsIds; value: boolean }) => {
    filters.value.channels[key] = value
  }
  const toggleChannelsFilters = (payload: boolean) => {
    Object.keys(filters.value.channels).forEach((key) => (filters.value.channels[Number(key) as ChannelsIds] = payload))
  }
  const clearActivitiesMatchSearch = () => {
    filters.value.searchTerms = null
    activitiesMatchSearch.value = []
  }
  const updateActivityPolaritySentiment = (polaritySentiment: PolaritySentiment) => {
    const index = activities.value.findIndex((o) => o.ticketId === currentActivityId.value)
    if (~index) {
      activities.value[index].polaritySentiment = polaritySentiment
    }
  }

  // actions
  const getActivityById = (ticketId: number) => {
    return ticketId ? activities.value.find((o) => o.ticketId === Number(ticketId)) : null
  }
  const fetchCommandCenterActivities = async () => {
    try {
      const { data } = await Api.commandCenter.getListing()

      activities.value = data
    } catch (e) {
      console.error(e)
    } finally {
      activitiesFetched.value = true
    }
  }
  const fetchActivities = async () => {
    try {
      const {
        data: { list = [], context = {} },
      } = await Api.activity.getListing()

      if (list) {
        activities.value = list
      }

      if (context) {
        activitiesContext.value = context
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e)
      }
    } finally {
      activitiesFetched.value = true
    }
  }
  const fetchGroupsActivities = async () => {
    const { data } = await Api.activity.getChannelGroups()
    groupActivities.value = data || []
  }
  const setCurrentActivity = (ticketId: number) => {
    const interactionsStore = useInteractionsStore()
    if (interactionsStore.newInteraction) {
      interactionsStore.preventClose({
        open: true,
        cb: () => setCurrentActivity(ticketId),
      })
    } else {
      interactionsStore.resetSelection()
      currentActivityId.value = Number(ticketId)
    }
  }
  const newActivity = (payload: LegacyEngageCase) => {
    const index = activities.value.findIndex((activity) => activity.ticketId === payload.ticketId)
    if (index === -1) {
      activities.value.push(payload)
    } else {
      updateActivity(payload)
    }
  }
  const deleteActivity = (payload: LegacyEngageCase) => {
    const index = activities.value.findIndex((activity) => activity.ticketId === payload.ticketId)
    if (index === -1) return

    _removeActivityByIndex(index)

    if (currentActivityId.value === payload.ticketId) {
      _clearCurrentActivity()
    }
  }
  const updateActivity = (payload: LegacyEngageCase) => {
    const index = activities.value.findIndex((activity) => activity.ticketId === payload.ticketId)
    if (index === -1) return

    /**
     * Keep old lastInteractionDateIn on chat open ticket only
     * Due to Twilio sync on front side check TwilioTile component
     */
    if (payload.conversationOpen && activities.value[index]) {
      payload.lastInteractionDateIn = activities.value[index].lastInteractionDateIn
    }
    _updateActivityByIndex({
      index,
      payload,
    })
  }
  const closeActivity = async () => {
    const worklogStore = useWorklogStore()
    const interactionsStore = useInteractionsStore()
    if (interactionsStore.newInteraction) {
      interactionsStore.preventClose({
        open: true,
        cb: () => closeActivity(),
      })
    } else if (worklogStore.haveWorkLog) {
      const customerStore = useCustomerStore()
      await worklogStore.saveWorkLog()
      clearCurrentActivityId()

      worklogStore.removeWorkLog()
      customerStore.removeCustomer()
      await router.push({
        name: 'default',
      })
    }
  }
  const searchActivities = async (searchTerms: string) => {
    filters.value.loading = true
    filters.value.searchTerms = searchTerms

    const { data } = await Api.activity.searchActivities(filters.value.searchTerms)
    activitiesMatchSearch.value = data
    filters.value.loading = false
  }
  const getNextActivity = async () => {
    const { data } = await Api.ticket.getNextTicket()

    if (data?.data) {
      _setNextActivity(data.data)
    }
  }
  const assignTickets = () => {
    return Api.ticket.assignTickets()
  }
  const setDefaultSort = (payload: SortValues) => {
    defaultSort.value = payload
    setActivitiesSort(payload)
  }
  const setChatContext = (context: CaseContext) => {
    activitiesContext.value = {
      [CHANNEL_TYPE.CHAT.id]: { ...context },
    }
  }

  // internal
  const _getActivitiesFiltered = (activitiesList: LegacyEngageCase[]) => {
    return activitiesList.filter((activity) => {
      return (
        !!filters.value.channels[activity.currentChannelId] &&
        (filters.value.searchTerms && !filters.value.loading ? activitiesMatchSearch.value.includes(activity.ticketId) : true) &&
        (filters.value.status.includes(STATUS_TYPE.REOPENED)
          ? activity.reopen
          : filters.value.status.length
            ? filters.value.status.includes(activity.ticketStatus)
            : true) &&
        (filters.value.skill.length ? filters.value.skill.includes(String(activity.skillId)) : true) &&
        (filters.value.typology.length ? filters.value.typology.includes(String(activity.typologyId)) : true)
      )
    })
  }
  /**
   * Order List
   * Open Chat should always be displayed at the top of its group
   * conversationOpen always to false except for chat ticket open
   */
  const _getActivitiesOrdered = (activitiesList: LegacyEngageCase[]) => {
    return sort.value === SORT_TYPE.LAST_INTERACTION_DATE_IN
      ? _orderBy(activitiesList, ['conversationOpen', 'newMessage', sort.value], ['desc', 'desc', 'asc'])
      : _orderBy(activitiesList, ['conversationOpen', sort.value], ['desc', 'asc'])
  }
  const _removeActivityByIndex = (index: number) => {
    activities.value.splice(index, 1)
  }
  const _updateActivityByIndex = ({ index, payload }: { index: number; payload: LegacyEngageCase }) => {
    activities.value.splice(index, 1, payload)
  }
  const _clearCurrentActivity = () => {
    const customerStore = useCustomerStore()
    const interactionsStore = useInteractionsStore()
    const currentId = currentActivityId.value
    if (currentId) interactionsStore.removeFirstInteractionVisibility(currentId)
    customerStore.removeCustomer()
    clearCurrentActivityId()
  }
  const _setNextActivity = (ticketId: number) => {
    if (activities.value.length && groupActivities.value.length) {
      const activity = activities.value.find((o) => o.ticketId === ticketId)
      if (activity) {
        currentActivityId.value = Number(ticketId)
      }
    } else {
      setTimeout(() => _setNextActivity(ticketId), 200)
    }
  }

  return {
    activitiesFetched,
    currentActivityId,
    activities,
    activitiesContext,
    groupActivities,
    activitiesMatchSearch,
    filters,
    defaultSort,
    sort,
    emptySearch,
    currentActivity,
    currentChannel,
    isChat,
    isPublic,
    isTwitter,
    displayCustomerHistoryBrowsing,
    getActivityById,
    hasActivityChatOpen,
    allActivityChatAreClosed,
    chatContext,
    activitiesByGroup,
    channelConfig,
    chatChannelGroup,
    activitiesWithHelpRequest,
    activitiesWithoutHelpRequest,
    currentActivityIdFetched,
    clearCurrentActivityId,
    updateLastInteractionDateIn,
    setActivitiesFilterStatus,
    clearActivitiesFilterStatus,
    setActivitiesFilterTypology,
    clearActivitiesFilterTypology,
    setActivitiesFilterSkill,
    clearActivitiesFilterSkill,
    setActivitiesSort,
    setChannelsFilters,
    updateChannelsFilters,
    toggleChannelsFilters,
    clearActivitiesMatchSearch,
    updateActivityPolaritySentiment,
    fetchCommandCenterActivities,
    fetchActivities,
    fetchGroupsActivities,
    setCurrentActivity,
    newActivity,
    deleteActivity,
    updateActivity,
    closeActivity,
    searchActivities,
    getNextActivity,
    assignTickets,
    setDefaultSort,
    setChatContext,
  }
})
