import { RouteRecordRaw } from 'vue-router'

const ModuleRoutedView = () => import('@/common/views/ModuleRoutedView.vue')
const HeaderComponent = () => import('@/agent/components/headers/BaseHeader.vue')

export default [
  {
    path: '/assist/cti/newCall',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
      },
    ],
    meta: {
      legacy: true,
      refresh: true,
      exclude: ['/assist/ticket/save'],
    },
  },
] as Array<RouteRecordRaw>
