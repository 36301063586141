import { FeatureFlagName } from '@/common/types/featureFlag'
import { featureFlags } from '@/common/helpers/configResolver'

export const isFeatureEnabled = function (feature: FeatureFlagName) {
  return featureFlags.includes(feature)
}

// map of featureFlag and its related views (list of view link)
export const featureFlagViews: { [key in FeatureFlagName]?: string[] } = {
  [FeatureFlagName.STONLY_KB]: ['/assist/admin/settings/kb/'],
}

// check if item/view should be disabled if related to a featureFlag
export const isDisabledFeatureView = (viewLink: string | undefined): boolean => {
  for (const [flagName, featureItems] of Object.entries(featureFlagViews)) {
    if (featureItems?.includes(viewLink ?? '') && !isFeatureEnabled(flagName as FeatureFlagName)) {
      return true
    }
  }
  return false
}
