import HttpService from '@/common/services/HttpService'
import { get as getCachedData } from '@/common/services/LocalStorageService'
import { type CaseData, type Case, type Channel, type Emergency, type Priority, TicketActionTypeEnum, CaseIncludeEnum } from '@/common/types/case'
import { NodeTree } from '@/common/helpers/treeHelper'
import { getFieldOptions } from '@/common/services/FieldService'
import { FieldOptionData, EntityFieldCodeEnum } from '@/common/types/fields'
import { addTranslations } from '@/common/services/LocalizationService'
import { useSessionStore } from '@/common/stores/sessionStore'
import { PrefixTranslationsEnum } from '@/common/types/i18n'

export const getCase = async (caseId: number): Promise<CaseData> => {
  return HttpService.getData<CaseData>(`/api/v1/cases/${caseId}`)
}

export const getCaseWorklog = async (caseId: number) => {
  return HttpService.post(`/api/v1/cases/${caseId}/worklogs`)
}

export const getCaseSkillExternalUrl = async (caseId: number): Promise<string> => {
  return HttpService.getData<string>(`/api/v3/cases/${caseId}/skill-external-url`)
}

interface getChannelsListParams {
  onlyActive?: boolean
  onlyWithTicketCreationEnabled?: boolean
}
export const getChannelsList = async (params: getChannelsListParams): Promise<Channel[]> => {
  const cacheKey = 'channelslist-' + params.onlyActive + '-' + params.onlyWithTicketCreationEnabled
  const data: Array<Channel> =
    (await getCachedData<Array<Channel>>(cacheKey, '/rest/channels', {
      params,
    })) || []
  return data
}

export const getEmergenciesList = async (): Promise<Emergency[]> => {
  const [list] = await Promise.all([
    getCachedData<FieldOptionData[]>('emergencieslist', () => getFieldOptions(EntityFieldCodeEnum.EMERGENCY)),
    loadEnumTranslations(PrefixTranslationsEnum.EMERGENCY),
  ])
  return list
}

export const getPrioritiesList = async (): Promise<Priority[]> => {
  const [list] = await Promise.all([
    getCachedData<FieldOptionData[]>('prioritieslist', () => getFieldOptions(EntityFieldCodeEnum.PRIORITY)),
    loadEnumTranslations(PrefixTranslationsEnum.PRIORITY),
  ])
  return list
}

export const loadEnumTranslations = async (prefix: PrefixTranslationsEnum): Promise<void> => {
  const sessionStore = useSessionStore()
  await addTranslations(sessionStore.locale, prefix, prefix.replace('.', '-') + 'label')
}

/** Deprecated : use entity store now **/
interface getSkillsTreeParams {
  restrictToLoggedInUser?: boolean
}
export const getSkillsTree = async (params: getSkillsTreeParams): Promise<NodeTree[]> => {
  const cacheKey = 'skillstree-' + params.restrictToLoggedInUser
  return (
    (await getCachedData<Array<NodeTree>>(cacheKey, '/rest/skills/getSkillsTree', {
      params,
    })) || []
  )
}

export const getMailboxesTree = async (isSkillGroupSelectable: boolean): Promise<NodeTree[]> => {
  const params: any = { isSkillGroupSelectable: isSkillGroupSelectable }
  const cacheKey = 'mailboxestree-' + isSkillGroupSelectable
  return (
    (await getCachedData<Array<NodeTree>>(cacheKey, '/rest/mail/mailboxes/getMailboxesTree', {
      params,
    })) || []
  )
}

export const getCases = async (ids?: number[], include?: CaseIncludeEnum[]): Promise<CaseData[]> => {
  const params: any = {}
  if (ids) {
    params.ids = ids.join()
  }
  if (include && include.length > 0) {
    params.include = include.join()
  }
  return HttpService.getData<CaseData[]>(`/api/v1/cases`, {
    params,
  })
}

interface getCaseActionTypesParams {
  onlySearchable?: boolean
}
export const getCaseActionTypes = async (params: getCaseActionTypesParams): Promise<TicketActionTypeEnum[]> => {
  const cacheKey = 'caseActionTypes-' + params.onlySearchable
  return (
    (await getCachedData<Array<NodeTree>>(cacheKey, '/api/v1/cases/action-types', {
      params,
    })) || []
  )
}

export function isCase(entity: any): entity is Case {
  return (entity as Case).initialSkill !== undefined
}
