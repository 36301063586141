import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { useI18n } from 'vue-i18n'
import i18n from '@/plugins/i18n'
import { VTreeview } from 'vuetify/labs/VTreeview'
import type { App } from 'vue'

const style = getComputedStyle(document.body)
const getCSSVariable = (colorName: string) => style.getPropertyValue(`--fds-global-color-${colorName}`)

export const innso = {
  dark: false,
  'text-fields': {
    outlined: 'rgba(0,0,0, 0.19)',
  },
  colors: {
    // Primary
    primary40: getCSSVariable('primary-40'),
    primary0: getCSSVariable('primary-0'),
    primary5: getCSSVariable('primary-5'),
    primary10: getCSSVariable('primary-10'),
    primary20: getCSSVariable('primary-20'),
    primary30: getCSSVariable('primary-30'),
    primary50: getCSSVariable('primary-50'),
    primary60: getCSSVariable('primary-60'),
    primary70: getCSSVariable('primary-70'),
    primary80: getCSSVariable('primary-80'),
    // Neutral
    neutral0: getCSSVariable('neutral-0'),
    neutral5: getCSSVariable('neutral-5'),
    neutral10: getCSSVariable('neutral-10'),
    neutral20: getCSSVariable('neutral-20'),
    neutral30: getCSSVariable('neutral-30'),
    neutral40: getCSSVariable('neutral-40'),
    neutral50: getCSSVariable('neutral-50'),
    neutral60: getCSSVariable('neutral-60'),
    neutral70: getCSSVariable('neutral-70'),
    neutral80: getCSSVariable('neutral-80'),
    neutral90: getCSSVariable('neutral-90'),
    neutral100: getCSSVariable('neutral-100'),
    neutral110: getCSSVariable('neutral-110'),
    neutral120: getCSSVariable('neutral-120'),
    neutral130: getCSSVariable('neutral-130'),
    neutral140: getCSSVariable('neutral-140'),
    neutral150: getCSSVariable('neutral-150'),
    neutral160: getCSSVariable('neutral-160'),
    neutral170: getCSSVariable('neutral-170'),
    // Success
    success0: getCSSVariable('success-0'),
    success5: getCSSVariable('success-5'),
    success10: getCSSVariable('success-10'),
    success20: getCSSVariable('success-20'),
    success30: getCSSVariable('success-30'),
    success40: getCSSVariable('success-40'),
    success50: getCSSVariable('success-50'),
    success60: getCSSVariable('success-60'),
    success70: getCSSVariable('success-70'),
    success80: getCSSVariable('success-80'),
    // Caution
    caution0: getCSSVariable('caution-0'),
    caution5: getCSSVariable('caution-5'),
    caution10: getCSSVariable('caution-10'),
    caution20: getCSSVariable('caution-20'),
    caution30: getCSSVariable('caution-30'),
    caution40: getCSSVariable('caution-40'),
    caution50: getCSSVariable('caution-50'),
    caution60: getCSSVariable('caution-60'),
    caution70: getCSSVariable('caution-70'),
    caution80: getCSSVariable('caution-80'),
    // Warning
    warning0: getCSSVariable('warning-0'),
    warning5: getCSSVariable('warning-5'),
    warning10: getCSSVariable('warning-10'),
    warning20: getCSSVariable('warning-20'),
    warning30: getCSSVariable('warning-30'),
    warning40: getCSSVariable('warning-40'),
    warning50: getCSSVariable('warning-50'),
    warning60: getCSSVariable('warning-60'),
    warning70: getCSSVariable('warning-70'),
    warning80: getCSSVariable('warning-80'),
    // Error
    error0: getCSSVariable('error-0'),
    error5: getCSSVariable('error-5'),
    error10: getCSSVariable('error-10'),
    error20: getCSSVariable('error-20'),
    error30: getCSSVariable('error-30'),
    error40: getCSSVariable('error-40'),
    error50: getCSSVariable('error-50'),
    error60: getCSSVariable('error-60'),
    error70: getCSSVariable('error-70'),
    error80: getCSSVariable('error-80'),
    // Info
    info0: getCSSVariable('info-0'),
    info5: getCSSVariable('info-5'),
    info10: getCSSVariable('info-10'),
    info20: getCSSVariable('info-20'),
    info30: getCSSVariable('info-30'),
    info40: getCSSVariable('info-40'),
    info50: getCSSVariable('info-50'),
    info60: getCSSVariable('info-60'),
    info70: getCSSVariable('info-70'),
    info80: getCSSVariable('info-80'),
  },
}

export default (app: App) => {
  app.use(
    createVuetify({
      components: {
        VTreeview,
      },
      defaults: {
        VTextField: {
          VIcon: {
            size: 'small',
          },
        },
        VBtn: {
          class: 'innso-vuetify__btn',
          VIcon: {
            class: 'innso-vuetify__btn__icon',
          },
        },
        VExpansionPanelTitle: {
          VIcon: {
            size: 'small',
          },
        },
      },
      locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
      },
      theme: {
        themes: {
          innso,
        },
        defaultTheme: 'innso',
      },
      icons: {
        defaultSet: 'fa',
        aliases: {
          ...aliases,
          assistSortAsc: 'fas fa-arrow-down',
          assistSortDesc: 'fas fa-arrow-up',
        },
        sets: {
          fa,
        },
      },
    }),
  )
}
