export type VonageConfiguration = {
  id?: UUID
  applicationName: string
  applicationId: string
  apiKey: string
  apiUrl: string
  privateKey: string
  signatureSecret: string
  smsNumbers?: VonageSmsNumber[]
  whatsappNumbers?: VonageWhatsAppNumber[]
}

export type VonageSmsNumber = {
  id?: number
  name: string
  number: string
  space?: number
  notification: boolean
  conversational: boolean
  defaultSkill?: number
  requiredSkills: number[]
  vonageConfiguration?: VonageConfiguration
}

export type VonageWhatsAppNumber = {
  id?: number
  active?: boolean
  name: string
  number: string
  wabaId: string
  nameSpace: string
  space: number
  defaultSkill?: number
  vonageConfiguration?: VonageConfiguration
}

export enum VonageProtectedData {
  'wabaId' = 'wabaId',
  'nameSpace' = 'nameSpace',
}

export enum VonageApiUrl {
  DEFAULT = 'https://api-eu.vonage.com',
}
