import HttpService from '@/common/services/HttpService'
import { ScreenBase, ScreenBaseV2, ScreenV2, Screen, ScreenTypeEnum, ScreenField } from '@/common/types/screen'
import { get as getCachedData } from '@/common/services/LocalStorageService'

interface listScreensParams {
  types?: ScreenTypeEnum[]
  creation?: boolean
  userIdToFilterBySkills?: number
}

interface listScreensV2Params {
  includeDeactivated: boolean
}

/**
 * Retrieve all screens of the requested types
 * @param params
 * @returns screens list
 */
export const listScreens = async (params: listScreensParams): Promise<ScreenBase[]> => {
  const paramsToSent: any = { ...params }
  if (params.types) paramsToSent.types = params.types.join()
  const cacheKey = 'screenslist-' + params.types?.join() + '-' + params.creation + '-' + params.userIdToFilterBySkills
  return (
    (await getCachedData<Array<ScreenBase>>(cacheKey, '/api/v1/screens', {
      params: paramsToSent,
    })) || []
  )
}

/**
 * Retrieve all screens
 * @param params
 * @returns screens list
 */

export const listScreensV2 = async (params: listScreensV2Params): Promise<ScreenBaseV2[]> => {
  return HttpService.getData<ScreenBaseV2[]>('/api/v2/screens/', { params })
}

/**
 * Get screen by id
 * @param id screen id
 * @returns Screen
 */
export const getScreenById = async (id: number): Promise<Screen> => {
  return HttpService.getData<Screen>('/api/v1/screens/' + id)
}

/**
 * Handle screen activation
 */
export const handleScreenActivation = (code: UUID, active: boolean) => {
  return HttpService.patch(`/api/v2/screens/${code}`, {
    activeToSet: active,
  })
}

/**
 * Get screen by code
 * @param screenCode screen code
 * @returns Screen
 */
export const getScreenByCode = async (screenCode: UUID): Promise<ScreenV2> => {
  return HttpService.getData<ScreenV2>(`/api/v2/screens/${screenCode}`)
}

/**
 * Create new screen
 * @returns created screen
 */

export const createScreen = async (newScreen: { type: ScreenTypeEnum; active: boolean; fields: ScreenField[] }): Promise<ScreenBaseV2> => {
  return HttpService.postData<ScreenBaseV2>('/api/v2/screens', newScreen)
}

/**
 * Update existing screen
 * @returns updated screen
 */

export const updateScreen = async (screenCode: UUID, updateScreenPayload: { active: boolean; fields: ScreenField[] }): Promise<ScreenBaseV2> => {
  return HttpService.putData<ScreenBaseV2>(`/api/v2/screens/${screenCode}`, updateScreenPayload)
}
