import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import {
  isHelpRequestsAvailable,
  getHelpRequests,
  assignHelpRequest as assignHelpRequestApi,
  abandonHelpRequest as abandonHelpRequestApi,
  cancelHelpRequests,
} from '@/engage/services/HelpRequestsService'
import type { HelpRequest } from '@/engage/types/helpRequest'

export const useHelpRequestStore = defineStore('helpRequests', () => {
  const helpRequests = ref<HelpRequest[]>([])
  const helpRequestsFeature = ref(false)
  const helpRequestFetched = ref(false)

  const helpRequestsAvailabilityByCaseId = computed(() => (caseId: number) => {
    return helpRequests.value.find((help) => help.caseId === caseId)
  })

  const helpRequestIsAssignedToASupervisor = computed(() => (caseId: number) => {
    return !!helpRequests.value.find((help) => help.caseId === caseId && help?.handlerId)
  })

  const addHelpRequest = (request: HelpRequest) => {
    helpRequests.value.push(request)
  }

  const updateHelpRequestFeature = (status: boolean) => {
    helpRequestsFeature.value = status
  }

  const setHelpRequestsFeature = async () => {
    try {
      const {
        data: { available },
      } = await isHelpRequestsAvailable()

      helpRequestsFeature.value = available
    } catch (e) {
      console.error(e)
    }
  }

  const fetchHelpRequests = async ({ forAllUsers }: { forAllUsers: boolean }) => {
    try {
      const { data: list } = await getHelpRequests(forAllUsers)
      helpRequests.value = list
    } catch (e) {
      console.error(e)
    } finally {
      helpRequestFetched.value = true
    }
  }

  const assignHelpRequest = async ({ requestId }: Required<Pick<HelpRequest, 'requestId'>>) => {
    try {
      const { data: helpRequestResponse } = await assignHelpRequestApi(requestId)

      updateHelpRequest(helpRequestResponse)
    } catch (e) {
      console.error(e)
    }
  }

  const abandonHelpRequest = async ({ requestId }: Required<Pick<HelpRequest, 'requestId'>>) => {
    try {
      const { data: helpRequestResponse } = await abandonHelpRequestApi(requestId)

      updateHelpRequest(helpRequestResponse)
    } catch (e) {
      console.error(e)
    }
  }

  const newHelpRequest = (payload: HelpRequest) => {
    const index = helpRequests.value.findIndex((help) => help.caseId === payload.caseId)

    if (index === -1) {
      addHelpRequest(payload)
    } else {
      updateHelpRequest(payload)
    }
  }

  const updateHelpRequest = (payload: HelpRequest) => {
    const index = helpRequests.value.findIndex((help) => help.caseId === payload.caseId)

    if (index === -1) return

    _updateHelpRequest({
      index,
      payload,
    })
  }

  const deleteHelpRequest = ({ caseId }: Required<Pick<HelpRequest, 'caseId'>>) => {
    const index = helpRequests.value.findIndex((help) => help.caseId === caseId)

    if (index === -1) return

    _removeHelpRequest(index)
  }

  const closeHelpRequest = async ({ requestId, caseId }: Required<Pick<HelpRequest, 'caseId' | 'requestId'>>) => {
    try {
      await cancelHelpRequests(requestId)

      deleteHelpRequest({ caseId })
    } catch (e) {
      console.error(e)
    }
  }

  // internal
  const _updateHelpRequest = ({ index, payload }: { index: number; payload: HelpRequest }) => {
    helpRequests.value[index] = payload
  }
  const _removeHelpRequest = (index: number) => {
    helpRequests.value.splice(index, 1)
  }

  return {
    helpRequests,
    helpRequestsFeature,
    helpRequestFetched,
    helpRequestsAvailabilityByCaseId,
    helpRequestIsAssignedToASupervisor,
    addHelpRequest,
    updateHelpRequestFeature,
    setHelpRequestsFeature,
    fetchHelpRequests,
    assignHelpRequest,
    abandonHelpRequest,
    newHelpRequest,
    updateHelpRequest,
    deleteHelpRequest,
    closeHelpRequest,
  }
})
