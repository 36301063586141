import HttpService from '@/common/services/HttpService'
import { type Activity, type ActivityCreateOrUpdate, type ActivityNamed } from '@/common/types/activity'
import { requestTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'

const serviceRoot = '/agent-activity/api/v1/activities'

/**
 * Get activities list
 */
export const getAgentActivities = async (fetchAll?: boolean): Promise<ActivityNamed[]> => {
  const sessionStore = useSessionStore()

  const activitiesPipe = [
    requestTranslations(sessionStore.locale, PrefixTranslationsEnum.ACTIVITY),
    HttpService.getData<Array<Activity>>(`${serviceRoot}${fetchAll ? '?fetchAll=true' : ''}`),
  ] as const

  const [labels, list] = await Promise.all(activitiesPipe)

  return list.map((a: Activity) => ({
    ...a,
    name: labels[PrefixTranslationsEnum.ACTIVITY + `${a.id}`] ?? '',
  }))
}

/**
 * Get a single activity
 */
export const getAgentActivity = (id: UUID) => {
  return HttpService.getData<Activity>(`${serviceRoot}/${id}`)
}

/**
 * Create an activity
 */
export const createAgentActivity = async (activity: ActivityCreateOrUpdate) => {
  return HttpService.post(`${serviceRoot}`, activity)
}

/**
 * Update an activity
 */
export const updateAgentActivity = async (id: UUID, activity: ActivityCreateOrUpdate) => {
  return HttpService.put(`${serviceRoot}/${id}`, activity)
}

/**
 * Handle activity activation
 */
export const handleActivityActivation = (activity: Activity, active: boolean) => {
  return HttpService.put(`${serviceRoot}/${activity.id}`, {
    ...activity,
    active,
  })
}

/**
 * Handle activity push auto blocked
 */
export const handleActivityPushAutoBlocked = (activity: Activity, pushAutoBlocked: boolean) => {
  return HttpService.put(`${serviceRoot}/${activity.id}`, {
    ...activity,
    pushAutoBlocked,
  })
}
