import { Customer } from '@/common/types/customer'
import { FieldOptionData } from '@/common/types/fields'
import { CaseChannelEnum } from '@/common/types/channel'

export type CaseData = {
  id: number
  comments?: string
  createdDate: string
  initialSkill: string
  status: CaseStatusEnum
  ref?: string
  typology?: string
  emergency?: string
  priority: CasePriorityEnum
  dueDate: string
  currentSkill: string
  assigneeUser?: string
  firstClaimDate?: string
  closingDate?: string
  lastEventDate?: string
  wakeupDate?: string
  lastActionReason?: string
  lastActionComment?: string
  skillTargetProcessingTime?: number
  lastAssignee?: string
  minPushDate?: string
  lastInteractionWith?: string
  assigneeUserGroupSiteName?: string
  creationUserGroupSiteName?: string
  creationImportId?: number
  creationImportDate?: string
  numberOfReopenActions?: number
  lastReopenActionDate?: string
  parent?: number
  creationChannel: CaseChannelEnum
  currentChannel: CaseChannelEnum
  lastInteractionDateIn?: string
  userGroup?: string
  creationScreen?: string
  assigneeUserExternalId?: string
  assigneeUserPhone?: string
  initialSkillCode: string
  currentSkillCode: string
  firstAssignee?: string
  product?: string
  lastActionReasonNotEmpty?: string
  lastActionTypeReasonNotEmpty?: string
  lastActionType?: string
  assigneeUserGroupName?: string
  lastValidationActionDate?: string
  lastValidationActionUser?: string
  lastValidationActionType?: string
  lastValidationActionComment?: string
  firstInteractionDirection?: string
  firstInboundDate?: string
  firstOutboundDate?: string
  firstInboundType?: string
  firstOutboundType?: string
  firstInboundStatus?: string
  firstOutboundStatus?: string
  assigneeUserLogin?: string
  dueDateAlert?: string
  customerId?: number
  hasNonAnsweredInteractions?: boolean
  hasAttachments?: boolean
}

export type Case = CaseData & {
  customer?: Customer
}

export enum CaseIncludeEnum {
  HAS_ATTACHMENTS = 'HAS_ATTACHMENTS',
}

export enum CaseStatusEnum {
  TODO = 'TODO',
  RESERVED = 'RESERVED',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
  TOBEVALIDATED = 'TOBEVALIDATED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  START = 'START',
}

export enum CaseStatusTypeEnum {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

export const mapCaseStatusType: Record<CaseStatusTypeEnum, Array<CaseStatusEnum>> = {
  OPENED: [CaseStatusEnum.TODO, CaseStatusEnum.RESERVED, CaseStatusEnum.INPROGRESS, CaseStatusEnum.PENDING, CaseStatusEnum.TOBEVALIDATED],
  CLOSED: [CaseStatusEnum.COMPLETED, CaseStatusEnum.CANCELED],
}

export const enum CasePriorityEnum {
  VERYLOW = 'VERYLOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERYHIGH = 'VERYHIGH',
}

export const enum CaseEmergencyEnum {
  VERYLOW = 'VERYLOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERYHIGH = 'VERYHIGH',
}

export const enum WorklogItemEnum {
  HANDLE = 'HANDLE',
  CONSULT = 'CONSULT',
  CREATE = 'CREATE',
}

export const enum UserStateEnum {
  CONNECTED = 'CONNECTED',
  WORKING = 'WORKING',
  CONSULTING = 'CONSULTING',
  CREATING = 'CREATING',
}

export interface WorklogItem {
  beginDate: string
  duration: number
  id: number
  skill: string
  case: number
  type: WorklogItemEnum
  user: number
}

export interface WorklogTooltipItem {
  skill: string
  duration: number
  userState: string
}

export type Channel = { channelId: number; code: CaseChannelEnum; channelDisplay: string; activate: boolean }

export type Emergency = Pick<FieldOptionData, 'code' | 'disabled'> & {
  code: CaseEmergencyEnum
}

export type Priority = Pick<FieldOptionData, 'code' | 'disabled'> & {
  code: CasePriorityEnum
}

export const enum TicketActionTypeEnum {
  ABANDON_HELP_REQUEST = 'ABANDON_HELP_REQUEST',
  ADD_ATTACHMENT = 'ADD_ATTACHMENT',
  APPROVE = 'APPROVE',
  ASKFORVALIDATION = 'ASKFORVALIDATION',
  ASSIGN = 'ASSIGN',
  ASSIGN_HELP_REQUEST = 'ASSIGN_HELP_REQUEST',
  ATTACHPARENT = 'ATTACHPARENT',
  CANCEL_ESCALATE = 'CANCEL_ESCALATE',
  CANCEL_HELP_REQUEST = 'CANCEL_HELP_REQUEST',
  CHAT_ADD_IN_QUEUING = 'CHAT_ADD_IN_QUEUING',
  CHAT_BOT_ESCALATE = 'CHAT_BOT_ESCALATE',
  CHAT_CLOSE = 'CHAT_CLOSE',
  CHAT_REASSIGN = 'CHAT_REASSIGN',
  CHAT_RELEASE = 'CHAT_RELEASE',
  CHAT_TRANSFER = 'CHAT_TRANSFER',
  CLOSE_HELP_REQUEST = 'CLOSE_HELP_REQUEST',
  COMMENT = 'COMMENT',
  COMPLETE = 'COMPLETE',
  COMPLETEONCREATION = 'COMPLETEONCREATION',
  COMPLETEONVALIDATION = 'COMPLETEONVALIDATION',
  CONVERT_TO_SMS = 'CONVERT_TO_SMS',
  CONVERT_TO_WHATSAPP = 'CONVERT_TO_WHATSAPP',
  CREATE_HELP_REQUEST = 'CREATE_HELP_REQUEST',
  CREATION = 'CREATION',
  DELETE = 'DELETE',
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
  DISAPPROVE = 'DISAPPROVE',
  ESCALATE = 'ESCALATE',
  HANDLE_INTERACTIONS = 'HANDLE_INTERACTIONS',
  IDENTIFIED_BY_IMPORT = 'IDENTIFIED_BY_IMPORT',
  MOVE_TICKET_CUSTOMER = 'MOVE_TICKET_CUSTOMER',
  PUTBACKINPOOL = 'PUTBACKINPOOL',
  REASSIGN = 'REASSIGN',
  REOPEN = 'REOPEN',
  STACK = 'STACK',
  STACKANDPUTBACKINPOOL = 'STACKANDPUTBACKINPOOL',
  STACKANDREASSIGN = 'STACKANDREASSIGN',
  STACKANDTRANSFER = 'STACKANDTRANSFER',
  TAKEON = 'TAKEON',
  TRANSFER = 'TRANSFER',
  UNDOVALIDATION = 'UNDOVALIDATION',
  UPDATEDUEDATE = 'UPDATEDUEDATE',
  UPDATEVALUES = 'UPDATEVALUES',
  WAKEUP = 'WAKEUP',
}

/**
 * Possible bulk actions for cases
 */

export enum CasesBulkActionsEnum {
  TICKETS_BULK_CANCEL = 'TICKETS_BULK_CANCEL',
  TICKETS_BULK_UNASSIGN = 'TICKETS_BULK_UNASSIGN',
  TICKETS_BULK_COMPLETE = 'TICKETS_BULK_COMPLETE',
  TICKETS_BULK_REOPEN = 'TICKETS_BULK_REOPEN',
  TICKETS_BULK_ASSIGN = 'TICKETS_BULK_ASSIGN',
  TICKETS_BULK_REASSIGN = 'TICKETS_BULK_REASSIGN',
  TICKETS_BULK_REASSIGN_ON_VALIDATION = 'TICKETS_BULK_REASSIGN_ON_VALIDATION',
  TICKETS_BULK_TRANSFER = 'TICKETS_BULK_TRANSFER',
  TICKETS_BULK_TRANSFER_ON_VALIDATION = 'TICKETS_BULK_TRANSFER_ON_VALIDATION',
  TICKETS_BULK_HANDLE_INTERACTIONS = 'TICKETS_BULK_HANDLE_INTERACTIONS',
  TICKETS_BULK_ESCALATE = 'TICKETS_BULK_ESCALATE',
  TICKETS_BULK_CATEGORIZE = 'TICKETS_BULK_CATEGORIZE',
}
