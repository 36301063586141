import BaseModel from '@/engage/services/legacy/BaseModel'

export default class CryptoConverter extends BaseModel {
  constructor() {
    super()
    this._service = 'rest'
    this._model = 'crypto'
  }

  /**
   * call the decrypting service to get decrypted data
   * @param {Object} { payload }
   * @returns {Promise}
   * @memberof CryptoConverter
   */
  decrypt(values) {
    const options = {
      headers: {
        'Secret-Code': 'TAG_MANAGER_CRYPTO_KEY',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }
    return this.post(
      `${this.resourceUrl}/decrypt`,
      {
        attributes: values,
      },
      options,
    )
  }
}
