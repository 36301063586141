import { Notify } from 'quasar'

const defaultApiMessageConfig = {
  timeout: 3000,
}
// TODO change color below
Notify.registerType('api-info', {
  color: 'info',
  ...defaultApiMessageConfig,
})

Notify.registerType('api-success', {
  color: 'positive',
  ...defaultApiMessageConfig,
})

Notify.registerType('api-warning', {
  color: 'warning',
  ...defaultApiMessageConfig,
})

Notify.registerType('api-danger', {
  color: 'negative',
  ...defaultApiMessageConfig,
})

Notify.registerType('notification-info', {
  color: 'primary',
  position: 'top-right',
  classes: 'a-notification a-notification--info',
  ...defaultApiMessageConfig,
})

Notify.registerType('notification-error', {
  color: 'negative',
  position: 'top-right',
  ...defaultApiMessageConfig,
})
