import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler } from '@/engage/services/legacy/utils'

const controllerAbortObject = createAbortControllerHandler(['actionGetListingByTicket', 'actionGetListingByInteraction'])

export default class Action extends BaseModel {
  constructor() {
    super()
    this._service = 'rest'
  }

  /**
   * @returns {Promise}
   * @memberof Action
   */
  async getListingByTicket(ticketId) {
    return this.get(`${this._service}/ticketActions/byTicket/${ticketId}`, {
      signal: controllerAbortObject['actionGetListingByTicket'].requestAbortController().signal,
    })
  }

  /**
   * @returns {Promise}
   * @memberof Action
   */
  async getListingByInteraction(interactionId) {
    return this.get(`${this._service}/interactionActions/byInteraction/${interactionId}`, {
      signal: controllerAbortObject['actionGetListingByInteraction'].requestAbortController().signal,
    })
  }
}
