import BaseModel from '@/engage/services/legacy/BaseModel'
import { apiMessageSnackbar } from '@/engage/services/legacy/utils'
import { publish } from '@/common/services/eventBusService'

export default class Pause extends BaseModel {
  constructor() {
    super()
    this._service = 'rest'
    this._model = 'pause'
  }

  /**
   * @param {number} userStateId
   * @returns {Promise}
   * @memberof Pause
   */
  async startPause(userStateId) {
    const response = await this.post(`${this.resourceUrl}/start`, {
      pauseStateId: userStateId,
    })
    publish('innso:break-stop-pre-break', {})
    publish('innso:break-start', {})
    return response
  }

  /**
   * @param {number} userSessionStateId
   * @returns {Promise}
   * @memberof Pause
   */
  async stopPause(userSessionStateId) {
    const response = await this.post(`${this.resourceUrl}/stop`, {
      userSessionStateId,
    })
    publish('innso:break-resume', {})
    return response
  }

  /**
   * @param {number} pauseId
   * @returns {Promise}
   * @memberof Pause
   */
  async startPrePause(pauseId) {
    publish('innso:break-start-pre-break', {
      userStateId: pauseId,
    })
    const response = await this.post(`${this.resourceUrl}/prePause`, {
      prePause: true,
      pauseStateId: pauseId,
    })

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {number} pauseId
   * @returns {Promise}
   * @memberof Pause
   */
  stopPrePause(pauseId) {
    publish('innso:break-stop-pre-break', {})
    return this.post(`${this.resourceUrl}/prePause`, {
      prePause: false,
      pauseStateId: pauseId,
    })
  }
}
