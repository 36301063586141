import { ref, Ref, computed, ComputedRef, nextTick } from 'vue'
import { defineStore } from 'pinia'
import { EntityEnum } from '@/common/types/general'
import {
  createCriteria,
  getMaxResultParameter,
  getResultViewConfigToDisplay,
  fetchSearchResultIds,
  fetchResultDetails,
  getEntitiesOptions,
  getSavedFilters,
  paginate,
} from '@/search/services/SearchService'
import { EntityFieldCodeEnum } from '@/common/types/fields'
import type { SearchFieldWrapper, Criteria, GroupedCriteria, SearchFilter, EntitySearchable } from '@/search/types/search'
import { PanelEnum, columnNameChannelIcon, GroupedCriteriaParentEnum, FieldsUsedByGroupedCriteriaMap } from '@/search/types/search'
import { ResultViewConfig } from '@/search/types/resultViewConfig'

export const useSearchStore = defineStore('search', () => {
  const displaySearchDialog: Ref<boolean> = ref(false)
  const ready: Ref<boolean> = ref(false)
  const loading: Ref<boolean> = ref(false)
  const currentPanel: Ref<PanelEnum> = ref(PanelEnum.SearchForm)
  const entitiesOptions: Ref<Array<{ label: string; value: EntityEnum }>> = ref([])
  const entitySelected: Ref<EntityEnum | null> = ref(null)
  const criteria: Ref<Criteria> = ref({})
  const groupedCriteria: Ref<GroupedCriteria> = ref({})
  const resetInProgress: Ref<boolean> = ref(false)
  const maxResult: Ref<number> = ref(1000)
  const resultViewConfig: Ref<{
    [entity: string]: ResultViewConfig
  }> = ref({})
  const resultIds: Ref<number[]> = ref([])
  const resultMapEntities: Ref<Map<number, EntitySearchable>> = ref(new Map())
  const selectedResults: Ref<Array<EntitySearchable>> = ref([])
  const relaunchSearch: Ref<boolean> = ref(false)
  const savedFilters: Ref<SearchFilter[]> = ref([])
  const selectedFilter: Ref<SearchFilter | null> = ref(null)

  async function loadCriteria() {
    try {
      ;[criteria.value, groupedCriteria.value] = await createCriteria()
    } catch (error) {
      console.log(error)
    }
  }

  async function loadMaxResultParameter() {
    try {
      const parameter = await getMaxResultParameter()
      const nb: number = parseInt(parameter.value)
      if (Number.isInteger(nb) && nb !== 0) {
        maxResult.value = nb
      } else {
        maxResult.value = 1000
      }
    } catch (error) {
      console.log(error)
    }
  }

  function initEntitiesOptions() {
    entitiesOptions.value = getEntitiesOptions()
    entitySelected.value = null
  }

  async function launchSearch() {
    if (isSearchAvailable.value && entitySelected.value) {
      loading.value = true
      resultViewConfig.value[entitySelected.value] =
        resultViewConfig.value[entitySelected.value] ?? (await getResultViewConfigToDisplay(entitySelected.value))
      currentPanel.value = PanelEnum.SearchResult
      loading.value = false
    }
  }

  async function fetchResultIds(sortBy: string | null, descending: boolean) {
    if (!entitySelected.value) return

    resultMapEntities.value.clear()
    let sort: string[] = []
    const sortSens = descending ? 'desc' : 'asc'
    resultViewConfig.value[entitySelected.value].sortBy = sortBy
    resultViewConfig.value[entitySelected.value].sortSens = sortSens
    if (sortBy !== null) {
      const sortByForRequest = sortBy === columnNameChannelIcon ? EntityFieldCodeEnum.CURRENT_CHANNEL : sortBy
      sort = [sortByForRequest + ':' + sortSens]
    }
    resultIds.value = await fetchSearchResultIds(entitySelected.value, getFieldWrapperList.value, sort, maxResult.value)
  }

  async function fetchEntitiesByPage(page: number, rowsPerPage: number): Promise<Array<EntitySearchable>> {
    if (!entitySelected.value) return []

    const currentIds: number[] = paginate(resultIds.value, page, rowsPerPage === 0 ? resultIds.value.length : rowsPerPage)

    const resultDetails: Array<EntitySearchable> = await fetchResultDetails(
      entitySelected.value,
      currentIds.filter((id) => !resultMapEntities.value.has(id)),
      resultViewConfig.value[entitySelected.value],
    )
    resultDetails.forEach((obj) => {
      resultMapEntities.value.set(obj.id, obj)
    })

    return currentIds.reduce((acc, id) => {
      const entity = resultMapEntities.value.get(id)
      return entity ? [...acc, entity] : acc
    }, [] as Array<EntitySearchable>)
  }

  async function fetchSavedFilters() {
    savedFilters.value = await getSavedFilters()
  }

  function removeOneSavedFilter(idToRemove: UUID) {
    savedFilters.value = savedFilters.value.filter((s) => s.id !== idToRemove)
  }

  function resetCriteria() {
    resetInProgress.value = true
    getFieldWrapperList.value.forEach((fieldWrapper) => {
      fieldWrapper.operation = fieldWrapper.defaultOperation
      fieldWrapper.currentValue = fieldWrapper.defaultValue.slice()
    })
    getFieldWrapperListInGroupedCriteria.value.forEach((fieldWrapper) => {
      fieldWrapper.operation = fieldWrapper.defaultOperation
      fieldWrapper.currentValue = fieldWrapper.defaultValue.slice()
    })
    nextTick(() => {
      resetInProgress.value = false
    })
  }

  function resetSearch() {
    resetCriteria()
    selectedFilter.value = null
    currentPanel.value = PanelEnum.SearchForm
  }

  const getFieldWrapperList: ComputedRef<SearchFieldWrapper[]> = computed(() => {
    return Object.values(criteria.value)
  })

  const getFieldWrapperByCode = computed(() => {
    return (code: string): SearchFieldWrapper => {
      return criteria.value[code]
    }
  })

  const getFieldWrapperListInGroupedCriteria: ComputedRef<SearchFieldWrapper[]> = computed(() => {
    return Object.values(groupedCriteria.value)
  })

  const getFieldWrapperInGroupedCriteriaByCode = computed(() => {
    return (code: string): SearchFieldWrapper => {
      return groupedCriteria.value[code]
    }
  })

  const getFieldWrappersForOneGroupedCriteriaParent = computed(() => {
    return (code: GroupedCriteriaParentEnum): SearchFieldWrapper[] => {
      const fieldWrappers: SearchFieldWrapper[] = []
      FieldsUsedByGroupedCriteriaMap.forEach((value: GroupedCriteriaParentEnum, key: string) => {
        if (code === value && groupedCriteria.value[key]) {
          fieldWrappers.push(groupedCriteria.value[key])
        }
      })
      return fieldWrappers
    }
  })

  const isSearchAvailable: ComputedRef<boolean> = computed(() => {
    return getFieldWrapperList.value.filter((fieldWrapper) => fieldWrapper.currentValue.length > 0).length > 0 && !!entitySelected.value
  })

  const getCounterFieldsFilled = computed(() => {
    return (entity: EntityEnum): number => {
      return Object.values(criteria.value).filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.currentValue.length > 0)
        .length
    }
  })

  const getFieldsFilled = computed(() => {
    return (entity: EntityEnum): SearchFieldWrapper[] => {
      return Object.values(criteria.value).filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.currentValue.length > 0)
    }
  })

  return {
    displaySearchDialog,
    ready,
    loading,
    currentPanel,
    entitiesOptions,
    entitySelected,
    criteria,
    groupedCriteria,
    resetInProgress,
    maxResult,
    resultViewConfig,
    resultIds,
    resultMapEntities,
    selectedResults,
    savedFilters,
    selectedFilter,
    relaunchSearch,
    loadCriteria,
    loadMaxResultParameter,
    initEntitiesOptions,
    launchSearch,
    fetchResultIds,
    fetchEntitiesByPage,
    fetchSavedFilters,
    removeOneSavedFilter,
    resetCriteria,
    resetSearch,
    getFieldWrapperList,
    getFieldWrapperByCode,
    getFieldWrapperListInGroupedCriteria,
    getFieldWrapperInGroupedCriteriaByCode,
    getFieldWrappersForOneGroupedCriteriaParent,
    isSearchAvailable,
    getCounterFieldsFilled,
    getFieldsFilled,
  }
})
