import { type RouteRecordRaw } from 'vue-router'

// Use dynamic import method to load components (it reduces the bundle during build time)
// To use dynamic import each component must be called by () => import('path_of_the_component.vue')
const ModuleRoutedView = () => import('@/common/views/ModuleRoutedView.vue')
const HeaderComponent = () => import('@/agent/components/headers/BaseHeader.vue')
const DefaultView = () => import('@/common/views/DefaultView.vue')

const LandingPage = () => import('@/common/views/LandingPage.vue')
const ParkPage = () => import('@/common/views/park/ParkPage.vue')
const LoginToParkPage = () => import('@/common/views/park/LoginToParkPage.vue')
import useNavigation from '@/common/composables/useNavigation'
const LogoutPage = () => import('@/common/views/LogoutPage.vue')
const ErrorPage = () => import('@/common/views/ErrorPage.vue')

import adminRoutes from '@/admin/routes'
import agentRoutes from '@/agent/routes'
import engageRoutes from '@/engage/routes'
import ctiRoutes from '@/cti/routes'

export const ERROR_ROUTE_NAME = 'ERROR_ROUTE_NAME'
export const OIDC_LOGOUT_ROUTE_NAME = 'OIDC_LOGOUT_ROUTE_NAME'
export const OIDC_LANDING_ROUTE_NAME = 'OIDC_LANDING_ROUTE_NAME'
export const NOT_FOUND_PATH = '404'
export const ADMIN_AUTH_SETTINGS_ROUTE = '/assist/admin/v2/authenticationSettings'
export const CHANGE_PASSWORD_ROUTE = '/assist/changePassword'

const navigation = useNavigation()

export const routes: Array<RouteRecordRaw> = [
  ...adminRoutes,
  ...agentRoutes,
  ...engageRoutes,
  ...ctiRoutes,
  {
    path: '/assist/login',
    component: {},
    beforeEnter: (to) => {
      navigation.redirectToLegacy(to.fullPath.replace('/assist/', ''))
    },
  },
  {
    path: '/assist/logout',
    component: {},
    beforeEnter: (to) => {
      navigation.redirectToLegacy(to.fullPath.replace('/assist/', ''))
    },
  },
  {
    path: CHANGE_PASSWORD_ROUTE,
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
        props: {
          menu: false,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/auth-guard',
    name: OIDC_LANDING_ROUTE_NAME,
    component: LandingPage,
  },
  {
    path: '/assist/park-page',
    name: 'park-page',
    component: ParkPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/assist/login-to-park-page',
    name: 'login-to-park-page',
    component: LoginToParkPage,
    meta: {
      requiresSession: false,
    },
  },
  {
    path: '/assist/error',
    name: ERROR_ROUTE_NAME,
    component: ErrorPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/assist/oidc-logout',
    name: OIDC_LOGOUT_ROUTE_NAME,
    component: LogoutPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/assist/:url+',
    component: DefaultView,
    props: {
      menu: true,
      backButton: true,
    },
    meta: {
      legacy: true,
    },
  },
]
