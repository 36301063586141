import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import type { App } from 'vue'

interface PluginOptions {
  router: Router
}

export default (app: App, options: PluginOptions) => {
  if (import.meta.env.PROD) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      logErrors: true,
      integrations: [Sentry.browserTracingIntegration({ router: options.router })],
      tracePropagationTargets: [/^\//],
      sampleRate: 0.25,
      tracesSampler({ attributes }) {
        const importantTransactions: string[] = ['auto.pageload.browser']
        const transactionOrigin = attributes?.['sentry.origin'] ?? ''

        if (importantTransactions.includes(transactionOrigin)) {
          return 0.05
        }
        return 0.01
      },
      environment: import.meta.env.MODE,
      release: __SENTRY_RELEASE__,
      beforeSend(event) {
        return {
          ...event,
          breadcrumbs: event.breadcrumbs?.filter((breadcrumb) => !breadcrumb.message?.includes('-sensitive-information-')),
        }
      },
    })
  }
}
