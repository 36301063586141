import BaseModel from '@/engage/services/legacy/BaseModel'
import { FILE_TYPE } from '@/engage/constants'
import { createAbortControllerHandler, apiMessageSnackbar } from '@/engage/services/legacy/utils'
import { useEngageCasesStore } from '@/engage/stores/engageCases'

const controllerAbortObject = createAbortControllerHandler(['ticketGetSurveyListCreated'])

export default class Ticket extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'ticket'
  }

  get currentActivityId() {
    const casesStore = useEngageCasesStore()
    return casesStore.currentActivityId
  }

  /**
   * @param {String} message
   * @returns {Promise}
   * @memberof Ticket
   */
  async addTicket(message) {
    const response = await this.post(`${this.resourceUrl}/answerOnTicket/${this.currentActivityId}`, {
      message,
    })

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {File} files
   * @returns {Promise}
   * @memberof Ticket
   */
  async addMediaTicket(files) {
    const formData = this.formDataFile(files)
    formData.append('request', this.multiPartObject({ message: '' }))
    const response = await this.$http.post(`${this.resourceUrl}/answerWithMultiPartOnTicket/${this.currentActivityId}`, formData, {
      headers: {
        'Content-Type': 'multipart/mixed',
      },
    })

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {Number} interactionId
   * @param {String} message
   * @returns {Promise}
   * @memberof Interaction
   */
  async addTicketInteraction(interactionId, message) {
    const response = await this.post(`${this.resourceUrl}/answerOnInteraction/${interactionId}`, {
      message,
    })

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {Number} interactionId
   * @param {File} files
   * @returns {Promise}
   * @memberof Interaction
   */
  async addMediaTicketInteraction(interactionId, files) {
    const formData = this.formDataFile(files)
    formData.append('request', this.multiPartObject({ message: '' }))
    const response = await this.$http.post(`${this.resourceUrl}/answerWithMultiPartOnInteraction/${interactionId}`, formData, {
      headers: {
        'Content-Type': 'multipart/mixed',
      },
    })

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {Number} interactionId
   * @param {Object} item - giphy
   * @returns {Promise}
   * @memberof Interaction
   */
  async addUrlMediaTicketInteraction(interactionId, item) {
    const response = await this.post(`${this.resourceUrl}/answerWithUrlMediaOnInteraction/${interactionId}`, {
      url: item.images.original.url,
      mediaType: 'image/gif',
    })

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {Object} item - giphy
   * @returns {Promise}
   * @memberof Ticket
   */
  async addUrlMediaTicket(item) {
    const response = await this.post(`${this.resourceUrl}/answerWithUrlMediaOnTicket/${this.currentActivityId}`, {
      url: item.images.original.url,
      mediaType: 'image/gif',
    })

    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param activityId
   * @param conversationOpen
   * @returns {Promise}
   */
  newTicketWorklog(activityId) {
    return this.post(`/rest/tickets/newTicketWorklog/${activityId}`)
  }

  /**
   * @param {Number} worklogId
   * @returns {Promise}
   * @memberof Ticket
   */
  saveWorkLog(worklogId) {
    return this.post('/rest/tickets/saveTicketWorklog', { worklogId })
  }

  /**
   * @param {String} comment
   * @returns {Promise}
   * @memberof Ticket
   */
  saveReleaseTicket(comment) {
    return this.post(`/social/putbackinpool/${this.currentActivityId}`, {
      comment,
    })
  }

  /**
   * @param {Number, String} { userId, comment }
   * @returns {Promise}
   * @memberof Ticket
   */
  saveReassignTicket({ userId, comment }) {
    return this.post(`/social/reassign/${this.currentActivityId}`, {
      userId,
      comment,
    })
  }

  /**
   * @param {Number, String} { reasonCode, comment }
   * @returns {Promise}
   * @memberof Ticket
   */
  saveCancelTicket({ reasonCode, comment }) {
    return this.post(`/social/cancel/${this.currentActivityId}`, {
      reasonCode,
      comment,
    })
  }

  /**
   * @param data Object
   * @param id Number
   * @returns {Promise}
   * @memberof Ticket
   */
  async sendAnswerForTicketOrInteraction(data, id = null) {
    const output = []

    if (data?.answerContent?.length) {
      if (id) {
        output.push(await this.addTicketInteraction(id, data.answerContent))
      } else {
        output.push(await this.addTicket(data.answerContent))
      }
    }

    if (data?.files?.length) {
      for (const item of data.files) {
        if (item.mediaType === FILE_TYPE.GIPHY) {
          if (id) {
            output.push(await this.addUrlMediaTicketInteraction(id, item))
          } else {
            output.push(await this.addUrlMediaTicket(item))
          }
        } else {
          if (id) {
            output.push(await this.addMediaTicketInteraction(id, item))
          } else {
            output.push(await this.addMediaTicket(item))
          }
        }
      }
    }

    return output
  }

  /**
   * @returns{Promise}
   * @memberof Ticket
   */
  getNextTicket() {
    return this.get(`/social/tickets/next`)
  }

  /**
   * @returns{Promise}
   * @memberof Ticket
   */
  assignTickets() {
    return this.post(`/social/ticket/assign`)
  }

  /**
   * @param {Number} ticketId
   * @returns{Promise}
   * @memberof Ticket
   */
  getRetweet(ticketId) {
    return this.get(`/social/ticket/retweet/${ticketId}`)
  }

  /**
   * @param {Number} surveyRecordId
   * @returns {Promise}
   */
  getSurvey(surveyRecordId) {
    return this.get(`satisfaction/surveyRecord/${surveyRecordId}`)
  }

  /**
   * @returns {Promise}
   */
  getSurveyListAvailable() {
    return this.get(`social/ticket/survey/${this.currentActivityId}`)
  }

  /**
   * @returns {Promise}
   */
  getSurveyListCreated({ ticketId }) {
    return this.get(`social/ticket/surveyRecord/${ticketId}`, {
      signal: controllerAbortObject['ticketGetSurveyListCreated'].requestAbortController().signal,
    })
  }

  /**
   * @param {Number} ticketId
   * @param {Number} surveyId
   * @returns {Promise}
   */
  async sendAnswerWithSurvey(ticketId, surveyId) {
    const response = await this.post(`${this.service}/ticket/answerWithSurveyOnTicket/${ticketId}/${surveyId}`)
    apiMessageSnackbar(response)
    return response
  }

  /**
   * @param {Number} ticketId
   * @param {Number} replyTemplateId
   * @returns {*} - OK/KO
   */
  sendAnswerWithTemplate({ ticketId }, replyTemplateId) {
    return this.post(`${this.service}/ticket/answerWithTemplateOnTicket/${ticketId}/${replyTemplateId}`)
  }
}
