import type { LoggedUserDetailsAPIResponse } from '@/agent/types/users'
import HttpService from '@/common/services/HttpService'

export const LOGGED_USER_DETAILS_URL = '/rest/users/getLoggedInUserDetails'
export const KEEP_ALIVE_URL = '/rest/users/keepAlive'

export const getLoggedUserDetails = () => {
  return HttpService.getData<LoggedUserDetailsAPIResponse>(LOGGED_USER_DETAILS_URL)
}

export const GetKeepAliveSession = () => {
  return HttpService.get(KEEP_ALIVE_URL)
}
