import { ref, Ref, computed, ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import type { Menu, Category, Group, Entry, StateNames } from '@/admin/types/menu'
import menu from '@/admin/components/menu/menuItems'
import { useSessionStore } from '@/common/stores/sessionStore'
import { menuTraverser, getMenuByLink } from '@/admin/components/menu/menuTraverser'
import { useRoute } from 'vue-router'

export const useMenuStore = defineStore('adminMenu', () => {
  const menuItems: Ref<Menu> = ref(menu)
  const entryStates: Ref<Map<StateNames, string>> = ref(new Map())
  const route = useRoute()

  const activeMenu: ComputedRef<{ category: Category | null; group: Group | null; entry: Entry | null }> = computed(() => {
    const menu = getMenuByLink(route.fullPath)
    if (!menu) return { category: null, group: null, entry: null }
    return { category: menu.category, group: menu.group, entry: menu.entry }
  })

  const activeCategory: ComputedRef<Category | null> = computed(() => {
    return activeMenu.value.category
  })

  const activeGroup: ComputedRef<Group | null> = computed(() => {
    return activeMenu.value.group
  })

  const activeEntry: ComputedRef<Group | null> = computed(() => {
    return activeMenu.value.entry
  })

  const displayedMenus: ComputedRef<Menu> = computed(() => {
    const sessionStore = useSessionStore()
    return menuItems.value.map(menuTraverser(sessionStore.authorities)).filter((category) => category.children.length)
  })

  const firstDisplayedMenuLink: ComputedRef<string> = computed(() => {
    if (!displayedMenus.value[0]) return ''
    const firstGroup = displayedMenus.value[0].children[0]
    if (firstGroup.link) return firstGroup.link
    if (firstGroup.children && firstGroup.children[0] && firstGroup.children[0].link) return firstGroup.children[0].link
    return ''
  })

  return {
    menuItems,
    entryStates,
    activeMenu,
    activeCategory,
    activeGroup,
    activeEntry,
    displayedMenus,
    firstDisplayedMenuLink,
  }
})
