import { INTERACTION_TYPE_STATUS } from '@/engage/constants'
import _groupBy from 'lodash/groupBy'
import dayjs from 'dayjs'

const filterNoDraft = (interactions) => {
  return interactions.filter((interaction) => INTERACTION_TYPE_STATUS.DRAFT !== interaction.type.status)
}

const filterByType = (interactions, typesAllowed = []) => {
  return interactions.filter((interaction) => !typesAllowed.length || typesAllowed.includes(interaction?.type?.code))
}

/**
 * Group interactions by day
 * key creationDate : when interaction is from backend
 * key dateCreated : when interaction is from twilio (open chat)
 */
const groupByDay = (interactions) => {
  return _groupBy(interactions, (interaction) => {
    const interactionDate = interaction.dateCreated || interaction.creationDate
    return dayjs(interactionDate).startOf('day').format()
  })
}

export { filterByType, groupByDay, filterNoDraft }
