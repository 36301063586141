import { markRaw } from 'vue'
import { createPinia } from 'pinia'
import { resetStore } from '@/plugins/pinia/resetStore'
import { allStore } from '@/plugins/pinia/allStore'
import type { Router } from 'vue-router'
import type { App } from 'vue'

interface PluginOptions {
  router: Router
}

export default (app: App, options: PluginOptions) => {
  const pinia = createPinia()

  pinia.use(({ store }) => {
    store.router = markRaw(options.router)
  })
  pinia.use(resetStore)
  pinia.use(allStore)

  app.use(pinia)
}
