import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import { Typology } from '@/common/types/typology'
import { fetchTypologies } from '@/common/services/TypologyService'

export const useTypologyStore = defineStore('typology', () => {
  const typologies: Ref<Typology[]> = ref([])

  async function getTypologies() {
    try {
      ;[typologies.value] = await fetchTypologies()
    } catch (error) {
      console.log(error)
    }
  }

  return {
    typologies,
    getTypologies,
  }
})
