import BaseModel from '@/engage/services/legacy/BaseModel'

export default class PolaritySentiment extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'polaritySentiment'
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  async getFindActiveForTicket() {
    return this.get(`${this.resourceUrl}/findActiveForTicket`)
  }
  /**
   * @returns {Promise}
   * @memberof Screen
   */
  async getFindActiveForInteraction() {
    return this.get(`${this.resourceUrl}/findActiveForInteraction`)
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  async postPolarizeByInteraction(interactionId, polaritySentimentId) {
    return this.post(`${this.service}/interaction/polarize/${interactionId}?polaritySentimentId=${polaritySentimentId}`)
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  async postPolarizeByMultiInteractions(interactionIds, polaritySentimentId) {
    return this.post(`${this.service}/interaction/polarize?interactionIds=${interactionIds}&polaritySentimentId=${polaritySentimentId}`)
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  async postPolarizeByTicket(ticketId, polaritySentimentId) {
    return this.post(`${this.service}/ticket/polarize/${ticketId}?polaritySentimentId=${polaritySentimentId}`)
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  async postPolarizeByMultiTickets(ticketIds, polaritySentimentId) {
    return this.post(`${this.service}/ticket/polarize?interactionIds=${ticketIds}&polaritySentimentId=${polaritySentimentId}`)
  }
}
