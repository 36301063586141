import { App } from 'vue'
import { capitalize, replace, truncate } from '@/common/helpers/filters/stringFilters'

export default (app: App) => {
  app.config.globalProperties.$filters = {
    capitalize,
    replace,
    truncate,
  }
}
