import { type RouteRecordRaw } from 'vue-router'
import merge from 'lodash/merge'
const VoicebotConfiguration = () => import('@/admin/components/voice/voicebot/VoicebotConfiguration.vue')
const VoicebotInboundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/voicebot/inbound/VoicebotInboundCallConfigurationEditionPage.vue')
const VoicebotInboundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/voicebot/inbound/VoicebotInboundCallConfigurationCreationPage.vue')

const RedirectBackHandlingPage = () => import('@/admin/views/RedirectBackHandlingPage.vue')

const AdminView = () => import('@/admin/views/AdminView.vue')
const LegacyRouteWrapper = () => import('@/common/components/legacy/LegacyRouteWrapper.vue')

const ReasonsLandingPage = () => import('@/admin/components/reasons/ReasonsLandingPage.vue')
const ReasonsCreationPage = () => import('@/admin/components/reasons/ReasonsCreationPage.vue')
const ReasonsEditionPage = () => import('@/admin/components/reasons/ReasonsEditionPage.vue')
const AxialysConfiguration = () => import('@/admin/components/voice/axialys/AxialysConfiguration.vue')
const AxialysOutBoundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/axialys/outbound/AxialysOutboundCallConfigurationCreationPage.vue')
const AxialysOutBoundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/axialys/outbound/AxialysOutboundCallConfigurationEditionPage.vue')
const AxialysInboundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/axialys/inbound/AxialysInboundCallConfigurationCreationPage.vue')
const AxialysInboundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/axialys/inbound/AxialysInboundCallConfigurationEditionPage.vue')
const OdigoConfiguration = () => import('@/admin/components/voice/odigo/OdigoConfiguration.vue')
const OdigoOutBoundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/odigo/outbound/OdigoOutboundCallConfigurationCreationPage.vue')
const OdigoOutBoundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/odigo/outbound/OdigoOutboundCallConfigurationEditionPage.vue')
const OdigoInboundCallConfigurationCreationPage = () => import('@/admin/components/voice/odigo/inbound/OdigoInboundCallConfigurationCreationPage.vue')
const OdigoInboundCallConfigurationEditionPage = () => import('@/admin/components/voice/odigo/inbound/OdigoInboundCallConfigurationEditionPage.vue')
const GenesysConfiguration = () => import('@/admin/components/voice/genesys/GenesysConfiguration.vue')
const SitelCtiConfiguration = () => import('@/admin/components/voice/SitelCtiConfiguration.vue')
const BotEditionDyduPage = () => import('@/admin/components/bots/BotEditionDyduPage.vue')
const BotCreationPage = () => import('@/admin/components/bots/BotCreationPage.vue')
const BotListPage = () => import('@/admin/components/bots/BotListPage.vue')
const ChatSdkWebListPage = () => import('@/admin/components/chat/chatSdk/ChatSdkWebListPage.vue')
const ChatSdkWebCreationPage = () => import('@/admin/components/chat/chatSdk/ChatSdkWebCreationPage.vue')
const ChatSdkWebEditionPage = () => import('@/admin/components/chat/chatSdk/ChatSdkWebEditionPage.vue')
const VersionPage = () => import('@/admin/components/settings/VersionPage.vue')
const SearchConfigLandingPage = () => import('@/admin/components/search/SearchConfigLandingPage.vue')
const SearchConfigCreationPage = () => import('@/admin/components/search/SearchConfigCreationPage.vue')
const SearchConfigEditionPage = () => import('@/admin/components/search/SearchConfigEditionPage.vue')
const ActivitiesListPage = () => import('@/admin/components/activities/ActivitiesListPage.vue')
const ActivitiesEditionPage = () => import('@/admin/components/activities/ActivitiesEditionPage.vue')
const ActivitiesCreationPage = () => import('@/admin/components/activities/ActivitiesCreationPage.vue')
const FoundeverGPTConfigurationPage = () => import('@/admin/components/settings/FoundeverGPTConfigurationPage.vue')
const CustomerSettingsPage = () => import('@/admin/components/settings/CustomerSettingsPage.vue')
const EditCustomerExportPage = () => import('@/admin/components/export/customer/EditCustomerExportPage.vue')
const InteractionExportEditPage = () => import('@/admin/components/export/interaction/InteractionExportEditPage.vue')
const VonageConfigurationLandingPage = () => import('@/admin/components/sms/vonage/configurations/VonageConfigurationLandingPage.vue')
const VonageConfigurationCreationPage = () => import('@/admin/components/sms/vonage/configurations/VonageConfigurationCreationPage.vue')
const VonageConfigurationEditPage = () => import('@/admin/components/sms/vonage/configurations/VonageConfigurationEditionPage.vue')
const VonageSmsSenderEditPage = () => import('@/admin/components/sms/vonage/smsNumbers/VonageSmsNumberEditionPage.vue')
const VonageSmsSenderCreationPage = () => import('@/admin/components/sms/vonage/smsNumbers/VonageSmsNumberCreationPage.vue')
const VonageWhatsAppAccountPage = () => import('@/admin/components/social/whatsapp/vonage/VonageWhatsAppAccountPage.vue')
const KnowledgeBasePage = () => import('@/admin/components/knowledge_base/KnowledgeBasePage.vue')
const InteractionsListLandingPage = () => import('@/admin/components/interactions/list/InteractionsListLandingPage.vue')
const InteractionExportLandingPage = () => import('@/admin/components/export/interaction/InteractionExportLandingPage.vue')
const InteractionsListEditPage = () => import('@/admin/components/interactions/list/InteractionsListEditPage.vue')

export const ADMIN_SETTINGS_KB_ROUTE = '/assist/admin/settings/kb/'

export const routes: Array<RouteRecordRaw> = [
  {
    name: 'admin-root',
    path: '/assist/admin',
    component: {},
  },
  {
    path: '/assist/admin/rules/',
    redirect: '/assist/admin/rules/list',
  },
  {
    path: '/assist/admin/bot',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-bot-list',
        component: BotListPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/bot/dydu/edit/:botId(\\d+)',
    component: AdminView,
    children: [
      {
        path: '',
        component: BotEditionDyduPage,
        name: 'admin-bot-edit',
        props: (route) => ({ botId: Number(route.params.botId) }),
      },
    ],
  },
  {
    path: '/assist/admin/bot/create',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-bot-create',
        component: BotCreationPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/activities',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-activities-list',
        component: ActivitiesListPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/activities/edit/:activityId',
    component: AdminView,
    children: [
      {
        path: '',
        component: ActivitiesEditionPage,
        name: 'admin-activities-edit',
        props: (route) => ({ activityId: route.params.activityId }),
      },
    ],
  },

  {
    path: '/assist/admin/activities/create',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-activities-create',
        component: ActivitiesCreationPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/chat/sdk/',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-chat-sdk-list',
        component: ChatSdkWebListPage,
      },
    ],
  },
  {
    path: '/assist/admin/chat/sdk/edit/:id',
    component: AdminView,
    children: [
      {
        path: '',
        component: ChatSdkWebEditionPage,
        name: 'admin-chat-sdk-edit',
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/chat/sdk/create',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-chat-sdk-create',
        component: ChatSdkWebCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/products/',
    redirect: '/assist/admin/products/0',
  },
  {
    path: '/assist/admin/reasons',
    component: AdminView,
    children: [
      {
        name: 'admin-reasons-landing-page',
        path: '',
        component: ReasonsLandingPage,
      },
    ],
  },
  {
    path: '/assist/admin/reasons/create/:reasonType',
    component: AdminView,
    children: [
      {
        name: 'admin-reason-create',
        path: '',
        component: ReasonsCreationPage,
        props: true,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/reasons/edit/:code',
    component: AdminView,
    children: [
      {
        name: 'admin-reason-edit',
        path: '',
        component: ReasonsEditionPage,
        props: true,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/voice/axialys-configuration/',
    component: AdminView,
    children: [
      {
        name: 'admin-axialys-configuration',
        path: '',
        component: AxialysConfiguration,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/voice/axialys-configuration/outbound/edit/:id',
    component: AdminView,
    children: [
      {
        name: 'admin-axialys-outbound-configuration-edit',
        path: '',
        component: AxialysOutBoundCallConfigurationEditionPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/voice/axialys-configuration/outbound/create',
    component: AdminView,
    children: [
      {
        name: 'admin-axialys-outbound-configuration-create',
        path: '',
        component: AxialysOutBoundCallConfigurationCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/voice/axialys-configuration/inbound/edit/:id',
    component: AdminView,
    children: [
      {
        name: 'admin-axialys-inbound-configuration-edit',
        path: '',
        component: AxialysInboundCallConfigurationEditionPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/voice/axialys-configuration/inbound/create',
    component: AdminView,
    children: [
      {
        name: 'admin-axialys-inbound-configuration-create',
        path: '',
        component: AxialysInboundCallConfigurationCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/voice/voicebot-configuration/',
    component: AdminView,
    children: [
      {
        name: 'admin-voicebot-configuration',
        path: '',
        component: VoicebotConfiguration,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/voice/voicebot-configuration/inbound/edit/:id',
    component: AdminView,
    children: [
      {
        name: 'admin-voicebot-inbound-configuration-edit',
        path: '',
        component: VoicebotInboundCallConfigurationEditionPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/voice/voicebot-configuration/inbound/create',
    component: AdminView,
    children: [
      {
        name: 'admin-voicebot-inbound-configuration-create',
        path: '',
        component: VoicebotInboundCallConfigurationCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/voice/odigo-configuration/',
    component: AdminView,
    children: [
      {
        name: 'admin-odigo-configuration',
        path: '',
        component: OdigoConfiguration,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/voice/odigo-configuration/outbound/edit/:id',
    component: AdminView,
    children: [
      {
        name: 'admin-odigo-outbound-configuration-edit',
        path: '',
        component: OdigoOutBoundCallConfigurationEditionPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/voice/odigo-configuration/outbound/create',
    component: AdminView,
    children: [
      {
        name: 'admin-odigo-outbound-configuration-create',
        path: '',
        component: OdigoOutBoundCallConfigurationCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/voice/odigo-configuration/inbound/edit/:id',
    component: AdminView,
    children: [
      {
        name: 'admin-odigo-inbound-configuration-edit',
        path: '',
        component: OdigoInboundCallConfigurationEditionPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/voice/odigo-configuration/inbound/create',
    component: AdminView,
    children: [
      {
        name: 'admin-odigo-inbound-configuration-create',
        path: '',
        component: OdigoInboundCallConfigurationCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/voice/genesys-configuration/',
    component: AdminView,
    children: [
      {
        path: '',
        component: GenesysConfiguration,
      },
    ],
  },
  {
    path: '/assist/admin/voice/sitel-cti-configuration/',
    component: AdminView,
    children: [
      {
        path: '',
        component: SitelCtiConfiguration,
      },
    ],
  },
  {
    path: '/assist/admin/vonage/configurations',
    component: AdminView,
    children: [
      {
        name: 'admin-vonage-configuration-landing',
        path: '',
        component: VonageConfigurationLandingPage,
      },
    ],
  },
  {
    path: '/assist/admin/vonage/configurations/create',
    component: AdminView,
    children: [
      {
        name: 'admin-vonage-configuration-create',
        path: '',
        component: VonageConfigurationCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/vonage/configurations/edit/:id',
    component: AdminView,
    children: [
      {
        name: 'admin-vonage-configuration-edit',
        path: '',
        component: VonageConfigurationEditPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/sms/senders/vonage/edit/:id',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-vonage-sms-sender-edit',
        component: VonageSmsSenderEditPage,
        props: true,
      },
    ],
  },
  {
    path: '/assist/admin/sms/senders/vonage/create',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-vonage-sms-sender-create',
        component: VonageSmsSenderCreationPage,
      },
    ],
  },
  {
    path: '/assist/admin/social/whatsApp/vonage/',
    component: AdminView,
    children: [
      {
        path: 'edit/:id',
        name: 'admin-vonage-whatsapp-account-edit',
        component: VonageWhatsAppAccountPage,
        props: true,
      },
      {
        path: 'create',
        name: 'admin-vonage-whatsapp-account-create',
        component: VonageWhatsAppAccountPage,
      },
    ],
  },

  {
    path: '/assist/admin/versions/',
    component: AdminView,
    children: [
      {
        path: '',
        component: VersionPage,
      },
    ],
  },
  {
    path: '/assist/admin/search',
    component: AdminView,
    children: [
      {
        name: 'admin-search-landing-page',
        path: '',
        component: SearchConfigLandingPage,
      },
    ],
  },
  {
    path: '/assist/admin/search/create',
    component: AdminView,
    children: [
      {
        name: 'admin-search-create',
        path: '',
        component: SearchConfigCreationPage,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/search/edit/:configId',
    component: AdminView,
    children: [
      {
        name: 'admin-search-edit',
        path: '',
        component: SearchConfigEditionPage,
        props: true,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/settings/customer/view',
    component: AdminView,
    children: [
      {
        name: 'admin-customer-settings',
        path: '',
        component: CustomerSettingsPage,
      },
    ],
  },
  {
    path: '/assist/admin/foundeverGPT',
    component: AdminView,
    children: [
      {
        name: 'foundeverGPT-configuration',
        path: '',
        component: FoundeverGPTConfigurationPage,
      },
    ],
  },
  {
    path: '/assist/admin/customerExportDefinition/add',
    component: AdminView,
    children: [
      {
        path: '',
        component: EditCustomerExportPage,
      },
    ],
  },
  {
    path: '/assist/admin/customerExportDefinition/edit/:fileRecordDefId',
    component: AdminView,
    children: [
      {
        path: '',
        props: true,
        component: EditCustomerExportPage,
      },
    ],
  },
  {
    path: '/assist/admin/interactionExportDefinition/add',
    component: AdminView,
    children: [
      {
        name: 'admin-export-interactions-create',
        path: '',
        component: InteractionExportEditPage,
      },
    ],
  },
  {
    path: '/assist/admin/interactionExportDefinition/edit/:exportDefCode',
    component: AdminView,
    children: [
      {
        name: 'admin-export-interactions-edit',
        path: '',
        props: true,
        component: InteractionExportEditPage,
      },
    ],
  },
  {
    path: '/assist/admin/interactionExportDefinition',
    component: AdminView,
    children: [
      {
        name: 'admin-export-interactions-landing-page',
        path: '',
        component: InteractionExportLandingPage,
      },
    ],
  },
  {
    path: '/assist/admin/interactions/list',
    component: AdminView,
    children: [
      {
        name: 'admin-interactions-list-landing-page',
        path: '',
        component: InteractionsListLandingPage,
      },
    ],
  },
  {
    path: '/assist/admin/interactions/list/create',
    component: AdminView,
    children: [
      {
        name: 'admin-interactions-list-create',
        path: '',
        component: InteractionsListEditPage,
        meta: {
          invalidate: true,
        },
      },
    ],
  },
  {
    path: '/assist/admin/interactions/list/edit/:screenCode',
    component: AdminView,
    children: [
      {
        name: 'admin-interactions-list-edit',
        path: '',
        component: InteractionsListEditPage,
        props: true,
        meta: {
          invalidate: true,
        },
      },
    ],
  },

  {
    name: 'redirect-back',
    path: '/assist/admin/redirect/back/:foundeverFrom',
    component: RedirectBackHandlingPage,
    children: [],
    props: true,
    meta: {
      technical: true,
    },
  },
  {
    path: ADMIN_SETTINGS_KB_ROUTE,
    component: AdminView,
    children: [
      {
        path: '',
        name: 'knowledge-base',
        component: KnowledgeBasePage,
      },
    ],
  },
  {
    path: '/assist/admin/sms/senders/add/:id',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-sms-sender-add',
        component: LegacyRouteWrapper,
      },
    ],
    meta: {
      legacy: true,
      exclude: ['.*\\/save$'],
    },
  },
  {
    path: '/assist/admin/social/whatsApp/add/:id',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'admin-whatsapp-account-add',
        component: LegacyRouteWrapper,
      },
    ],
    meta: {
      legacy: true,
      exclude: ['.*\\/save$'],
    },
  },
  {
    path: '/assist/admin/:url+',
    component: AdminView,
    children: [
      {
        path: '',
        component: LegacyRouteWrapper,
      },
    ],
    meta: {
      legacy: true,
      exclude: ['.*\\/save$'],
    },
  },
]

export default routes.map((l) => merge(l, { meta: { admin: true } }))
