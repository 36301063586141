import BaseModel from '@/engage/services/legacy/BaseModel'

export default class UserGroups extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'userGroups'
  }

  /**
   * @returns {Promise}
   * @memberof BaseUsersModel
   */
  getList() {
    return this.get(`${this.resourceUrl}/list`)
  }
}
