import { AGENT_ROUTE_NAMES } from '@/router/constants'
import { RouteRecordRaw } from 'vue-router'

const ModuleRoutedView = () => import('@/common/views/ModuleRoutedView.vue')
const HeaderComponent = () => import('@/agent/components/headers/BaseHeader.vue')
const CaseListHeader = () => import('@/agent/components/headers/CaseListHeader.vue')
const CaseView = () => import('@/agent/views/CaseView.vue')
const CustomerHeader = () => import('@/agent/components/headers/CustomerHeader.vue')
const CaseConvertHeader = () => import('@/agent/components/headers/CaseConvertHeader.vue')
const SupervisionHeader = () => import('@/agent/components/headers/SupervisionHeader.vue')

export default [
  {
    path: '/assist/tickets/list',
    component: ModuleRoutedView,
    children: [
      {
        name: AGENT_ROUTE_NAMES.TICKET_LIST,
        path: '',
        component: CaseListHeader,
      },
    ],
    meta: {
      legacy: true,
      exclude: ['/assist/tickets/bulk/bulkSave'],
    },
  },
  {
    path: '/assist/ticket/cancel',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: CaseListHeader,
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/ticket/create/oneclick/:skillGroupId',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/ticket/show/:caseId',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: CaseView,
        props: (route) => ({ caseId: route.params.caseId }),
      },
    ],
    meta: {
      legacy: true,
      refresh: true,
      exclude: ['/assist/ticket/save'],
    },
  },
  {
    path: '/assist/customer/new',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
      exclude: ['/assist/customer/save'],
    },
  },
  {
    path: '/assist/customer/edit/:customerId',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: CustomerHeader,
        props: (route) => ({ customerId: route.params.customerId, menu: true, backButton: true }),
      },
    ],
    meta: {
      legacy: true,
      exclude: ['/assist/customer/delete', '/assist/customer/save'],
    },
  },
  {
    path: '/assist/:channel/create/:id',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: CaseConvertHeader,
        props: {
          menu: false,
        },
      },
    ],
    meta: {
      legacy: true,
      exclude: ['/assist/sms/save'],
    },
  },
  {
    path: '/assist/pause/start/:id',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
        props: {
          menu: false,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/user/myprofile',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/stats/:url+',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/stats/supervision/users',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: SupervisionHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/stats/supervision/connections',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: SupervisionHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/imports/init',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
  {
    path: '/assist/exclusions/init',
    component: ModuleRoutedView,
    children: [
      {
        path: '',
        component: HeaderComponent,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
    meta: {
      legacy: true,
    },
  },
] as Array<RouteRecordRaw>
