import HttpService from '@/common/services/HttpService'
import { Typology } from '@/common/types/typology'
import { addTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'

const serviceRoot = '/api/v1/typologies'

export const getTypologies = async (): Promise<Typology[]> => {
  return HttpService.getData<Typology[]>(serviceRoot, {
    params: {
      includeDeactivated: true,
    },
  })
}

export const fetchTypologies = async (): Promise<any> => {
  const sessionStore = useSessionStore()
  return await Promise.all([getTypologies(), addTranslations(sessionStore.locale, PrefixTranslationsEnum.TYPOLOGY)])
}
