// https://stackoverflow.com/a/71760032/4568927

import cloneDeep from 'lodash/cloneDeep'
import { Store } from 'pinia'

export function resetStore({ store }: { store: Store }) {
  const initialState = cloneDeep(store.$state)
  store.$reset = () => {
    store.$patch(($state: any) => {
      Object.assign($state, cloneDeep(initialState))
    })
  }
}
