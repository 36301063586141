import BaseModel from '@/engage/services/legacy/BaseModel'

export default class Mail extends BaseModel {
  constructor() {
    super()
    this._service = 'rest'
    this._model = 'mail'
  }

  /**
   *  Get contacts
   *
   * @param {*} skillGroupId
   * @param {*} searchValue
   * @returns
   * @memberof Mail
   */
  getContacts(skillGroupId, searchValue) {
    const formData = new FormData()
    formData.append(`search[value]`, searchValue)

    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }
    return this.postFormData(`contacts/getContacts.json?skillGroupId=${skillGroupId}`, formData, options)
  }
  /**
   *  Get contacts header list
   *
   * @returns
   * @memberof Mail
   */
  getContactsHeaderList() {
    return this.get(`/contacts/header/list`)
  }
  /**
   *  Get contacts header list
   *
   * @returns
   * @memberof Mail
   */
  getContactsList(skillGroupId, options) {
    return this.post(`/contacts/list?skillGroupId=${skillGroupId}`, options)
  }

  /**
   * Send email throw REST API
   *
   * @param {*} email
   * @returns
   * @memberof Mail
   */
  sendMail(email) {
    return this.post(`${this.resourceUrl}/send`, {
      ...email,
    })
  }

  /**
   * Save draft email
   *
   * @param {*} email
   * @returns
   * @memberof Mail
   */
  saveMail(email) {
    return this.post(`${this.resourceUrl}/draft/save`, {
      ...email,
    })
  }

  /**
   * Delete draft email
   *
   * @param {*} interaction
   * @returns
   * @memberof Mail
   */
  deleteDraft(interaction) {
    return this.post(`${this.model}/deleteDraft/${interaction.id}`, {})
  }

  /**
   * Send attachment files
   *
   * @param {*} files
   * @returns
   * @memberof Mail
   */
  sendAttachments(files) {
    const formData = new FormData()

    if (files) {
      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file)
      })
    }

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return this.postFormData(`${this.resourceUrl}/attachment/upload`, formData, options)
  }

  /**
   * Get associated email account for the specified skill group
   *
   * @param {*} skillId
   * @returns
   * @memberof Mail
   */
  getMailboxes(skillId) {
    return this.get(`${this.resourceUrl}/mailboxes/findBySkill`, {
      params: {
        skillId,
      },
    })
  }

  /**
   * Retrieve signtures associated to a mailbox
   *
   * @param {*} mailboxId
   * @memberof Mail
   */
  getSignatures(mailboxId) {
    return this.get(`${this.model}/getMailboxSignatures/${mailboxId}`)
  }
}
