import { defineStore } from 'pinia'
import { NotificationState, Notification, NOTIFICATION_SCOPE, NotificationInterceptor } from '@/common/types/notification'
import { updateNotifications, removeAllNotifications, removeNotification, addNotification, canRemove } from '@/agent/services/NotificationService'

const minTimeoutDelay = process.env.NODE_ENV === 'development' ? 1000 : 2000
const maxTimeoutDelay = 60000
const timeoutDelayCoef = process.env.NODE_ENV === 'development' ? 1.1 : 1.6

export const useNotifications = defineStore('notifications', {
  state: (): NotificationState => {
    return {
      opened: false,
      waiting: false,
      setTimeoutRef: null,
      timeoutDelay: minTimeoutDelay,
      notifications: [],
      interceptors: [],
    }
  },
  actions: {
    startPolling(immediate?: boolean) {
      if (immediate) {
        this.refresh()
      } else {
        this.setTimeoutRef = setTimeout(this.refresh, this.timeoutDelay)
      }
    },
    stopPolling() {
      if (this.setTimeoutRef) {
        clearTimeout(this.setTimeoutRef)
        this.setTimeoutRef = null
      }
    },
    async refresh() {
      this.stopPolling()
      try {
        // TODO local storage notificationsClosed list-ticket
        this.notifications = await updateNotifications(this.notifications)
        this.timeoutDelay = Math.min(this.timeoutDelay * timeoutDelayCoef, maxTimeoutDelay)

        const NotificationMap = new Map<string, Notification>(this.notifications.map((n) => [n.ref, n]))

        // process server-side notifications interceptors
        this.interceptors = this.interceptors.reduce((acc, interceptor) => {
          const notificationToIntercept = NotificationMap.get(interceptor.ref)

          if (notificationToIntercept && notificationToIntercept.source === NOTIFICATION_SCOPE.Remote) {
            interceptor.handler(notificationToIntercept)
            return [...acc]
          }
          return [...acc, interceptor]
        }, [] as NotificationInterceptor[])

        this.startPolling()
      } catch (error) {
        console.error('Unable to refresh')
      }
    },
    async removeAll() {
      // Stop polling to avoid inconsistency
      this.waiting = true
      this.stopPolling()

      const removableItems = this.notifications.filter((n) => canRemove(n))

      this.notifications = this.notifications.filter((n) => !removableItems.includes(n))

      try {
        await removeAllNotifications(removableItems)
      } catch (error) {
        console.log(error)
      } finally {
        this.startPolling()
        this.waiting = false
      }
    },
    async remove(notification: Notification) {
      // Stop polling to avoid inconsistency
      this.waiting = true
      this.stopPolling()

      this.notifications = this.notifications.filter((n) => n.ref != notification.ref)

      await removeNotification(notification)

      this.startPolling()
      this.waiting = false
    },
    add(notification: Notification) {
      this.notifications = addNotification(notification, this.notifications)

      // Reset timeout on new notification
      this.stopPolling()
      this.timeoutDelay = minTimeoutDelay
      this.startPolling()
    },

    registerInterceptor(ref: string, handler: (notification: Notification) => void) {
      this.interceptors.push({ ref, handler })
    },
  },
  getters: {
    hasNotifications(): boolean {
      if (!this.notifications.length) {
        this.opened = false
      }

      return !!this.notifications.length
    },
    canDeleteAll(): boolean {
      return !!this.notifications.find((n) => canRemove(n))
    },
  },
})
