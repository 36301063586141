import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import Api from '@/engage/plugins/api'
import type { Worklog } from '@/engage/types/worklog'
import { useEngageCasesStore } from '@/engage/stores/engageCases'

export const useWorklogStore = defineStore('worklog', () => {
  const item = ref<Worklog | null>(null)

  const haveWorkLog = computed(() => {
    return !!item.value?.worklogId
  })
  const worklogId = computed(() => {
    return item.value?.worklogId || null
  })

  const saveWorkLog = async () => {
    try {
      if (item.value?.worklogId) {
        await Api.ticket.saveWorkLog(item.value.worklogId)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const insertWorkLog = async () => {
    const casesStore = useEngageCasesStore()

    try {
      if (casesStore.currentActivityId) {
        const { data } = await Api.ticket.newTicketWorklog(casesStore.currentActivityId)
        item.value = data
      }
    } catch (e) {
      console.error(e)
    }
  }
  const removeWorkLog = () => {
    item.value = null
  }
  const saveAndRemoveWorkLog = () => {
    saveWorkLog()
    removeWorkLog()
  }

  return {
    item,
    haveWorkLog,
    worklogId,
    saveWorkLog,
    removeWorkLog,
    insertWorkLog,
    saveAndRemoveWorkLog,
  }
})
