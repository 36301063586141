/**
 * All unprotected parameter
 */
export enum UnprotectedParameterEnum {
  TICKET_SEARCH_MAX_RESULTS = 'TICKET_SEARCH_MAX_RESULTS',
  CLOUD_EXPORT_ALLOW_ASYNC = 'CLOUD_EXPORT_ALLOW_ASYNC',
  CLOUD_EXPORT_ALLOW_SYNC = 'CLOUD_EXPORT_ALLOW_SYNC',
  TICKET_ACTION_COMMENT_MANDATORY = 'TICKET_ACTION_COMMENT_MANDATORY',
  KB_SYNCHRONIZATION_FREQUENCY = 'KB_SYNCHRONIZATION_FREQUENCY',
}

/**
 * Instance Parameter interface
 */
export interface InstanceParameter {
  code: UnprotectedParameterEnum | string
  name: string
  description: string
  value: string
}
