import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler } from '@/engage/services/legacy/utils'
import { useCustomerStore } from '@/engage/stores/customer'

const controllerAbortObject = createAbortControllerHandler(['customerHistoryCustomer'])

export default class Customer extends BaseModel {
  constructor() {
    super()
    this._service = 'rest'
    this._model = 'customer'
  }

  get currentCustomer() {
    const customerStore = useCustomerStore()
    return customerStore.currentCustomer
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   * @memberof Customer
   */
  updateCustomer(data) {
    return this.post(`${this.resourceUrl}/update/${this.currentCustomer.customerId}`, {
      ...data,
    })
  }

  /**
   * @returns {Promise}
   * @memberof Customer
   */
  historyCustomer() {
    return this.get(`/social/ticket/byCustomer/${this.currentCustomer.customerId}`, {
      signal: controllerAbortObject['customerHistoryCustomer'].requestAbortController().signal,
    })
  }

  /**
   * @returns {Promise}
   * @memberof Customer
   */
  getCustomer(customerId) {
    return this.get(`${this.resourceUrl}/get/${customerId}`)
  }

  /**
   * @returns {Promise}
   * @memberof Customer
   */
  getFieldsSearchCustomer() {
    return this.get(`${this.resourceUrl}/search/fieldToSearch`)
  }

  /**
   * @returns {Promise}
   * @memberof Customer
   */
  getCustomersHeaderList() {
    return this.get(`${this.resourceUrl}/search/fieldToDisplay`)
  }

  /**
   * @returns {Promise}
   * @memberof Customer
   */
  getCustomersList(options) {
    return this.post(`${this.resourceUrl}/search`, options)
  }

  /**
   * @returns {Promise}
   * @memberof Customer
   */
  changeCustomer(ticketId, customerId) {
    return this.post(`${this.resourceUrl}/change/${ticketId}/${customerId}`)
  }
}
