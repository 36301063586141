import { CHANNEL_PAGES, CHANNELS, GROUPS_COMMAND_CENTER } from '@/engage/constants'

export default [
  {
    path: '',
    alias: '/engage',
    component: () => import('@/engage/views/AgentView.vue'),
    meta: {
      name: 'engage',
    },
    children: [
      {
        path: '',
        name: 'default',
        component: () => import('@/engage/pages/engage/PageDefault.vue'),
      },
      {
        path: `${CHANNEL_PAGES.CHAT_CHANNELS}/:ticketId(\\d+)/close`,
        name: `${CHANNEL_PAGES.CHAT_CHANNELS}-close`,
        component: () => import('@/engage/pages/engage/PageChatClose.vue'),
        props: ticketIdForceNumber,
        meta: {
          channelRoute: true,
          worklog: true,
          pollInteractions: true,
        },
      },
      {
        path: `${CHANNEL_PAGES.CHAT_CHANNELS}/:ticketId(\\d+)/open`,
        name: `${CHANNEL_PAGES.CHAT_CHANNELS}-open`,
        component: () => import('@/engage/pages/engage/PageChatOpen.vue'),
        props: ticketIdForceNumber,
        meta: {
          channelRoute: true,
          worklog: true,
        },
      },
      ..._findPageByChannel(CHANNEL_PAGES.PUBLIC_CHANNELS, {
        channelRoute: true,
        worklog: true,
      }),
      ..._findPageByChannel(CHANNEL_PAGES.PRIVATE_CHANNELS, {
        channelRoute: true,
        worklog: true,
        pollInteractions: true,
      }),
    ],
  },
  {
    path: 'command-center',
    component: () => import('@/engage/views/CommandCenterView.vue'),
    meta: {
      name: 'command-center',
      supervisorRoute: true,
    },
    children: [
      {
        path: '',
        name: 'command-center-default',
        component: () => import('@/engage/pages/commandCenter/PageDefault.vue'),
        meta: {
          supervisorRoute: true,
        },
      },
      {
        path: `${GROUPS_COMMAND_CENTER.HELP}/:ticketId(\\d+)`,
        name: GROUPS_COMMAND_CENTER.HELP,
        component: () => import('@/engage/pages/commandCenter/PageChat.vue'),
        props: ticketIdForceNumber,
        meta: {
          channelRoute: true,
          supervisorRoute: true,
        },
      },
      {
        path: `${GROUPS_COMMAND_CENTER.OTHER}/:ticketId(\\d+)`,
        name: GROUPS_COMMAND_CENTER.OTHER,
        component: () => import('@/engage/pages/commandCenter/PageChat.vue'),
        props: ticketIdForceNumber,
        meta: {
          channelRoute: true,
          supervisorRoute: true,
        },
      },
    ],
  },
  {
    path: '404',
    name: '404',
    component: () => import('@/engage/pages/PageNotFound.vue'),
  },
  {
    path: ':pathMatch(.*)*',
    redirect: { name: '404' },
  },
]

function _findPageByChannel(page, meta) {
  return Object.values(CHANNELS)
    .filter((o) => o.page === page)
    .map((o) => {
      const key = o.name.toUpperCase()
      return {
        path: `${page}/${o.name.toLowerCase()}/:ticketId(\\d+)`,
        name: o.name,
        component: () => import(`@/engage/pages/engage/Page${key.charAt(0) + o.name.substring(1)}.vue`),
        props: ticketIdForceNumber,
        meta,
      }
    })
}

function ticketIdForceNumber(route) {
  return {
    ticketId: parseInt(route.params.ticketId, 10),
  }
}
