import { getCommandCenterRouteByActivity, getEngageRouteByActivity } from '@/engage/helper/router'
import { useEngageCasesStore } from '@/engage/stores/engageCases'

const setCurrentActivity = async (ticketId) => {
  const casesStore = useEngageCasesStore()
  return casesStore.setCurrentActivity(Number(ticketId))
}

const activityExist = (ticketId) => {
  const casesStore = useEngageCasesStore()
  const id = Number(ticketId)
  return casesStore.getActivityById(id)
}

const isCorrectCommandCenterPath = (to) => {
  const nextActivity = activityExist(to.params.ticketId)

  if (!nextActivity) {
    return false
  }

  const nextRoute = getCommandCenterRouteByActivity(nextActivity)
  return to.name === nextRoute.name
}

const isCorrectEngagePath = (to) => {
  const nextActivity = activityExist(to.params.ticketId)

  if (!nextActivity) {
    return false
  }

  const nextRoute = getEngageRouteByActivity(nextActivity)
  return to.name === nextRoute.name
}

export default {
  setCurrentActivity,
  activityExist,
  isCorrectEngagePath,
  isCorrectCommandCenterPath,
}
