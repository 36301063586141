import HttpService from '@/common/services/HttpService'

const apiVersion = 'api/v1'

export function isHelpRequestsAvailable() {
  return HttpService.get(`${apiVersion}/help-request-availability`)
}

export async function createHelpRequest(caseId: number) {
  return HttpService.post(`${apiVersion}/help-requests`, {
    caseId,
  })
}

export async function assignHelpRequest(requestId: UUID) {
  return HttpService.post(`${apiVersion}/help-request-assignments`, {
    requestId: requestId,
  })
}

export async function abandonHelpRequest(requestId: UUID) {
  return HttpService.delete(`${apiVersion}/help-request-assignments/${requestId}`)
}

export async function getHelpRequests(forAllUsers = false) {
  return HttpService.get(`${apiVersion}/help-requests${forAllUsers ? '?fetchAll=true' : ''}`)
}

export async function cancelHelpRequests(requestId: UUID) {
  return HttpService.delete(`${apiVersion}/help-requests/${requestId}`)
}
