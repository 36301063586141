import { useUserStore } from '@/engage/stores/user'

const userHasAccess = () => {
  const userStore = useUserStore()
  return userStore.userIsSupervisor
}

export default {
  userHasAccess,
}
