import { useUserStore } from '@/engage/stores/user'
import { useChatStore } from '@/engage/stores/chat'
import { useEngageApplication } from '@/engage/stores/engageApplication'

const getLoggedUser = async () => {
  const userStore = useUserStore()
  await userStore.getLoggedUserDetails()
  if (userStore.userIsChatAgent) {
    await useChatStore().connect()
  }
}

const getConfiguration = async () => {
  try {
    const application = useEngageApplication()
    await application.getConfiguration()
  } catch (e) {
    console.error(e)
  }
}

const getUserAccessRight = async () => {
  const userStore = useUserStore()
  await userStore.getUserAccessRight()
}

export default {
  getLoggedUser,
  getConfiguration,
  getUserAccessRight,
}
