import { defineStore } from 'pinia'
import { ref } from 'vue'

interface Customer {
  customerId: number
}

export const useCustomerStore = defineStore('customer', () => {
  const currentCustomer = ref<Customer | null>(null)
  const currentInteraction = ref<number | null>(null)
  const currentTicket = ref<number | null>(null)

  const setCurrentCustomer = (customer: Customer) => {
    currentCustomer.value = customer
  }
  const setCurrentCustomerByInteraction = ({ customerId, interactionId }: { customerId: number; interactionId: number }) => {
    if (currentCustomer.value?.customerId !== customerId) {
      setCurrentCustomer({
        customerId: customerId,
      })
    }
    if (currentInteraction.value !== interactionId) {
      _setInteraction(interactionId)
    }
  }
  const setCurrentCustomerByTicket = ({ customerId, ticketId }: { customerId: number; ticketId: number }) => {
    setCurrentCustomer({ customerId })
    _setTicket(ticketId)
  }
  const removeInteractionCustomer = () => {
    currentCustomer.value = null
    currentInteraction.value = null
  }
  const removeTicketCustomer = () => {
    currentCustomer.value = null
    currentTicket.value = null
  }
  const removeCustomer = () => {
    currentCustomer.value = null
    currentTicket.value = null
    currentInteraction.value = null
  }

  const _setInteraction = (interaction: number) => {
    currentInteraction.value = interaction
    currentTicket.value = null
  }
  const _setTicket = (ticket: number) => {
    currentTicket.value = ticket
    currentInteraction.value = null
  }

  return {
    currentCustomer,
    currentInteraction,
    currentTicket,
    setCurrentCustomer,
    setCurrentCustomerByInteraction,
    setCurrentCustomerByTicket,
    removeInteractionCustomer,
    removeTicketCustomer,
    removeCustomer,
  }
})
