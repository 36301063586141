import hostConfig from '@/common/helpers/configResolver'
import router from '@/router'
import { RouteLocationOptions } from 'vue-router'
import { sanitizeUrl } from '@/plugins/sanitizer'

export default function useNavigation() {
  function redirectToLegacy(url: String) {
    window.location.href = sanitizeUrl(hostConfig.root + url)
  }

  function openCase(caseId: string | number, params?: RouteLocationOptions) {
    router.push({ path: `/assist/ticket/show/${caseId}`, ...params })
  }

  function openCustomer(customerId: string | number, params?: RouteLocationOptions) {
    router.push({ path: `/assist/customer/edit/${customerId}`, ...params })
  }

  function openCustomerSearch() {
    redirectToLegacy('assist/tickets/list?showCustomerSearchTab=true&continue')
  }

  return { redirectToLegacy, openCase, openCustomer, openCustomerSearch }
}
