import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler } from '@/engage/services/legacy/utils'

const controllerAbortObject = createAbortControllerHandler(['typologyGetTreeView', 'typologyGetTree'])

export default class Typology extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'typologies'
  }

  /**
   * @returns {Promise}
   * @memberof Typology
   */
  getTreeView(activityId, data) {
    return this.post(`${this.resourceUrl}/getTreeview/${activityId}`, data)
  }

  /**
   * @returns {Promise}
   * @memberof Typology
   */
  getTree() {
    return this.get(`${this.resourceUrl}/getTree`, {
      signal: controllerAbortObject['typologyGetTree'].requestAbortController().signal,
    })
  }
}
