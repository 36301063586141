import BaseModel from '@/engage/services/legacy/BaseModel'

export default class Parameters extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'param'
  }
}
