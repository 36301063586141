import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler } from '@/engage/services/legacy/utils'

const controllerAbortObject = createAbortControllerHandler([
  'screenGetFieldsQualifyTicket',
  'screenGetDataQualifyTicket',
  'screenGetFieldsCustomer',
  'screenGetDataCustomer',
])

export default class Screen extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'screen'
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  getFieldsQualifyTicket(activityId) {
    return this.get(`${this.resourceUrl}/fields/${activityId}`, {
      signal: controllerAbortObject['screenGetFieldsQualifyTicket'].requestAbortController().signal,
    })
  }
  /**
   * @returns {Promise}
   * @memberof Screen
   */
  getDataQualifyTicket(activityId) {
    return this.get(`${this.resourceUrl}/values/${activityId}`, {
      signal: controllerAbortObject['screenGetDataQualifyTicket'].requestAbortController().signal,
    })
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  getFieldsCustomer() {
    return this.get(`${this.resourceUrl}/fields/customer`, {
      signal: controllerAbortObject['screenGetFieldsCustomer'].requestAbortController().signal,
    })
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  getDataCustomer(customerId) {
    return this.get(`${this.resourceUrl}/values/customer/${customerId}`, {
      signal: controllerAbortObject['screenGetDataCustomer'].requestAbortController().signal,
    })
  }

  /**
   * @returns {Promise}
   * @memberof Screen
   */
  getFieldsQualifyInteraction(interactionId) {
    return this.get(`${this.resourceUrl}/fields/interaction/${interactionId}`)
  }
  /**
   * @returns {Promise}
   * @memberof Screen
   */
  getDataQualifyInteraction(interactionId) {
    return this.get(`${this.resourceUrl}/values/interaction/${interactionId}`)
  }
}
