import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { LegacyEngageCase } from '@/engage/types/case'

export const useHistoryStore = defineStore('history', () => {
  const currentHistory = ref<LegacyEngageCase | null>(null)

  const setCurrentHistory = (history: LegacyEngageCase) => {
    currentHistory.value = history
  }

  const removeHistory = () => {
    currentHistory.value = null
  }

  return {
    currentHistory,
    setCurrentHistory,
    removeHistory,
  }
})
