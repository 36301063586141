import BaseModel from '@/engage/services/legacy/BaseModel'
import { createAbortControllerHandler } from '@/engage/services/legacy/utils'

const controllerAbortObject = createAbortControllerHandler(['commandCenterActivityGetListing'])

export default class CommandCenter extends BaseModel {
  constructor() {
    super()
    this._service = 'commandCenter'
    this._model = ''
  }

  /**
   * @returns
   * @memberof BaseModel
   */
  async getListing() {
    return this.get(`${this.resourceUrl}listing`, {
      signal: controllerAbortObject['commandCenterActivityGetListing'].requestAbortController().signal,
    })
  }
}
