import { useWorklogStore } from '@/engage/stores/worklog'

const saveWorkLog = () => {
  const worklogStore = useWorklogStore()
  return worklogStore.saveWorkLog()
}

export default {
  saveWorkLog,
}
