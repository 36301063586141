import { defineStore } from 'pinia'
import { useHistoryStore } from '@/engage/stores/history'
import { useInteractionsStore } from '@/engage/stores/interactions'
import { useEngageCasesStore } from '@/engage/stores/engageCases'
import { useChatStore } from '@/engage/stores/chat'

export const useRootStore = defineStore('root', () => {
  const historyStore = useHistoryStore()
  const interactionsStore = useInteractionsStore()
  const casesStore = useEngageCasesStore()
  const chatStore = useChatStore()

  const resetRouteData = async () => {
    casesStore.clearCurrentActivityId()
    interactionsStore.resetInteractions()
    interactionsStore.resetNewInteraction()
    historyStore.removeHistory()
    chatStore.resetTyping()
  }

  return {
    resetRouteData,
  }
})
