import { InteractionChannelEnum } from '@/common/types/channel'
import type { CaseData } from '@/common/types/case'

export const enum InteractionTypeEnum {
  MAIL_IN = 'MAIL_IN',
  INSTAGRAM_MEDIA = 'INSTAGRAM_MEDIA',
  INSTAGRAM_IN_COMMENT = 'INSTAGRAM_IN_COMMENT',
  INSTAGRAM_OUT_COMMENT = 'INSTAGRAM_OUT_COMMENT',
  INSTAGRAM_DM_IN = 'INSTAGRAM_DM_IN',
  CHAT_IN_NEW_USER_MESSAGE = 'CHAT_IN_NEW_USER_MESSAGE',
  CHAT_IN_INTERNAL_MESSAGE = 'CHAT_IN_INTERNAL_MESSAGE',
  CALL_IN = 'CALL_IN',
  INSTAGRAM_DM_OUT = 'INSTAGRAM_DM_OUT',
  SMS_OUT_NOTIFICATION = 'SMS_OUT_NOTIFICATION',
  INSTAGRAM_MENTIONED_COMMENT = 'INSTAGRAM_MENTIONED_COMMENT',
  INSTAGRAM_MENTIONED_MEDIA = 'INSTAGRAM_MENTIONED_MEDIA',
  INSTAGRAM_DM_STORY_MENTION = 'INSTAGRAM_DM_STORY_MENTION',
  INSTAGRAM_DM_STORY_REPLY = 'INSTAGRAM_DM_STORY_REPLY',
  INSTAGRAM_MEDIA_VIDEO = 'INSTAGRAM_MEDIA_VIDEO',
  INSTAGRAM_MENTIONED_MEDIA_VIDEO = 'INSTAGRAM_MENTIONED_MEDIA_VIDEO',
  MAIL_OUT_ACKNOWLEDGEMENT = 'MAIL_OUT_ACKNOWLEDGEMENT',
  MAIL_OUT_USER_REPLY = 'MAIL_OUT_USER_REPLY',
  MAIL_OUT_USER_FORWARD = 'MAIL_OUT_USER_FORWARD',
  MAIL_OUT_USER_INITIATED = 'MAIL_OUT_USER_INITIATED',
  CHAT_OUT_OPERATOR_RESPONSE = 'CHAT_OUT_OPERATOR_RESPONSE',
  CHAT_OUT_BOT_RESPONSE = 'CHAT_OUT_BOT_RESPONSE',
  CHAT_OUT_MANUAL_TRANSFER = 'CHAT_OUT_MANUAL_TRANSFER',
  CHAT_OUT_AUTO_TRANSFER = 'CHAT_OUT_AUTO_TRANSFER',
  SATISFACTION_CONTACT = 'SATISFACTION_CONTACT',
  CHAT_OUT_BOT_AUTO_RESPONSE = 'CHAT_OUT_BOT_AUTO_RESPONSE',
  CALL_OUT = 'CALL_OUT',
  FB_IN_COMMENT = 'FB_IN_COMMENT',
  FB_OUT_COMMENT = 'FB_OUT_COMMENT',
  MESSENGER_IN_MESSAGE = 'MESSENGER_IN_MESSAGE',
  MESSENGER_OUT_MESSAGE = 'MESSENGER_OUT_MESSAGE',
  SMS_IN = 'SMS_IN',
  SMS_OUT = 'SMS_OUT',
  FB_IN_POST = 'FB_IN_POST',
  TWITTER_IN = 'TWITTER_IN',
  TWITTER_OUT = 'TWITTER_OUT',
  TWITTER_DM_IN = 'TWITTER_DM_IN',
  TWITTER_DM_OUT = 'TWITTER_DM_OUT',
  MAIL_OUT_BULK_USER_INITIATED = 'MAIL_OUT_BULK_USER_INITIATED',
  WHATSAPP_IN = 'WHATSAPP_IN',
  WHATSAPP_OUT = 'WHATSAPP_OUT',
  MAIL_OUT_NOTIFICATION = 'MAIL_OUT_NOTIFICATION',
  FB_OUT_POST = 'FB_OUT_POST',
  VISIT = 'VISIT',
  MISSED_OPPORTUNITY = 'MISSED_OPPORTUNITY',
  OPPORTUNITY = 'OPPORTUNITY',
  CHAT_START = 'CHAT_START',
  CHAT_END = 'CHAT_END',
  MAIL_OUT_TRANSCRIPT = 'MAIL_OUT_TRANSCRIPT',
  WHATSAPP_OUT_TEMPLATE = 'WHATSAPP_OUT_TEMPLATE',
  MESSENGER_OUT_TEMPLATE = 'MESSENGER_OUT_TEMPLATE',
  CHAT_START_BOT = 'CHAT_START_BOT',
  CHAT_END_BOT = 'CHAT_END_BOT',
  MAIL_IN_DISSUASION = 'MAIL_IN_DISSUASION',
  MAIL_OUT_DISSUASION = 'MAIL_OUT_DISSUASION',
  WHATSAPP_IN_DISSUASION = 'WHATSAPP_IN_DISSUASION',
  WHATSAPP_OUT_DISSUASION = 'WHATSAPP_OUT_DISSUASION',
  API_IN = 'API_IN',
  API_OUT = 'API_OUT',
}

export const enum InteractionDirectionEnum {
  IN = 'IN',
  OUT = 'OUT',
}

export type InteractionTypeData = {
  code: InteractionTypeEnum
  direction: InteractionDirectionEnum
  channel: InteractionChannelEnum
  active: boolean
}

export enum InteractionStatusEnum {
  OPEN = 'OPEN',
  REPLIED = 'REPLIED',
  BULKREPLIED = 'BULKREPLIED',
  FORWARDED = 'FORWARDED',
  NOACTIONREQUIRED = 'NOACTIONREQUIRED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  TOSEND = 'TOSEND',
  TORESEND = 'TORESEND',
  SENT = 'SENT',
  SENDERROR = 'SENDERROR',
}

export enum InteractionIncludeEnum {
  HAS_ATTACHMENTS = 'HAS_ATTACHMENTS',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
}

export type InteractionData = {
  id: number
  channel: InteractionChannelEnum
  parentId?: number
  direction: InteractionDirectionEnum
  summary?: string
  content?: string
  type: InteractionTypeEnum
  status: InteractionStatusEnum
  statusDate?: string
  interactionDate: string
  interactionUser?: string
  dueDate?: string
  sentFrom?: string
  sentTo?: string
  caseId?: number
  customerId?: number
  hasAttachments?: boolean
}

export type Interaction = InteractionData & {
  case?: CaseData
}
