<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import useFocusOutIframe from '@/common/composables/useFocusOutIframe'
import { useGlobalEvents } from '@/app/composables/useGlobalEvents'

useFocusOutIframe()
useGlobalEvents()

const AppAsync = defineAsyncComponent(() => import('@/AppAsync.vue'))
</script>

<template>
  <Suspense>
    <AppAsync></AppAsync>
  </Suspense>
</template>

<style lang="scss">
@import '@/styles/style.scss';
</style>
