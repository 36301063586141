import HttpService from '@/common/services/HttpService'
import { Profiles, UserLandingPage, UserSummary } from '@/common/types/users'
import { UserSession } from '@/common/types/session'
import { UserForAssignment } from '@/agent/types/users'
import { getCurrentKeycloakToken } from '@/common/services/AuthenticationService'
import { get as getCachedData } from '@/common/services/LocalStorageService'

const serviceRootV1 = '/api/v1/users'
const USER_LANDING_PAGE_API = serviceRootV1 + '/me/landing-page'

const serviceRootV2 = '/api/v2/users'

interface GetUsersForAssignmentParams {
  skillCodes?: string[]
}

export const getUserMe = () => {
  return HttpService.getData<UserSession>('/api/v1/users/me')
}

export const getBotUsers = () => {
  return HttpService.getData<UserSession[]>(`${serviceRootV1}?userProfile=${Profiles.BOT}`)
}

export const getUsersForAssignment = ({ skillCodes }: GetUsersForAssignmentParams): Promise<UserForAssignment[]> => {
  const params: any = {}
  if (skillCodes !== undefined) params.skillCodes = skillCodes.join(',')
  return HttpService.getData<UserForAssignment[]>('/rest/skills/getUsersForAssignmentWithSkillCodes', {
    params,
  })
}

// get user landing page by jwt
export const getUserLandingPage = (): Promise<UserLandingPage> => {
  return HttpService.getData(USER_LANDING_PAGE_API, { headers: { 'jwt-access-token': getCurrentKeycloakToken() } })
}

export const fetchUsers = async (cacheKey: string): Promise<UserSummary[]> => {
  return getCachedData<UserSummary[]>(cacheKey, serviceRootV2, {
    params: {
      includeDeactivated: true,
    },
  })
}
