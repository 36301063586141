import { createI18n } from 'vue-i18n'
import fr from '@/locales/fr.json'
import en from '@/locales/en.json'
import de from '@/locales/de.json'
import es from '@/locales/es.json'

const i18n = createI18n({
  legacy: false,
  messages: {
    en: en,
    fr: fr,
    de: de,
    es: es,
  },
  silentTranslationWarn: true,
  silentFallbackWarn: true,
})

export default i18n
