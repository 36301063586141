import HttpService from '@/common/services/HttpService'
import type { Break, PreBreakTimerSettings } from '@/agent/types/break'

export const getBreaks = () => {
  return HttpService.getData<Break[]>('/api/v1/breaks')
}

export const stopPrebreak = (pauseId: number) => {
  return HttpService.post(`/rest/pause/prePause`, {
    prePause: false,
    pauseStateId: pauseId,
  })
}

export const startBreak = (pauseId: number) => {
  return HttpService.post(`/rest/pause/start`, {
    pauseStateId: pauseId,
  })
}

export const getPreBreakTimerSettings = () => {
  return HttpService.getData<PreBreakTimerSettings>('/configuration/api/v1/instance/pre-break')
}
