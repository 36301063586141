import { useInteractionsStore } from '@/engage/stores/interactions'
import { usePauseStore } from '@/engage/stores/pause'

let pollingInterval = null

const initPolling = function () {
  const interactionsStore = useInteractionsStore()

  pollingInterval = setTimeout(async () => {
    const pauseStore = usePauseStore()
    if (!pauseStore.isPause) {
      try {
        await interactionsStore.fetchLastInteractions()
        initPolling()
      } catch {
        clearPolling()
      }
    }
  }, 5000)
}

const clearPolling = async function () {
  // Dynamic import to avoid circular dependency import will be solved by INNSO-12054
  const { controllerAbortObject } = await import('@/engage/services/legacy/Interaction')
  controllerAbortObject.getLastInteractions.requestAbortController()
  if (pollingInterval) {
    pollingInterval = clearTimeout(pollingInterval)
  }
}

export default {
  initPolling,
  clearPolling,
}
