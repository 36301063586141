import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import humanizeDurationPlugin from '@/plugins/dayjs/humanizeDurationPlugin'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
dayjs.extend(LocalizedFormat)
dayjs.extend(isBetween)

export async function setDayjsLocale(language: string) {
  const dayjsLocaleModule = import.meta.glob<Record<string, ILocale>>('../../../node_modules/dayjs/esm/locale/*.js')

  const localeData = await dayjsLocaleModule[`../../../node_modules/dayjs/esm/locale/${language || 'en'}.js`]()
  dayjs.locale(language, localeData.default)
  dayjs.extend(humanizeDurationPlugin, { language })
}

export default dayjs
