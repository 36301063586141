import BaseModel from '@/engage/services/legacy/BaseModel'
import configResolver from '@/common/helpers/configResolver'
import { useEngageCasesStore } from '@/engage/stores/engageCases'

export default class Users extends BaseModel {
  constructor() {
    super()

    this._service = 'rest'
    this._model = 'users'
  }

  get currentActivityId() {
    const casesStore = useEngageCasesStore()
    return casesStore.currentActivityId
  }

  /**
   * @returns {Promise}
   * @memberof Users
   */
  getLoggedUserDetails() {
    return this.get(`${this.resourceUrl}/getLoggedInUserDetails`)
  }

  /**
   * @param {Number, String} { skillId, search }
   * @returns {Promise}
   * @memberof BaseUsersModel
   */
  getUsersForTransfert({ skillId, search }) {
    return this.get(`${this.resourceUrl}/getUsersForTransfert`, {
      params: {
        skillId,
        search,
      },
    })
  }

  /**
   * @param {Number, String} { skillId, search }
   * @returns {Promise}
   * @memberof BaseUsersModel
   */
  getUsersForChatTransfert({ skillId = null, search = null }) {
    return this.get(`${this.resourceUrl}/getUsersForChatTransfert`, {
      params: {
        skillId,
        search,
      },
    })
  }

  /**
   * @param {String} search
   * @returns {Promise}
   * @memberof BaseUsersModel
   */
  getUsersForAssignment(search) {
    return this.get(`${this.resourceUrl}/getUsersForAssignment`, {
      params: {
        search,
        ticketId: this.currentActivityId,
      },
    })
  }

  /**
   * @param {String} search
   * @returns {Promise}
   * @memberof BaseUsersModel
   */
  getUsersForChatAssignment({ skillId, search = null }) {
    return this.get(`${this.resourceUrl}/getUsersForChatAssignment`, {
      params: {
        skillId,
        search,
      },
    })
  }

  /**
   * @returns {Promise}
   * @memberof Users
   */
  keepAlive() {
    this.get(`${this.resourceUrl}/keepAlive`, {
      params: {
        userTimestamp: new Date().getTime(),
      },
    })
  }

  /**
   * @returns {Promise}
   * @memberof Users
   */
  getUserAccessRight() {
    return this.get(`${this.resourceUrl}/getUserAccessRight`)
  }

  /**
   * @returns {Promise}
   * @memberof Users
   */
  changeUserChannelState(channelId, connected) {
    return this.post(`${this.resourceUrl}/changeUserChannelState`, {
      channelId,
      connected,
    })
  }
  /**
   * @param {Number|null} worklogId
   * @param {Number|null} userSessionStateId
   * @memberof Users
   */
  sendBeacon(worklogId, userSessionStateId) {
    const stringify = JSON.stringify({
      worklogId,
      userSessionStateId,
    })
    navigator.sendBeacon(`${configResolver.pathname}${this.resourceUrl}/receiveBeacon`, stringify)
  }
}
