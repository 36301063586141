import HttpService from '@/common/services/HttpService'
import type { Space } from '@/common/types/spaces'
import type { Skill } from '@/common/types/skills'
import { Locale } from '@/admin/types/locale'
import { NodeTree } from '@/common/helpers/treeHelper'
import { addTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'
import { fasFolder, fasFolderOpen, fasCodeFork } from '@quasar/extras/fontawesome-v6'
import i18n from '@/plugins/i18n'
const { t } = i18n.global

export const getSkills = async (): Promise<Skill[]> => {
  return HttpService.getData<Skill[]>('/api/v1/skills')
}

export const fetchSkills = async (): Promise<any> => {
  const sessionStore = useSessionStore()
  return await Promise.all([getSkills(), addTranslations(sessionStore.locale, PrefixTranslationsEnum.SKILL)])
}

export const getSkillListNodeTree = (skills: Skill[]): NodeTree[] => {
  const sessionStore = useSessionStore()
  const list: NodeTree[] = []
  const spaces: Array<string> = []
  for (const skill of skills) {
    if (!skill.active || !sessionStore.spaces.includes(skill.spaceName)) continue
    if (!spaces.includes(skill.spaceName)) {
      list.push({
        id: skill.spaceName,
        code: skill.spaceName,
        parent: null,
        children: [],
        text: t(skill.spaceName),
        type: 'folder',
      })
      spaces.push(skill.spaceName)
    }
    list.push({
      id: skill.code,
      code: skill.code,
      parent: skill.spaceName,
      children: [],
      text: t(PrefixTranslationsEnum.SKILL + skill.code),
      type: 'skill',
    })
  }
  return list
}

export const iconNodeSkillTree = (node: NodeTree, expanded: boolean) => {
  if (node.type === 'folder') {
    return expanded ? fasFolderOpen : fasFolder
  }
  return fasCodeFork
}

export const getSpaceOptions = (spaces: Space[]): Array<{ label: string; value: string }> => {
  const sessionStore = useSessionStore()
  const options: Array<{ label: string; value: string }> = []
  for (const space of spaces) {
    if (!space.active || !sessionStore.spaces.includes(space.name)) continue

    options.push({
      label: t(space.name),
      value: space.name,
    })
  }
  return options
}

/**
 * Get locales list
 */
export const fetchLocales = async (): Promise<Locale[]> => {
  return await HttpService.getData<Locale[]>('/api/v1/locales')
}
