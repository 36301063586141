import BaseModel from '@/engage/services/legacy/BaseModel'
import { useEngageCasesStore } from '@/engage/stores/engageCases'

export default class Conversation extends BaseModel {
  constructor() {
    super()
  }

  get currentActivityId() {
    const casesStore = useEngageCasesStore()
    return casesStore.currentActivityId
  }

  /**
   * @param {Number, Date, String} { reasonCode, wakeUpDate, comment }
   * @returns {Promise}
   * @memberof Conversation
   */
  stack({ reasonCode, wakeUpDate, comment }) {
    return this.post(`${this.service}/stack/${this.currentActivityId}`, {
      reasonCode,
      wakeUpDate,
      comment,
    })
  }

  /**
   * @param {Number, String} { reasonCode, comment }
   * @returns {Promise}
   * @memberof Conversation
   */
  close({ reasonCode, comment }) {
    return this.post(`${this.service}/close/${this.currentActivityId}`, {
      reasonCode,
      comment,
    })
  }

  /**
   * @param {Number, Number, Number, String} { skillId, reasonCode, userId, comment }
   * @returns
   * @memberof Conversation
   */
  transfer({ skillId, reasonCode, userId, comment }) {
    return this.post(`${this.service}/transfer/${this.currentActivityId}`, {
      skillId,
      reasonCode,
      userId,
      comment,
    })
  }

  /**
   * Check if mandatory fields to allow form submission
   * @param {String} { action }
   * @returns
   * @memberof Conversation
   */
  isActionReady(ticketId, action) {
    return this.post(`${this.service}/ticketAction/check?ticketId=${ticketId}&action=${action}`)
  }
}
