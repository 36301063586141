import { ref } from 'vue'
import { defineStore } from 'pinia'
import api from '@/engage/plugins/api'
import type { Application } from '@/engage/types/application'
import type { AxiosResponse } from 'axios'

export const useEngageApplication = defineStore('engageApplication', () => {
  const userName = ref()
  const token = ref()

  const getConfiguration = async () => {
    try {
      const { data }: AxiosResponse<Application> = await api.config.getConfiguration()

      if (data.userName) userName.value = data.userName
      if (data.token) token.value = data.token
    } catch (e) {
      console.error(e)
    }
  }

  return {
    userName,
    token,
    getConfiguration,
  }
})
